import { IHelpcenterSubcategory } from '../Interface/models/IHelpcenterSubcategory';
import { ISupportType } from '../Interface/models/ISupportType';

export enum SupportMode {
  DISCORD = 'discord',
  CHAT = 'chat',
  TICKET = 'ticket',
  VISIO = 'visio',
}

export const SupportType: ISupportType[] = [
  {
    mode: SupportMode.DISCORD,
    name: 'Communauté',
    iconSrc: '/help-center/discord.png',
    description: 'Posez directement votre question à d\'autres utilisateurs Abby sur le serveur Discord de notre communauté.',
    averageTimeResponse: '~ 1 h',
    status: '78 membres en ligne',
    statusColor: 'success',
  },
  {
    mode: SupportMode.CHAT,
    name: 'Chat en ligne',
    iconSrc: '/help-center/chat.svg',
    description: 'Conversez avec le support Abby pour obtenir une réponse de manière rapide et efficace.',
    averageTimeResponse: '~ 20 min (offre Pro) / < 1 h (offre Start)',
    status: 'En ligne',
    statusColor: 'success',
  },
  {
    mode: SupportMode.TICKET,
    name: 'Nouveau ticket',
    iconSrc: '/help-center/ticket.svg',
    description: 'Envoyez un ticket au support Abby qui traitera votre demande dans un délai acceptable.',
    averageTimeResponse: '~ 24 h',
    status: 'Ouvert',
    statusColor: 'success',
  },
  {
    mode: SupportMode.VISIO,
    name: 'Rendez-vous en visio',
    iconSrc: '/help-center/visio.png',
    description: 'Prenez directement rendez-vous avec un de nos conseillers en visioconférence.',
    averageTimeResponse: 'Variable',
    status: 'Sur rendez-vous',
    statusColor: 'primary',
  },
];

export enum HelpcenterCategory {
  HELP_SUPPORT = 'help_support',
  BUG = 'bug',
  SUBSCRIPTION = 'subscription',
  CREATION = 'creation',
  SUGGESTION = 'suggestion',
}

export const HelpcenterSubcategory: IHelpcenterSubcategory[] = [
  {
    name: '📄 Facturation',
    code: 'billing',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.DISCORD,
    descriptionTip: 'N\'oubliez pas de bien préciser le numéro de votre document (facture, devis...) ainsi que les informations à prendre en compte pour une prise en charge plus rapide du support.',
    topRatedQuestions: [
      {
        title: 'Comment supprimer/ annuler/ modifier une facture ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6466692-facturation-supprimer-modifier-ou-annuler-une-facture" target="_blank"><b>Facturation - Supprimer, modifier ou annuler une facture</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466692-facturation-supprimer-modifier-ou-annuler-une-facture',
      },
      {
        title: 'Comment faire signer un devis en ligne ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6762053-facturation-signature-en-ligne" target="_blank"><b>Facturation - Signature en ligne</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6762053-facturation-signature-en-ligne',
      },
      {
        title: 'Comment configurer la numérotation de mes documents ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6556756-reglages-facturation-numerotation" target="_blank"><b>Réglages Facturation - Numérotation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6556756-reglages-facturation-numerotation',
      },
      {
        title: 'Comment modifier l\'e-mail d\'envoi des factures ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6563591-faq-facturation" target="_blank"><b>FAQ - Facturation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6563591-faq-facturation',
      },
      {
        title: 'Mon logo est trop petit',
        text: 'Si votre logo est trop petit, nous vous conseillons de le redimensionner au format 100x100.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'Mes factures sont en HT au lieu de TTC',
        text: 'Si vous ne facturez pas la TVA et n\'êtes donc pas redevable de la TVA, votre facture doit rester en HT. Dans le cas contraire, le montant TTC indique que vous facturez la TVA. Cependant, si vous la facturez mais que vos factures sont HT vous devez l\'activer sur Abby. <br>Voir l\'article : <a href="https://aide.abby.fr/fr/articles/6940517-declarations-tva" target="_blank"><b>Déclarations - TVA</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6940517-declarations-tva',
      },
      {
        title: 'Comment facturer en plusieurs fois ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6467070-facturation-paiement-en-plusieurs-fois-facture-d-acompte-et-reste-a-payer" target="_blank"><b>FAQ - Facturation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6467070-facturation-paiement-en-plusieurs-fois-facture-d-acompte-et-reste-a-payer',
      },
      {
        title: 'Ajouter une note de pieds de page ou d\'en-tête',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6563591-faq-facturation" target="_blank"><b>FAQ - Facturation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6563591-faq-facturation',
      },
      {
        title: 'Comment facturer des frais de ports (ou frais de livraison) ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6563591-faq-facturation" target="_blank"><b>FAQ - Facturation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6563591-faq-facturation',
      },
      {
        title: 'Problème de doublon au moment de finaliser une facture',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6563591-faq-facturation" target="_blank"><b>FAQ - Facturation</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6563591-faq-facturation',
      },
      {
        title: 'Ajouter des crédits de signature',
        text: 'Chaque crédit est facturé 2€, vous devez informer notre équipe du nombre de crédits que vous souhaitez ajouter.<br>Voir l\'article : <a href="https://aide.abby.fr/fr/articles/6762053-facturation-signature-en-ligne" target="_blank"><b>Facturation - Signature en ligne</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6762053-facturation-signature-en-ligne',
      },
    ],
  },
  {
    name: '📘 Comptabilité',
    code: 'accounting',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Supprimer ou ajouter une entrée dans les livres comptables',
        text: 'Livre des recettes : <a href="https://aide.abby.fr/fr/articles/6466659-livres-comptables-livre-des-recettes" target="_blank"><b>Livres comptables - Livre des recettes</b></a><br>Livre des achats : <a href="https://aide.abby.fr/fr/articles/6519084-livres-comptables-livre-des-achats" target="_blank"><b>Livres comptables - Livre des achats</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466659-livres-comptables-livre-des-recettes',
      },
      {
        title: 'Le livre des recettes se met-il à jour automatiquement ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6466659-livres-comptables-livre-des-recettes" target="_blank"><b>Livres comptables - Livre des recettes</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466659-livres-comptables-livre-des-recettes',
      },
      {
        title: 'Comment catégoriser ou ventiler une transaction ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6464004-banque-categoriser-et-ventiler-des-transactions-bancaires" target="_blank"><b>Banque - Catégoriser et ventiler des transactions bancaires</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6464004-banque-categoriser-et-ventiler-des-transactions-bancaires',
      },
      {
        title: 'Comment configurer mes taux de cotisations ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6482320-declarations-urssaf" target="_blank"><b>Déclarations - Urssaf</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6482320-declarations-urssaf',
      },
      {
        title: 'Comment fonctionne le compte de résultat ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6463974-livres-comptables-compte-de-resultat" target="_blank"><b>Livres comptables - Compte de résultat</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6463974-livres-comptables-compte-de-resultat',
      },
      {
        title: 'Versement libératoire ou imposition classique ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://blog.abby.fr/difference-versement-liberatoire-limposition-classique/" target="_blank"><b>Quelle est la différence entre le versement libératoire et l\'imposition classique ?</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://blog.abby.fr/difference-versement-liberatoire-limposition-classique/',
      },

    ],
  },
  {
    name: '⏩ Avance immédiate',
    code: 'immediate_advance',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.DISCORD,
    descriptionTip: 'Pensez à préciser le client ou le document concerné par votre demande pour une meilleure prise en charge par le support.',
    topRatedQuestions: [
      {
        title: 'Comment fonctionne l\'avance immédiate ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise" target="_blank"><b>Inscriptions à notre démonstration : Classique - SAP - Création</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise',
      },
      {
        title: 'Comment inscrire et facturer un client ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6657420-ajouter-et-facturer-vos-clients-avance-immediate-de-credit-d-impot-api-tiers-de-prestations" target="_blank"><b>Ajouter et facturer vos clients - Avance immédiate de crédit d\'impôt</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6657420-ajouter-et-facturer-vos-clients-avance-immediate-de-credit-d-impot-api-tiers-de-prestations',
      },
      {
        title: 'Télécharger une attestation fiscale',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6890863-facturation-attestation-fiscale-avance-immediate-de-credit-d-impot-api-tiers-de-prestation" target="_blank"><b>Facturation - Attestation fiscale</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6890863-facturation-attestation-fiscale-avance-immediate-de-credit-d-impot-api-tiers-de-prestation',
      },
      {
        title: 'Effectuer ma déclaration EMA (NOVA)',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6671704-facturation-declaration-ema-nova-avance-immediate-de-credit-d-impot-api-tiers-de-prestations" target="_blank"><b>Facturation - Déclaration EMA (NOVA)</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6671704-facturation-declaration-ema-nova-avance-immediate-de-credit-d-impot-api-tiers-de-prestations',
      },
      {
        title: 'Je n\'arrive pas à créer / inscrire ou modifier un client',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6949390-resoudre-les-erreurs-d-inscription-de-clients-avance-immediate-de-credit-d-impot-api-tiers-de-prestations" target="_blank"><b>Résoudre les erreurs d\'inscription de clients</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6949390-resoudre-les-erreurs-d-inscription-de-clients-avance-immediate-de-credit-d-impot-api-tiers-de-prestations',
      },
      {
        title: 'Mon client n’a pas activé son espace',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation" target="_blank"><b>Résoudre les erreurs de facturation - Avance immédiate de crédit d\'impôt</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation',
      },
      {
        title: 'Comment recevoir mes identifiants ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6467235-fonctionnement-et-identification-de-l-avance-immediate-de-credit-d-impot-api-tiers-de-prestations#h_c297784bc2" target="_blank"><b>Fonctionnement et identification - Avance immédiate</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6467235-fonctionnement-et-identification-de-l-avance-immediate-de-credit-d-impot-api-tiers-de-prestations#h_c297784bc2',
      },
      {
        title: 'Le statut de mes factures ne s’actualise pas',
        text: 'Passé un délai de 48h, si le statut de vos factures ne se met pas à jour, cliquez sur Actions > Mettre à jour le statut.<br><a href="https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation" target="_blank"><b>Le statut de mes factures ne s’actualise pas</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation',
      },
      {
        title: 'Je rencontre un problème pour facturer un client',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation" target="_blank"><b>FAQ et résolution d\'erreurs liées à l\'avance immédiate</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6949641-resoudre-les-erreurs-de-facturation-avance-immediate-de-credit-d-impot-api-tiers-de-prestation',
      },
      {
        title: 'Le code postal (ou code Insee de la commune de naissance) de mon client a changé',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6948112-retrouver-l-ancien-code-insee-d-une-commune-de-naissance-avance-immediate-de-credit-d-impot-api-tiers-de-prestations" target="_blank"><b>La commune de naissance a changé</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6948112-retrouver-l-ancien-code-insee-d-une-commune-de-naissance-avance-immediate-de-credit-d-impot-api-tiers-de-prestations',
      },
      {
        title: 'Je n’arrive pas à rentrer mes identifiants',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6949328-resoudre-les-erreurs-pour-votre-identification-avance-immediate-de-credit-d-impot-api-tiers-de-prestation" target="_blank"><b>Je n’arrive pas à rentrer mes identifiants</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6949328-resoudre-les-erreurs-pour-votre-identification-avance-immediate-de-credit-d-impot-api-tiers-de-prestation',
      },
      {
        title: 'J’ai reçu mes identifiants Sandbox',
        text: 'Vous devez envoyer un mail à l\'adresse que vous envoyez vos codes sandbox. Pour en savoir plus vous pouvez consulter notre article dédié : <a href="https://aide.abby.fr/fr/articles/6657446-faq-et-resolutions-d-erreurs-liees-a-l-avance-immediate-de-credit-d-impot-api-tiers-de-prestations#h_e6610aecb2" target="_blank">Résoudre mes problèmes d\'identifiants</a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6657446-faq-et-resolutions-d-erreurs-liees-a-l-avance-immediate-de-credit-d-impot-api-tiers-de-prestations#h_e6610aecb2',
      },
      {
        title: 'J\'ai une erreur avec le RIB',
        text: 'Le RIB doit être celui d\'un pays membre de l\'Union Européenne, il faut le renseigner manuellement et ne pas le copier-coller.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'Je n\'arrive pas à ajouter un client né en Algérie',
        text: 'Malheureusement, tant que l\'Urssaf n\'aura pas mis en place l\'identification via le numéro fiscal pour créer de nouveaux clients, nous ne pourrons pas l\'inscrire.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'J\'ai une erreur avec les dates de prestation (Espace particulier non activé)',
        text: 'Vous devez modifier la date de début de prestation pour qu\'elle soit supérieure à la date à laquelle votre client a activé son espace. La date de fin doit être inférieure ou égale à la date du jour.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'J\'ai une erreur "Facture en doublon"',
        text: 'Cette erreur est générée quand l\'Urssaf reçoit trop de demandes. Votre facture va bien être envoyée à votre client, inutile d\'en éditer une nouvelle. Lorsque vous aurez reçu le virement, il suffit de passer la facture au statut Payé manuellement en cliquant sur Actions > Marqué comme Payé.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'L\'avance immédiate est active, mais je n\'ai pas les fonctionnalités',
        text: 'Si vos identifiants sont bien renseignés sur Abby, mais que vous ne pouvez pas créer de clients, il convient d’envoyer un mail à l’adresse qui vous a fait parvenir vos identifiants afin d’indiquer que : Vous avez reçu des identifiants de Production depuis plus de 72h mais qu’ils ne fonctionnent pas.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
    ],
  },
  {
    name: '🔢 TVA',
    code: 'vat',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Comment l\'activer et comment fonctionne-t-elle ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6940517-declarations-tva" target="_blank"><b>Déclarations - TVA</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6940517-declarations-tva',
      },
      {
        title: 'Dois-je mettre une mention d’exonération de TVA ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6609481-qu-est-ce-que-la-tva" target="_blank"><b>Qu\'est-ce que la TVA ?</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6609481-qu-est-ce-que-la-tva',
      },
      {
        title: 'Pourquoi mes factures sont en HT ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6940517-declarations-tva" target="_blank"><b>Déclaration - TVA</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6940517-declarations-tva',
      },
      {
        title: 'Mon tableau est erroné',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6940517-declarations-tva" target="_blank"><b>Déclaration - TVA</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6940517-declarations-tva',
      },
      {
        title: 'Comment facturer en TTC ?',
        text: 'Si vous ne facturez pas la TVA et n\'êtes donc pas redevable de la TVA, votre facture doit rester en HT. Dans le cas contraire un montant TTC signifie que vous facturez à la TVA.<br>'
          + 'Si vous facturez la TVA mais que vos factures sont renseignées uniquement en HT alors vous devez l\'activer sur Abby. Retrouvez la procédure dans notre article dédié : <a href="https://aide.abby.fr/fr/articles/6940517-declarations-tva" target="_blank"><b>Déclaration - TVA</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6940517-declarations-tva',
      },
    ],
  },
  {
    name: '🧙 Déclaration urssaf',
    code: 'urssaf_declaration',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'J\'ai déjà déclaré sur une autre plateforme (ex: Urssaf)',
        text: 'Tout à fait, vous pouvez déclarer votre CA depuis le site de l\'Urssaf, il vous suffit ensuite de renseigner Déclarer depuis une autre plateforme lorsque vous effectuez la déclaration sur Abby.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'Comment configurer mes taux de cotisations ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6482320-declarations-urssaf" target="_blank"><b>Déclarations - Urssaf</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6482320-declarations-urssaf',
      },
      {
        title: 'Je n’arrive pas à synchroniser mon compte Urssaf',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/7021646-resolutions-d-erreurs-liees-au-compte-urssaf" target="_blank"><b>Résolutions d\'erreurs liées au compte Urssaf</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/7021646-resolutions-d-erreurs-liees-au-compte-urssaf',
      },
      {
        title: 'Que faire si j\'oublie de déclarer mon CA ou si je veux modifier ma déclaration ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6542574-faq-comptabilite" target="_blank"><b>FAQ - Comptabilité</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6542574-faq-comptabilite',
      },
      {
        title: 'Je n\'arrive pas à ajouter de mandat SEPA pour payer mes cotisations sociales',
        text: 'Vous pouvez ajouter votre mandat SEPA depuis le site de l\'Urssaf. En revenant sur Abby celui-ci apparaîtra sur Abby.<br><a href="https://aide.abby.fr/fr/articles/7021646-resolutions-d-erreurs-liees-au-compte-urssaf" target="_blank">Résolutions d\'erreurs liées au compte Urssaf</a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/7021646-resolutions-d-erreurs-liees-au-compte-urssaf',
      },

    ],
  },
  {
    name: '👨‍👧‍👦 Parrainage',
    code: 'sponsorship',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'Pensez à préciser par exemple l\'adresse email de votre parrain et / ou de votre filleul si votre demande s\'y relate de façon à vous donner une réponse plus rapidement.',
    topRatedQuestions: [
      {
        title: 'Comment retirer mes gains ?',
        text: 'Pour que nous puissions vous verser vos gains, pouvez-vous nous communiquer votre RIB ?<br><a href="https://aide.abby.fr/fr/articles/6538470-comment-fonctionne-le-parrainage" target="_blank"><b>Comment fonctionne le parrainage ?</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6538470-comment-fonctionne-le-parrainage',
      },
      {
        title: 'Les gains rentrent-ils dans le chiffre d\'affaires ?',
        text: 'S\'il s\'agit de gains occasionnels : pas besoin de le faire rentrer dans votre chiffre d\'affaires, c\'est direction votre poche 💸<br>S\'il s\'agit de gains réguliers ou votre plus grosse source de revenus sans en faire votre activité (par exemple 1000€ à l\'année), les gains rentrent dans votre chiffre d\'affaires en BNC<br>Si vous en faites une activité (affiliation, apporteur d\'affaires), les gains rentrent dans votre chiffre d\'affaires en BNC',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
    ],
  },
  {
    name: '🔗 Intégrations',
    code: 'integrations',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.DISCORD,
    answerText: 'Concernant les intégrations, nous vous invitons à poser vos questions sur le Discord d\'Abby.<br>Voir l\'article : <a href="https://aide.abby.fr/fr/articles/6840058" target="_blank"><b>Comment rejoindre la communauté Abby sur Discord</b></a>',
    answerUrl: 'https://aide.abby.fr/fr/articles/6840058',
  },
  {
    name: '📈 Statistiques',
    code: 'statistics',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Pourquoi je ne peux pas voir les données remontant à plus de trois mois ?',
        text: 'La page analyse sans restriction est disponible avec notre offre Pro 🚀',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
    ],
  },
  {
    name: '📊 Productivité',
    code: 'productivity',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'N\'oubliez pas de bien préciser votre commande / projet / temps saisi ou tâche pour une prise en charge plus rapide par le support.',
    topRatedQuestions: [
      {
        title: 'Comment fonctionne le pilotage d\'entreprise ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6544605-piloter-son-entreprise-avec-abby" target="_blank"><b>Piloter son entreprise avec Abby</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6544605-piloter-son-entreprise-avec-abby',
      },
    ],
  },
  {
    name: '🕵️‍ Compte utilisateur Abby',
    code: 'user_account',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Comment changer mon adresse mail ?',
        text: 'Si vous utilisez votre compte google ou facebook pour vous connecter, il n\'est pas possible de modifier l\'adresse mail. Dans le cas contraire, vous pouvez le faire depuis la page Réglages - Réglages généraux - Paramétrer les informations de l\'entreprise et du compte<br><a href="https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte" target="_blank"><b>Paramétrer les informations de l\'entreprise et du compte</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte',
      },
      {
        title: 'Comment modifier les informations de mon entreprise/ compte Abby ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte" target="_blank"><b>Paramétrer les informations de l\'entreprise et du compte</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte',
      },
      {
        title: 'Je suis repassé à l’offre Abby basique',
        text: 'Un problème a été détecté lors d\'un ou des prélèvements de votre abonnement. Pour régulariser la situation, rendez-vous sur la page Réglage > Paiement afin d\'ajouter un moyen de paiement. Si vous avez déjà un moyen de paiement de renseigner, il convient de le supprimer avant d\'en ajouter un nouveau.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
      {
        title: 'La facture de mon abonnement Abby est de 0 €',
        text: 'Pas d\'inquiétude, notre partenaire a besoin d\'une empreinte bancaire pour mettre en place l\'abonnement. Si vous validez le paiement, vous serez bien prélevé du bon montant. Si cela ne fonctionne pas malgré la validation, retentez l\'opération.',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
      },
    ],
  },
  {
    name: '🎓 Légal',
    code: 'lawful',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.DISCORD,
    topRatedQuestions: [
      {
        title: 'Est-ce que je peux avoir 2 micro-entreprises ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6534664-faq-creation#h_663bc1cf9c" target="_blank"><b>Créer une micro-entreprise si vous en avez déjà une</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6534664-faq-creation#h_663bc1cf9c',
      },
      {
        title: 'Est-ce que je peux être fonctionnaire ou salarié et être entrepreneur en même temps ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6534664-faq-creation#h_53ef2b15a5" target="_blank"><b>Puis-je être salarié ou fonctionnaire et micro-entrepreneur ?</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6534664-faq-creation#h_53ef2b15a5',
      },
    ],
  },
  {
    name: '⬇️ Imports',
    code: 'imports',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'N\'oubliez pas de bien détailler votre demande (type d\'import concerné) pour une meilleure prise en charge de votre demande par le support.',
    topRatedQuestions: [
      {
        title: 'Je n’arrive pas à importer',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby" target="_blank"><b>Importer ses données sur Abby</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby',
      },
      {
        title: 'Comment importer mes données ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby" target="_blank"><b>Importer ses données sur Abby</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby',
      },
      {
        title: 'Quelles données je peux importer sur Abby ?',
        text: 'Vous pouvez importer sur Abby vos articles / prestations, vos clients ainsi que votre livre des recettes (factures soldées).<br><a href="https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby" target="_blank"><b>Importer ses données sur Abby</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6661664-importer-ses-donnees-sur-abby',
      },
    ],
  },
  {
    name: '⬆️ Exports',
    code: 'exports',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'N\'oubliez pas de bien détailler votre demande (type d\'export concerné) pour une meilleure prise en charge de votre demande par le support.',
    topRatedQuestions: [
      {
        title: 'Quelles données peut-on exporter ?',
        text: 'Vous pouvez exporter l\'ensemble des données renseignées sur Abby dans différents formats.<br><a href="https://aide.abby.fr/fr/articles/6899224-exporter-ses-donnees-depuis-abby" target="_blank"><b>Exporter ses données</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6899224-exporter-ses-donnees-depuis-abby',
      },
    ],
  },
  {
    name: '💳 Compte bancaire',
    code: 'bank_account',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'N\'oubliez pas de bien détailler votre demande (votre banque par ex.) pour une meilleure prise en charge de votre demande par le support.',
    topRatedQuestions: [
      {
        title: 'Est ce que ma banque peut être synchronisée ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6615605-faq-synchronisation-bancaire" target="_blank"><b>FAQ - Synchronisation bancaire</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6615605-faq-synchronisation-bancaire',
      },
      {
        title: 'Mes transactions sont en double',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6542574-faq-comptabilite#h_01faaa2b10" target="_blank"><b>FAQ - Comptabilité</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6542574-faq-comptabilite#h_01faaa2b10',
      },
      {
        title: 'Je n\'arrive pas à synchroniser mon compte bancaire',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6615605-faq-synchronisation-bancaire" target="_blank"><b>FAQ - Synchronisation bancaire</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6615605-faq-synchronisation-bancaire',
      },
    ],
  },
  {
    name: '🏫 Academy',
    code: 'academy',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
  },
  {
    name: '🏪 Store',
    code: 'store',
    type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG, HelpcenterCategory.SUGGESTION],
    recommendedSupportMode: SupportMode.CHAT,
  },

  {
    name: '👁 Suivi de mon dossier de création',
    code: 'follow_creation',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Où en est mon dossier ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6463312-comment-connaitre-l-avancee-de-mon-dossier" target="_blank"><b>Connaître l\'avancée de mon dossier</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6463312-comment-connaitre-l-avancee-de-mon-dossier',
      },
      {
        title: 'Où trouver mon KBIS ?',
        text: 'Vous pouvez retrouver votre Kbis en vous rendant dans l\'onglet Suivi de création > Document afin de le télécharger en PDF. Si l\'entreprise a été déclarée à partir du 02/02/2023, vous recevrez le Kbis par courrier.',
        type: [HelpcenterCategory.CREATION],
      },
    ],
  },
  {
    name: '🤔 Problèmes liés à la création / modification',
    code: 'issues_with_creation_modification',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Pourquoi mon SIREN n’apparait pas sur mon KBIS ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6534664-faq-creation#h_b85a094e10" target="_blank"><b>Pourquoi je n\'ai pas de SIREN sur mon KBIS ?</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6534664-faq-creation#h_b85a094e10',
      },
      {
        title: 'Pourquoi il y a l’adresse de Delia Solutions sur mon document ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6534664-faq-creation#h_5835978166" target="_blank"><b>Pourquoi Delia Solutions est inscrit sur mon avis de situation SIREN ?</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6534664-faq-creation#h_5835978166',
      },
      {
        title: 'Je ne peux pas créer mon compte urssaf',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6463653-comment-creer-un-compte-urssaf" target="_blank"><b>Comment créer mon compte Urssaf ?</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6463653-comment-creer-un-compte-urssaf',
      },
      {
        title: 'Je n’arrive pas à déposer un document',
        text: 'Ces ressources vous aideront peut-être : <a href="https://aide.abby.fr/fr/articles/6460256-quels-sont-les-documents-a-fournir-pour-la-creation-d-une-micro-entreprise-sur-abby" target="_blank"><b>Quels sont les documents à fournir ?</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6460256-quels-sont-les-documents-a-fournir-pour-la-creation-d-une-micro-entreprise-sur-abby',
      },
    ],
  },
  {
    name: '❌ Document manquant ou refusé',
    code: 'missing_document',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'Précisez bien quel(s) document(s) pour une meilleure prise en charge de votre demande par le support.',
    topRatedQuestions: [
      {
        title: 'Que faire si mon document est manquant ou refusé ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6534013-creation-document-refuse" target="_blank"><b>Création - Document manquant ou refusé</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6534013-creation-document-refuse',
      },
    ],
  },
  {
    name: '✏️ Demande de modification',
    code: 'modification_request',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    descriptionTip: 'Précisez bien quelle(s) information(s) doivent être modifiées pour une meilleure prise en charge de votre demande par le support.',
    topRatedQuestions: [
      {
        title: 'Comment faire ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_cac3ac5143" target="_blank"><b>Modifier mon activité</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_cac3ac5143',
      },
    ],
  },
  {
    name: '🗑 Demande de cessation',
    code: 'cessation_request',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Comment faire ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_e328b32b04" target="_blank"><b>Cesser mon activité</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_e328b32b04',
      },
    ],
  },
  {
    name: '↪️ Changement de statut',
    code: 'status_change_request',
    type: [HelpcenterCategory.CREATION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Comment changer mon statut ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_79a8e9f497" target="_blank"><b>Changer mon statut</b></a>',
        type: [HelpcenterCategory.CREATION],
        url: 'https://aide.abby.fr/fr/articles/6539246-modifier-ou-cesser-mon-activite-ou-changer-de-statut#h_79a8e9f497',
      },
    ],
  },

  {
    name: '🏆 Je souhaite m\'abonner',
    code: 'want_subscribe',
    type: [HelpcenterCategory.SUBSCRIPTION],
    descriptionTip: 'Vous pouvez préciser à quelle offre vous voulez vous abonner et si c\'est en paiement mensuel ou annuel.',
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Comment faire ?',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise" target="_blank"><b>Inscription à nos démonstrations : Classique - SAP - Création d\'entreprise</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise',
      },
    ],
  },
  {
    name: '💬 Demande d\'informations complémentaires',
    code: 'want_informations',
    type: [HelpcenterCategory.SUBSCRIPTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'Est ce que Abby est fait pour les sociétés ?',
        text: 'Abby est tout à fait utilisable quelque soit le statut de l’entreprise. La seule différence concerne le bilan fiscal que nous ne pouvons pas éditer. Cependant, votre expert comptable pourra se connecter à votre compte afin d’avoir toutes les données nécessaires pour l’établir.',
        type: [HelpcenterCategory.SUBSCRIPTION],
      },
      {
        title: 'Participer à une démonstration d\'Abby',
        text: 'Vous trouverez la réponse sur cet article : <a href="https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise" target="_blank"><b>Inscription à nos démonstrations : Classique - SAP - Création d\'entreprise</b></a>',
        type: [HelpcenterCategory.HELP_SUPPORT, HelpcenterCategory.BUG],
        url: 'https://aide.abby.fr/fr/articles/6466270-inscription-a-nos-demonstrations-classique-sap-creation-d-entreprise',
      },
    ],
  },
  {
    name: '❓ Problème relatif à mon abonnement',
    code: 'subscription_problem',
    type: [HelpcenterCategory.SUBSCRIPTION],
    recommendedSupportMode: SupportMode.CHAT,
    topRatedQuestions: [
      {
        title: 'La facture de mon abonnement Abby est de 0 €',
        text: 'Notre partenaire a besoin d’une empreinte bancaire, voilà pourquoi le montant est à 0€. Cependant, si vous validez le paiement vous serez bien prélevé du bon montant.',
        type: [HelpcenterCategory.SUBSCRIPTION],
      },
    ],
  },
  {
    name: '🔚 Je souhaite résilier',
    code: 'want_stop_subscription',
    type: [HelpcenterCategory.SUBSCRIPTION],
    descriptionTip: 'Pouvez-vous nous transmettre le motif de votre résiliation ? Cela nous permettra d\'améliorer toujours plus Abby !',
    recommendedSupportMode: SupportMode.CHAT,
    answerText: 'Vous pouvez suspendre votre abonnement via la page Réglages > Paiement.<br>Voir l\'article : <a href="https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte" target="_blank"><b>Paramétrer les informations de l\'entreprise et du compte</b></a>',
    answerUrl: 'https://aide.abby.fr/fr/articles/6556938-reglages-generaux-parametrer-les-informations-de-l-entreprise-et-du-compte',
  },
  {
    name: '🔚 Je souhaite résilier mon abonnement CE',
    code: 'want_stop_subscription_ce',
    type: [HelpcenterCategory.SUBSCRIPTION],
    descriptionTip: 'Pouvez-vous nous transmettre le motif de votre résiliation ? Cela nous permettra d\'améliorer toujours plus Abby !',
    recommendedSupportMode: SupportMode.CHAT,
    answerText: 'Vous pouvez suspendre l\'abonnement en vous rendant sur la page Réglages > Paiement, Puis cliquez sur Suspendre.',
  },

];
