import { ReadContactListItem, Time } from '@abby/shared'
import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'

export class ContactItemMapper {
  static toDomain (result: ReadContactListItem) {
    return ContactItem.create({
      id: result.id,
      fullname: result.fullname,
      emails: result.emails || [],
      defaultContact: result.defaultContact,
      createdAt: Time.toDate(result.createdAt),
      archivedAt: result.archivedAt ? Time.toDate(result.archivedAt) : undefined,
      organization: result.organization
        ? {
          id: result.organization.id,
          name: result.organization.name,
        }
        : undefined,
      tiersPrestation: result.tiersPrestation,
      hasBillings: result.hasBillings,
      test: result.test,
    })
  }
}
