export enum DeclarationPlatform {
  GUICHET_UNIQUE = 6,
  GUICHET_ENTREPRISE = 1,
  INFOGREFFE = 2,
  CFE_METIER = 3,
  AUTO_ENTREPRENEUR_URSSAF = 4,
  GUICHET_ENTREPRISE_CMA = 5,
}

export const DeclarationPlatformColor = {
  [DeclarationPlatform.GUICHET_UNIQUE]: 'orange lighten-4',
  [DeclarationPlatform.GUICHET_ENTREPRISE]: 'orange lighten-4',
  [DeclarationPlatform.INFOGREFFE]: 'blue lighten-4',
  [DeclarationPlatform.CFE_METIER]: 'brown lighten-4',
  [DeclarationPlatform.AUTO_ENTREPRENEUR_URSSAF]: 'green lighten-4',
  [DeclarationPlatform.GUICHET_ENTREPRISE_CMA]: 'orange lighten-2',
};
