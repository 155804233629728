import { ActionTree, GetterTree, MutationTree } from 'vuex'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import type {
  BasePaginateMongo,
  CreatePurchaseRegisterItemLegacy,
  QueryPurchaseRegisterLegacy,
  ReadPurchaseRegisterItemLegacy,
  UpdatePurchaseRegisterItemLegacy,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface PurchaseRegisterQuery {
  search?: string | undefined,
}

export interface PurchaseRegisterStatisticsQuery {
  search?: string | undefined,
  from?: string | undefined,
  to?: string | undefined,
}

export interface PurchaseRegisterLegacyState {
  isPurchaseRegisterModalOpened: boolean;
  pagination: BasePaginateMongo<ReadPurchaseRegisterItemLegacy> | null;
  queryLoading: boolean;
  initialLoading: boolean;
  purchaseRegisterItem: ReadPurchaseRegisterItemLegacy | null;
  paginationQuery: QueryPurchaseRegisterLegacy,
  query: PurchaseRegisterQuery;
  statistics: number | null;
  statisticsLoading: boolean,
  queryStatisticsLoading: boolean,
  queryStatistics: PurchaseRegisterStatisticsQuery
}

export const state = (): PurchaseRegisterLegacyState => ({
  isPurchaseRegisterModalOpened: false,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 25,
    range: [`${dayjs().get('year')}-01-01`, dayjs().format('YYYY-MM-DD')],
  },
  query: {
    search: undefined,
  },
  queryLoading: true,
  purchaseRegisterItem: null,
  initialLoading: true,
  statistics: null,
  statisticsLoading: true,
  queryStatisticsLoading: true,
  queryStatistics: {
    from: `${dayjs().get('year')}-01-01`,
    to: dayjs().format('YYYY-MM-DD'),
  },
})

export const getters: GetterTree<PurchaseRegisterLegacyState, PurchaseRegisterLegacyState> = {
  pagination (state: PurchaseRegisterLegacyState) {
    return state.pagination
  },
  paginationQuery (state: PurchaseRegisterLegacyState) {
    return state.paginationQuery
  },
  purchaseRegisterItems: (state: PurchaseRegisterLegacyState) => {
    return (state.pagination?.docs || [])
  },
  purchaseRegisterItem: (state: PurchaseRegisterLegacyState) => {
    return state.purchaseRegisterItem
  },
  isPurchaseRegisterModalOpened (state: PurchaseRegisterLegacyState) {
    return state.isPurchaseRegisterModalOpened
  },
  queryLoading (state: PurchaseRegisterLegacyState) {
    return state.queryLoading
  },
  initialLoading (state: PurchaseRegisterLegacyState) {
    return state.initialLoading
  },
  query (state: PurchaseRegisterLegacyState) {
    return state.query
  },
  statistics (state: PurchaseRegisterLegacyState) {
    return state.statistics
  },
  statisticsLoading (state: PurchaseRegisterLegacyState) {
    return state.statisticsLoading
  },
  queryStatistics (state: PurchaseRegisterLegacyState) {
    return state.queryStatistics
  },
  queryStatisticsLoading (state: PurchaseRegisterLegacyState) {
    return state.queryStatisticsLoading
  },
}

export const mutations: MutationTree<PurchaseRegisterLegacyState> = {
  SET_PAGINATION_QUERY (state: PurchaseRegisterLegacyState, paginationQuery: QueryPurchaseRegisterLegacy) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: PurchaseRegisterLegacyState, pagination: BasePaginateMongo<ReadPurchaseRegisterItemLegacy> | null): void {
    state.pagination = pagination
  },
  SET_INITIAL_LOADING (state: PurchaseRegisterLegacyState, value: boolean): void {
    state.initialLoading = value
  },
  SET_QUERY (state: PurchaseRegisterLegacyState, value: PurchaseRegisterQuery): void {
    state.query = value
  },
  SET_QUERY_LOADING (state: PurchaseRegisterLegacyState, value: boolean): void {
    state.queryLoading = value
  },
  SET_IS_INCOME_BOOK_ITEM_MODAL_OPENED (state: PurchaseRegisterLegacyState, value: boolean) {
    state.isPurchaseRegisterModalOpened = value
  },
  SET_PURCHASE_REGISTER_ITEM (state: PurchaseRegisterLegacyState, value: ReadPurchaseRegisterItemLegacy | null) {
    state.purchaseRegisterItem = value
  },
  SET_STATISTICS (state: PurchaseRegisterLegacyState, value: any | null): void {
    state.statistics = value
  },

  SET_STATISTICS_LOADING (state: PurchaseRegisterLegacyState, value: boolean): void {
    state.statisticsLoading = value
  },
  SET_QUERY_STATISTICS (state: PurchaseRegisterLegacyState, value: PurchaseRegisterStatisticsQuery): void {
    state.queryStatistics = value
  },
  SET_QUERY_STATISTICS_LOADING (state: PurchaseRegisterLegacyState, value: boolean): void {
    state.queryStatisticsLoading = value
  },
  RESET (_currentState: PurchaseRegisterLegacyState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<PurchaseRegisterLegacyState, RootState> = {
  async fetchPurchaseRegister ({ commit, getters }) {
    commit('SET_QUERY_LOADING', true)
    try {
      const pagination = await this.$api.purchaseRegisterLegacy.getPaginated({
        ...getters.paginationQuery as QueryPurchaseRegisterLegacy,
        ...getters.query,
      })
      commit('SET_PAGINATION', pagination)
    } finally {
      commit('SET_INITIAL_LOADING', false)
      commit('SET_QUERY_LOADING', false)
    }
  },
  updateQuery ({ commit, dispatch }, query: Partial<PurchaseRegisterQuery>) {
    commit('SET_QUERY', query)
    dispatch('debounceFetchPurchaseRegister')
  },
  async updatePurchaseRegisterItem ({ dispatch }, data: UpdatePurchaseRegisterItemLegacy) {
    await this.$api.purchaseRegisterLegacy.updateItem(data)
    dispatch('fetchPurchaseRegister')
    dispatch('fetchStatistics')
  },
  debounceFetchPurchaseRegister: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchPurchaseRegister')
  }, 300, { leading: true }),
  debounceFetchStatistics: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchStatistics')
  }, 300, { leading: true }),
  setPurchaseRegisterItem ({ commit }, value: ReadPurchaseRegisterItemLegacy | null) {
    commit('SET_PURCHASE_REGISTER_ITEM', value)
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<QueryPurchaseRegisterLegacy>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchPurchaseRegister')
  },
  async addPurchaseRegisterItem ({ dispatch }, value: CreatePurchaseRegisterItemLegacy) {
    await this.$api.purchaseRegisterLegacy.createItem(value)
    dispatch('fetchPurchaseRegister')
    dispatch('fetchStatistics')
  },
  async deletePurchaseRegisterItem ({ dispatch }, value: string) {
    await this.$api.purchaseRegisterLegacy.deleteItem(value)
    dispatch('fetchPurchaseRegister')
    dispatch('fetchStatistics')
  },
  async fetchStatistics ({ commit, getters }) {
    try {
      commit('SET_STATISTICS_LOADING', true)
      const statistics = await this.$api.purchaseRegisterLegacy.getStatistics({
        ...getters.queryStatistics,
      })
      commit('SET_STATISTICS', statistics)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    } finally {
      commit('SET_STATISTICS_LOADING', false)
    }
  },
  updateQueryStatistics ({ commit, dispatch }, query: Partial<PurchaseRegisterStatisticsQuery>) {
    commit('SET_QUERY_STATISTICS', query)
    dispatch('debounceFetchStatistics')
  },
}
