import {
  BillingType,
  BillingTypeText,
  convertBillingTypeToText,
  Currency,
  DefaultTemplateEmailCustomisation,
  EmailCustomisationVariable,
  EmailType,
  IBilling,
  IUser,
  PaymentDelayItems,
} from '../types';
import {
  customerFirstname, customerLastname, getCompleteTotalAmount, getDueDate,
} from './billing';
import { formatCents } from './common';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const dayjs = require('dayjs');

export function getAvailableVarsByType(type: string | string[]) {
  return EmailCustomisationVariable.filter(ecv => [...type].includes(ecv.type));
}
export function getDefaultTemplateEmail(type: EmailType, forVat = false) {
  return DefaultTemplateEmailCustomisation.find(tec => tec.type === type && tec.forVat === forVat);
}

async function fillBillingTags(data: { subject: string; email: string; billing: IBilling; user: IUser; brandUrl: string; createdFromDocument?: IBilling }): Promise<{ subject: string; email: string } | null> {
  const variables = EmailCustomisationVariable.filter(ecv => [convertBillingTypeToText(data.billing.billingType || BillingType.INVOICE), BillingTypeText.BILLING].includes(ecv.type));
  let _subject = data.subject;
  let _email = data.email;
  const billingTypeText = {
    [BillingType.INVOICE]: 'la facture',
    [BillingType.ESTIMATE]: 'le devis',
    [BillingType.ADVANCE]: 'l\'acompte',
    [BillingType.ASSET]: 'l\'avoir',
    [BillingType.PURCHASE_ORDER]: 'le bon de commande',
  };
  const amount = getCompleteTotalAmount(data.billing, data.createdFromDocument);
  const currency = data.billing.currency || Currency.EUR;
  const priceTotalTax = typeof amount.remainingPriceToPayTotalTax === 'number' ? amount.remainingPriceToPayTotalTax : amount.priceTotalTax;
  const priceWithoutTax = typeof amount.remainingPriceToPayWithoutTax === 'number' ? amount.remainingPriceToPayWithoutTax : amount.priceWithoutTax;
  const dataTags = await variables.reduce(async (acc: Promise<any>, el) => {
    const tagData = () => {
      let paymentLink = '';
      switch (el.tag) {
        case '{{ prenom }}':
          return data.user.firstname || '';
        case '{{ nom }}':
          return data.user.lastname || '';
        case '{{ prenom client }}':
          return customerFirstname(data.billing.customer) || '';
        case '{{ nom client }}':
          return customerLastname(data.billing.customer) || '';
        case '{{ numero du document }}':
          return data.billing.number ?? 'brouillon';
        case '{{ montant HT }}':
          return formatCents(priceWithoutTax, currency).text;
        case '{{ montant TTC }}':
          return formatCents(priceTotalTax, currency).text;
        case '{{ date emission }}':
          return dayjs(data.billing.date || dayjs()).format('DD/MM/YYYY');
        case '{{ date echeance }}':
          return dayjs(getDueDate(data.billing.paymentDelay as PaymentDelayItems, data.billing.date || dayjs().toDate()) || dayjs().toDate()).format('DD/MM/YYYY');
        case '{{ type de document }}':
          return billingTypeText[data.billing.billingType || BillingType.INVOICE];
        case '{{ page de signature du devis }}':
          if (data.billing?._id && data.billing.customer?._id && data.billing.useSignature) {
            const signLink = `${data.brandUrl}/clientPortal?id=${data.billing.customer?._id ?? ''}&billing=${data.billing?._id ?? ''}&token=${data.billing.customer?.token ?? ''}`;
            return `<p>Vous pouvez signer ce devis en ligne en cliquant ici : <a target="_blank" rel="noopener noreferrer nofollow" href="${signLink}">Lien de signature en ligne</a>.</p>`;
          }
          return '';
        case '{{ page de paiement }}':
          if (!data.brandUrl || !data.billing.useStripePayment || !data.billing.stripePaymentActivated) {
            return '';
          }
          paymentLink = data.billing.token
            ? `${data.brandUrl}/payment?id=${data.billing?._id ?? ''}&token=${data.billing?.token ?? ''}`
            : `${data.brandUrl}/clientPortal?id=${data.billing.customer?._id ?? ''}&billing=${data.billing?._id ?? ''}&token=${data.billing.customer?.token ?? ''}`;
          return `<p>Vous pouvez payer votre facture en ligne en cliquant ici : <a target="_blank" rel="noopener noreferrer nofollow" href="${paymentLink}">Lien de paiement en ligne</a>.</p>`;
        default:
          return '';
      }
    };
    return [
      ...await acc,
      {
        tag: el.tag,
        data: tagData(),
      },
    ];
  }, Promise.resolve([]));

  for (const dataTag of dataTags) {
    _subject = _subject.replace(new RegExp(dataTag.tag, 'g'), dataTag.data);
    _email = _email.replace(new RegExp(dataTag.tag, 'g'), dataTag.data);
  }
  return {
    subject: _subject,
    email: _email,
  };
}

export async function setTagsToDatas(type: EmailType, hasAccessTo: boolean, data: any): Promise<{ subject: string; email: string } | null> {
  let content = null;
  const hasVat = !!data.company?.hasVat;
  const defaultTemplate = getDefaultTemplateEmail(type, hasVat);
  if (hasAccessTo && type === EmailType.INVOICE_SIMPLE) {
    content = {
      subject: data.billing?.customEmail?.subject ?? defaultTemplate?.subject ?? '',
      email: data.billing?.customEmail?.email ?? defaultTemplate?.email ?? '',
    };
  }
  else if (hasAccessTo && type === EmailType.INVOICE_RECURRING) {
    content = {
      subject: data.billing?.recurring?.customEmail?.subject ?? defaultTemplate?.subject ?? '',
      email: data.billing?.recurring?.customEmail?.email ?? defaultTemplate?.email ?? '',
    };
  }
  else if (hasAccessTo && type === EmailType.INVOICE_REMINDER) {
    content = {
      subject: data.billing?.reminder?.customEmail?.subject ?? defaultTemplate?.subject ?? '',
      email: data.billing?.reminder?.customEmail?.email ?? defaultTemplate?.email ?? '',
    };
  }
  else if (hasAccessTo && type === EmailType.ESTIMATE_SIMPLE) {
    content = {
      subject: data.billing?.customEmailEstimate?.subject ?? defaultTemplate?.subject ?? '',
      email: data.billing?.customEmailEstimate?.email ?? defaultTemplate?.email ?? '',
    };
  }
  else {
    content = {
      subject: defaultTemplate?.subject ?? '',
      email: defaultTemplate?.email ?? '',
    };
  }
  return fillBillingTags({
    ...data,
    ...content,
  });
}
