import { ClientAddress, SapAddress } from '@abby/shared'
import { ContactTPForm } from '~/services/client/contact/forms/ContactTP.form'
import { ContactForm } from '~/services/client/contact/forms/Contact.form'
import {
  useI18nManager,
} from '~/composables/shared/manager/useI18nManager'

const addressSapToClientAddressMapper = (addressSap: SapAddress, localisationManager: any): ClientAddress => {

  return {
    address: `${addressSap.place ? `${addressSap.place} ` : ''}${addressSap.houseNumber ? `${addressSap.houseNumber} ` : ''}${addressSap.repetitionIndex ? localisationManager.t(`urssaf_address_letter.${addressSap.repetitionIndex}`) + ' ' : ''}${addressSap.typeOfRoad ? localisationManager.t(`urssaf_lane.${addressSap.typeOfRoad}`) + ' ' : ''}${addressSap.street ? `${addressSap.street} ` : ''}`.trim(),
    complement: addressSap.complement,
    place: addressSap.place,
    city: addressSap.city,
    zipCode: addressSap.zip,
    country: addressSap.country,
  }
}

export function useCreateContactFormFromContactTPForm () {
  const localisationManager = useI18nManager()
  return (contactTP: ContactTPForm): ContactForm => {
    return {
      id: contactTP.contactId,
      firstname: contactTP.firstname,
      lastname: (contactTP.lastname ? contactTP.lastname : contactTP.birthname)!,
      emails: [contactTP.email],
      phone: contactTP.mobilePhone,
      billingAddress: addressSapToClientAddressMapper(contactTP.address, localisationManager),
    }
  }
}
