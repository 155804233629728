export enum AffilaePrograms {
  ABBY = '620239042458145515dfbb22',
}

export enum AffilaeRules {
  INSCRIPTION_CREATION = '6216554885baad276cc3b5e3',
  INSCRIPTION_GESTION = '6216554d85baad276cc3b5e4',
  PERIODE_ESSAI_START = '6327398175e926781a2853f3',
  PERIODE_ESSAI_PRO = '6327399e75e926781a2853f4',
  PERIODE_ESSAI_BUSINESS = '66db1490bc37be5a3d047d23',
  ABONNEMENT_MENSUEL_ATTENTE_PREMIER_PAIEMENT_START = '6327380675e926781a2853e6',
  ABONNEMENT_MENSUEL_ATTENTE_PREMIER_PAIEMENT_PRO = '632738f775e926781a2853f1',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  ABONNEMENT_MENSUEL_ATTENTE_PREMIER_PAIEMENT_BUSINESS = '632738f775e926781a2853f1',
  PAIEMENT_CREATION_START = '66e84c03321e42b280ab1b2b',
  PAIEMENT_CREATION_PRO = '66e84c1de51cc3dd11185ab1',
  PAIEMENT_CREATION_BUSINESS = '66e84c262274a79bf171b722',
  PREMIER_PAIEMENT_START = '6327392d75e926781a2853f2',
  PREMIER_PAIEMENT_PRO = '63273935296b7178ae6dea60',
  PREMIER_PAIEMENT_BUSINESS = '66db14a27151c676beaeabdc',
  PREMIER_PAIEMENT_CE = '63fb8f883d56e55e452a8eca',
}
