import { Extension, InputRule } from '@tiptap/core'
// @ts-ignore
import { Plugin, PluginKey } from '@tiptap/pm/state'
// @ts-ignore
import { Node as ProsemirrorNode } from '@tiptap/pm/model'
// @ts-ignore
import { Decoration, DecorationSet, EditorView } from '@tiptap/pm/view'

const imageAttributesPluginKey = new PluginKey('imageAttributes')

export const ImageAttributesExtension = Extension.create({
  name: 'imageAttributes',

  addProseMirrorPlugins () {
    let view: EditorView | null = null

    return [
      new Plugin({
        key: imageAttributesPluginKey,
        view: (editorView: any) => {
          view = editorView
          return {}
        },
        props: {
          decorations (state: any) {
            const { doc } = state
            const decorations: Decoration[] = []

            const handleImageNode = (node: ProsemirrorNode, pos: number, view: EditorView | null, decorations: Decoration[]) => {
              if (node.type.name !== 'image') {
                return
              }

              const createWidgetSpan = () => {
                const span = document.createElement('span')
                updateImageWidth(pos, node, view)
                return span
              }

              const widget = Decoration.widget(pos, createWidgetSpan)
              decorations.push(widget)
            }

            const updateImageWidth = (pos: number, node: ProsemirrorNode, view: EditorView | null) => {
              setTimeout(() => {
                if (!view) { return }

                const img = view.nodeDOM(pos)
                if (!(img instanceof HTMLImageElement)) { return }

                const currentWidth = Math.round(img.offsetWidth)
                if (!shouldUpdateWidth(currentWidth, node.attrs.width)) { return }

                const updatedAttrs = {
                  ...node.attrs,
                  width: `${currentWidth}px`,
                }

                const transaction = view.state.tr.setNodeMarkup(pos, undefined, updatedAttrs)
                view.dispatch(transaction)
              }, 0)
            }

            const shouldUpdateWidth = (currentWidth: number, existingWidth: string) => {
              return currentWidth && (!existingWidth || existingWidth !== `${currentWidth}px`)
            }

            doc.descendants((node: any, pos: any) => {
              handleImageNode(node, pos, view, decorations)
            })

            return DecorationSet.create(doc, decorations)
          },
        },
      }),
    ]
  },

  addGlobalAttributes () {
    return [
      {
        types: ['image'],
        attributes: {
          width: {
            default: null,
            parseHTML: element => element.getAttribute('width'),
            renderHTML: (attributes) => {
              if (!attributes.width) {
                return {}
              }
              return { width: attributes.width }
            },
          },
        },
      },
    ]
  },

  addInputRules () {
    return [
      new InputRule({
        find: /(<img[^>]*width="(\d+)"[^>]*>)/g,
        handler: ({ state, range, match }) => {
          const [fullMatch, , width] = match
          const { tr } = state
          const start = range.from
          const end = start + fullMatch.length

          // Extract src and alt from the matched HTML
          const srcMatch = fullMatch.match(/src="([^"]*)"/)
          const altMatch = fullMatch.match(/alt="([^"]*)"/)

          const attrs = {
            src: srcMatch ? srcMatch[1] : '',
            alt: altMatch ? altMatch[1] : '',
            width: `${width}px`,
          }

          const node = state.schema.nodes.image.create(attrs)
          tr.replaceRangeWith(start, end, node)
        },
      }),
    ]
  },
})
