const countries = {
  AF: 'Afghanistan',
  ZA: 'Afrique du Sud',
  AL: 'Albanie',
  DZ: 'Algérie',
  DE: 'Allemagne',
  AD: 'Andorre',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctique',
  AG: 'Antigua-et-Barbuda',
  AN: 'Antilles néerlandaises',
  SA: 'Arabie saoudite',
  AR: 'Argentine',
  AM: 'Arménie',
  AW: 'Aruba',
  AU: 'Australie',
  AT: 'Autriche',
  AZ: 'Azerbaïdjan',
  BS: 'Bahamas',
  BH: 'Bahreïn',
  BD: 'Bangladesh',
  BB: 'Barbade',
  BY: 'Bélarus',
  BE: 'Belgique',
  BZ: 'Belize',
  BJ: 'Bénin',
  BM: 'Bermudes',
  BT: 'Bhoutan',
  BO: 'Bolivie',
  BA: 'Bosnie-Herzégovine',
  BW: 'Botswana',
  BR: 'Brésil',
  BN: 'Brunéi Darussalam',
  BG: 'Bulgarie',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  CV: 'Cap-Vert',
  EA: 'Ceuta et Melilla',
  CL: 'Chili',
  CN: 'Chine',
  CY: 'Chypre',
  CO: 'Colombie',
  KM: 'Comores',
  CG: 'Congo-Brazzaville',
  KP: 'Corée du Nord',
  KR: 'Corée du Sud',
  CR: 'Costa Rica',
  CI: 'Côte d’Ivoire',
  HR: 'Croatie',
  CU: 'Cuba',
  DK: 'Danemark',
  DG: 'Diego Garcia',
  DJ: 'Djibouti',
  DM: 'Dominique',
  EG: 'Égypte',
  SV: 'El Salvador',
  AE: 'Émirats arabes unis',
  EC: 'Équateur',
  ER: 'Érythrée',
  ES: 'Espagne',
  EE: 'Estonie',
  VA: 'État de la Cité du Vatican',
  FM: 'États fédérés de Micronésie',
  US: 'États-Unis',
  ET: 'Éthiopie',
  FJ: 'Fidji',
  FI: 'Finlande',
  FR: 'France',
  GA: 'Gabon',
  GM: 'Gambie',
  GE: 'Géorgie',
  GS: 'Géorgie du Sud et les îles Sandwich du Sud',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GR: 'Grèce',
  GD: 'Grenade',
  GL: 'Groenland',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GN: 'Guinée',
  GQ: 'Guinée équatoriale',
  GW: 'Guinée-Bissau',
  GY: 'Guyana',
  GF: 'Guyane française',
  HT: 'Haïti',
  HN: 'Honduras',
  HU: 'Hongrie',
  BV: 'Île Bouvet',
  CX: 'Île Christmas',
  CP: 'Île Clipperton',
  AC: 'Île de l\'Ascension',
  IM: 'Île de Man',
  NF: 'Île Norfolk',
  AX: 'Îles Åland',
  KY: 'Îles Caïmans',
  IC: 'Îles Canaries',
  CC: 'Îles Cocos - Keeling',
  CK: 'Îles Cook',
  FO: 'Îles Féroé',
  HM: 'Îles Heard et MacDonald',
  FK: 'Îles Malouines',
  MP: 'Îles Mariannes du Nord',
  MH: 'Îles Marshall',
  UM: 'Îles Mineures Éloignées des États-Unis',
  SB: 'Îles Salomon',
  TC: 'Îles Turks et Caïques',
  VG: 'Îles Vierges britanniques',
  VI: 'Îles Vierges des États-Unis',
  IN: 'Inde',
  ID: 'Indonésie',
  IQ: 'Irak',
  IR: 'Iran',
  IE: 'Irlande',
  IS: 'Islande',
  IL: 'Israël',
  IT: 'Italie',
  JM: 'Jamaïque',
  JP: 'Japon',
  JE: 'Jersey',
  JO: 'Jordanie',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KI: 'Kiribati',
  XK: 'Kosovo',
  KW: 'Koweït',
  LA: 'Laos',
  LS: 'Lesotho',
  LV: 'Lettonie',
  LB: 'Liban',
  LR: 'Libéria',
  LY: 'Libye',
  LI: 'Liechtenstein',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MK: 'Macédoine',
  MG: 'Madagascar',
  MY: 'Malaisie',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malte',
  MA: 'Maroc',
  MQ: 'Martinique',
  MU: 'Maurice',
  MR: 'Mauritanie',
  YT: 'Mayotte',
  MX: 'Mexique',
  MD: 'Moldavie',
  MC: 'Monaco',
  MN: 'Mongolie',
  ME: 'Monténégro',
  MS: 'Montserrat',
  MZ: 'Mozambique',
  MM: 'Myanmar',
  NA: 'Namibie',
  NR: 'Nauru',
  NP: 'Népal',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigéria',
  NU: 'Niue',
  NO: 'Norvège',
  NC: 'Nouvelle-Calédonie',
  NZ: 'Nouvelle-Zélande',
  OM: 'Oman',
  UG: 'Ouganda',
  UZ: 'Ouzbékistan',
  PK: 'Pakistan',
  PW: 'Palaos',
  PA: 'Panama',
  PG: 'Papouasie-Nouvelle-Guinée',
  PY: 'Paraguay',
  NL: 'Pays-Bas',
  PE: 'Pérou',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Pologne',
  PF: 'Polynésie française',
  PR: 'Porto Rico',
  PT: 'Portugal',
  QA: 'Qatar',
  HK: 'R.A.S. chinoise de Hong Kong',
  MO: 'R.A.S. chinoise de Macao',
  QO: 'régions éloignées de l’Océanie',
  CF: 'République centrafricaine',
  CD: 'République démocratique du Congo',
  DO: 'République dominicaine',
  CZ: 'République tchèque',
  RE: 'Réunion',
  RO: 'Roumanie',
  GB: 'Royaume-Uni',
  RU: 'Russie',
  RW: 'Rwanda',
  EH: 'Sahara occidental',
  BL: 'Saint-Barthélémy',
  KN: 'Saint-Kitts-et-Nevis',
  SM: 'Saint-Marin',
  MF: 'Saint-Martin',
  PM: 'Saint-Pierre-et-Miquelon',
  VC: 'Saint-Vincent-et-les Grenadines',
  SH: 'Sainte-Hélène',
  LC: 'Sainte-Lucie',
  WS: 'Samoa',
  AS: 'Samoa américaines',
  ST: 'Sao Tomé-et-Principe',
  SN: 'Sénégal',
  RS: 'Serbie',
  CS: 'Serbie-et-Monténégro',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapour',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  SO: 'Somalie',
  SD: 'Soudan',
  LK: 'Sri Lanka',
  SE: 'Suède',
  CH: 'Suisse',
  SR: 'Suriname',
  SJ: 'Svalbard et Île Jan Mayen',
  SZ: 'Swaziland',
  SY: 'Syrie',
  TJ: 'Tadjikistan',
  TW: 'Taïwan',
  TZ: 'Tanzanie',
  TD: 'Tchad',
  TF: 'Terres australes françaises',
  IO: 'Territoire britannique de l\'océan Indien',
  PS: 'Territoire palestinien',
  TH: 'Thaïlande',
  TL: 'Timor oriental',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinité-et-Tobago',
  TA: 'Tristan da Cunha',
  TN: 'Tunisie',
  TM: 'Turkménistan',
  TR: 'Turquie',
  TV: 'Tuvalu',
  UA: 'Ukraine',
  EU: 'Union européenne',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viêt Nam',
  WF: 'Wallis-et-Futuna',
  YE: 'Yémen',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
};

const codes = {
  AF: 'AFG',
  AL: 'ALB',
  DZ: 'DZA',
  AS: 'ASM',
  AD: 'AND',
  AO: 'AGO',
  AI: 'AIA',
  AQ: 'ATA',
  AG: 'ATG',
  AR: 'ARG',
  AM: 'ARM',
  AW: 'ABW',
  AU: 'AUS',
  AT: 'AUT',
  AZ: 'AZE',
  BS: 'BHS',
  BH: 'BHR',
  BD: 'BGD',
  BB: 'BRB',
  BY: 'BLR',
  BE: 'BEL',
  BZ: 'BLZ',
  BJ: 'BEN',
  BM: 'BMU',
  BT: 'BTN',
  BO: 'BOL',
  BA: 'BIH',
  BW: 'BWA',
  BV: 'BVT',
  BR: 'BRA',
  IO: 'IOT',
  BN: 'BRN',
  BG: 'BGR',
  BF: 'BFA',
  BI: 'BDI',
  KH: 'KHM',
  CM: 'CMR',
  CA: 'CAN',
  CV: 'CPV',
  KY: 'CYM',
  CF: 'CAF',
  TD: 'TCD',
  CL: 'CHL',
  CN: 'CHN',
  CX: 'CXR',
  CC: 'CCK',
  CO: 'COL',
  KM: 'COM',
  CG: 'COG',
  CD: 'COD',
  CK: 'COK',
  CR: 'CRI',
  CI: 'CIV',
  HR: 'HRV',
  CU: 'CUB',
  CY: 'CYP',
  CZ: 'CZE',
  DK: 'DNK',
  DJ: 'DJI',
  DM: 'DMA',
  DO: 'DOM',
  EC: 'ECU',
  EG: 'EGY',
  SV: 'SLV',
  GQ: 'GNQ',
  ER: 'ERI',
  EE: 'EST',
  ET: 'ETH',
  FK: 'FLK',
  FO: 'FRO',
  FJ: 'FJI',
  FI: 'FIN',
  FR: 'FRA',
  GF: 'GUF',
  PF: 'PYF',
  TF: 'ATF',
  GA: 'GAB',
  GM: 'GMB',
  GE: 'GEO',
  DE: 'DEU',
  GH: 'GHA',
  GI: 'GIB',
  GR: 'GRC',
  GL: 'GRL',
  GD: 'GRD',
  GP: 'GLP',
  GU: 'GUM',
  GT: 'GTM',
  GG: 'GGY',
  GN: 'GIN',
  GW: 'GNB',
  GY: 'GUY',
  HT: 'HTI',
  HM: 'HMD',
  VA: 'VAT',
  HN: 'HND',
  HK: 'HKG',
  HU: 'HUN',
  IS: 'ISL',
  IN: 'IND',
  ID: 'IDN',
  IR: 'IRN',
  IQ: 'IRQ',
  IE: 'IRL',
  IM: 'IMN',
  IL: 'ISR',
  IT: 'ITA',
  JM: 'JAM',
  JP: 'JPN',
  JE: 'JEY',
  JO: 'JOR',
  KZ: 'KAZ',
  KE: 'KEN',
  KI: 'KIR',
  KP: 'PRK',
  KR: 'KOR',
  KW: 'KWT',
  KG: 'KGZ',
  LA: 'LAO',
  LV: 'LVA',
  LB: 'LBN',
  LS: 'LSO',
  LR: 'LBR',
  LY: 'LBY',
  LI: 'LIE',
  LT: 'LTU',
  LU: 'LUX',
  MO: 'MAC',
  MK: 'MKD',
  MG: 'MDG',
  MW: 'MWI',
  MY: 'MYS',
  MV: 'MDV',
  ML: 'MLI',
  MT: 'MLT',
  MH: 'MHL',
  MQ: 'MTQ',
  MR: 'MRT',
  MU: 'MUS',
  YT: 'MYT',
  MX: 'MEX',
  FM: 'FSM',
  MD: 'MDA',
  MC: 'MCO',
  MN: 'MNG',
  ME: 'MNE',
  MS: 'MSR',
  MA: 'MAR',
  MZ: 'MOZ',
  MM: 'MMR',
  NA: 'NAM',
  NR: 'NRU',
  NP: 'NPL',
  NL: 'NLD',
  AN: 'ANT',
  NC: 'NCL',
  NZ: 'NZL',
  NI: 'NIC',
  NE: 'NER',
  NG: 'NGA',
  NU: 'NIU',
  NF: 'NFK',
  MP: 'MNP',
  NO: 'NOR',
  OM: 'OMN',
  PK: 'PAK',
  PW: 'PLW',
  PS: 'PSE',
  PA: 'PAN',
  PG: 'PNG',
  PY: 'PRY',
  PE: 'PER',
  PH: 'PHL',
  PN: 'PCN',
  PL: 'POL',
  PT: 'PRT',
  PR: 'PRI',
  QA: 'QAT',
  RE: 'REU',
  RO: 'ROU',
  RU: 'RUS',
  RW: 'RWA',
  SH: 'SHN',
  KN: 'KNA',
  LC: 'LCA',
  PM: 'SPM',
  VC: 'VCT',
  WS: 'WSM',
  SM: 'SMR',
  ST: 'STP',
  SA: 'SAU',
  SN: 'SEN',
  RS: 'SRB',
  SC: 'SYC',
  SL: 'SLE',
  SG: 'SGP',
  SK: 'SVK',
  SI: 'SVN',
  SB: 'SLB',
  SO: 'SOM',
  ZA: 'ZAF',
  GS: 'SGS',
  SS: 'SSD',
  ES: 'ESP',
  LK: 'LKA',
  SD: 'SDN',
  SR: 'SUR',
  SJ: 'SJM',
  SZ: 'SWZ',
  SE: 'SWE',
  CH: 'CHE',
  SY: 'SYR',
  TW: 'TWN',
  TJ: 'TJK',
  TZ: 'TZA',
  TH: 'THA',
  TL: 'TLS',
  TG: 'TGO',
  TK: 'TKL',
  TO: 'TON',
  TT: 'TTO',
  TN: 'TUN',
  TR: 'TUR',
  TM: 'TKM',
  TC: 'TCA',
  TV: 'TUV',
  UG: 'UGA',
  UA: 'UKR',
  AE: 'ARE',
  GB: 'GBR',
  US: 'USA',
  UM: 'UMI',
  UY: 'URY',
  UZ: 'UZB',
  VU: 'VUT',
  VE: 'VEN',
  VN: 'VNM',
  VG: 'VGB',
  VI: 'VIR',
  WF: 'WLF',
  EH: 'ESH',
  YE: 'YEM',
  ZM: 'ZMB',
  ZW: 'ZWE',
};

export const Country = {
  getCountries: () => countries,
  getCountriesCodes: () => codes,
  getCountryCode: (name: string) => Object.entries(countries).find(country => country[1] === name)?.[0],
};
