import { ReadContact, Time } from '@abby/shared'
import { Contact } from '~/services/client/contact/entities/Contact.entity'

export class ContactMapper {
  public static toDomain (contact: ReadContact): Contact {
    return Contact.create({
      id: contact.id,
      fullname: `${contact.firstname} ${contact.lastname}`,
      emails: contact.emails,
      phone: contact.phone,
      billingAddress: contact.billingAddress,
      deliveryAddress: contact.deliveryAddress,
      bankInformations: contact.bankInformations,
      jobTitle: contact.jobTitle,
      notes: contact.notes,
      defaultContact: contact.defaultContact,
      createdAt: Time.toDate(contact.createdAt),
      archivedAt: contact.archivedAt ? Time.toDate(contact.archivedAt) : undefined,
      hasTiersPrestation: contact.hasTiersPrestation,
      language: contact.language,
      currency: contact.currency,
      paymentMethods: contact.paymentMethods,
      organization: contact.organization,
      hasBillings: contact.hasBillings,
      test: contact.test,
    })
  }
}
