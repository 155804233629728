import { useQuery } from '@tanstack/vue-query'
import { sdk } from '@abby/sdk'

export const useEditorRepository = () => {

  const getEditorToken = () => useQuery({
    queryKey: ['editorToken'],
    refetchIntervalInBackground: true,
    refetchInterval: 55 * 60 * 1000, // 55 minutes
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const { data } = await sdk.Editor.retrieveToken()
      return data
    },
  })

  return {
    getEditorToken,
  }
}
