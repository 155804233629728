import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { PersonaFeature, RegisteredType, SegmentEvent } from '@abby/core-legacy'
import dayjs from 'dayjs'

export interface CommunityEvent {
  title?: string,
  description?: string,
  date?: string,
  previewImage?: string,
  image?: string,
  url: string,
  type: RegisteredType[] | RegisteredType
  banner?: boolean,
  menu?: boolean,
  continuous?: boolean,
  bannerTitle?: string,
  newUser: boolean
}

export enum Loading {
  NUXT_CLIENT_LOADING= 'nuxtClientLoading',
  DEFAULT_LOADING= 'defaultLoading',
  LOGOUT_LOADING= 'logoutLoading',
  AUTH_LOADING= 'authLoading',
}

export interface AppState {
  loading: {
    nuxtClientLoading: boolean,
    defaultLoading: boolean,
    logoutLoading: boolean,
    authLoading: boolean,
  },
  isUpdateAvailable: boolean
  baseUrl: string
  apiHost: string
  strapiApiHost: string
  isContainerFullHeight: boolean
  parentPageHeaderHeight: number
  initTimestamp: number
  frillIsOpen: boolean,
  communityEventInBanner: CommunityEvent | null
  communityEventInModal: CommunityEvent | null,
  communityEventInNotification: CommunityEvent | null,
  mobileMenuOpened: boolean
}

export const state = (): AppState => ({
  loading: {
    nuxtClientLoading: false,
    defaultLoading: false,
    logoutLoading: false,
    authLoading: false,
  },
  baseUrl: 'http://localhost:3000',
  apiHost: 'http://localhost:3030',
  strapiApiHost: 'http://localhost:1337/api',
  isContainerFullHeight: false,
  parentPageHeaderHeight: 0,
  initTimestamp: 0,
  frillIsOpen: false,
  communityEventInBanner: null,
  communityEventInModal: null,
  communityEventInNotification: null,
  isUpdateAvailable: false,
  mobileMenuOpened: false,
})

export const getters: GetterTree<AppState, AppState> = {
  isUpdateAvailable: state => state.isUpdateAvailable,
  loading: state => state.loading,
  appLoading: state => (state.loading.nuxtClientLoading || state.loading.defaultLoading || state.loading.logoutLoading),
  mobileMenuOpened: state => state.mobileMenuOpened,
  initTimestamp: state => state.initTimestamp,
  baseUrl: state => state.baseUrl,
  apiHost: state => state.apiHost,
  strapiApiHost: state => state.strapiApiHost,
  isContainerFullHeight: state => state.isContainerFullHeight,
  frillIsOpen: state => state.frillIsOpen,
  communityEventInBanner: state => state.communityEventInBanner,
  communityEventInModal: state => state.communityEventInModal,
  communityEventInNotification: state => state.communityEventInNotification,
  communityEvents: (_, __, ___, rootGetters): CommunityEvent[] => {
    const query = {
      email: rootGetters['auth/user']?.email,
      phone_number: rootGetters['auth/user']?.phone,
      name: rootGetters['auth/user']?.fullName,
    }

    const serialize = (obj: any) => {
      const str = Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }

    const _query = serialize(query)

    let calendlyDemoGestionLink
    if (rootGetters['guide/persona']?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API)) {
      calendlyDemoGestionLink = `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs-sap?${_query ? _query + '&' : ''}`
    } else {
      calendlyDemoGestionLink = `https://calendly.com/abby-customer-success/abby-l-appli-n-1-pour-les-micro-entrepreneurs?${_query ? _query + '&' : ''}`
    }

    return [
      {
        url: `https://calendly.com/abby-customer-success/creez-votre-entreprise-avec-abby?${_query ? _query + '&' : ''}`,
        continuous: true,
        type: RegisteredType.CREATION,
        newUser: true,
        banner: true,
        bannerTitle: 'Créez votre entreprise avec Abby. Présentation en direct par un conseiller',
      }, {
        url: calendlyDemoGestionLink,
        continuous: true,
        type: RegisteredType.GESTION,
        newUser: true,
        banner: true,
        bannerTitle: 'Tout savoir sur Abby pour démarrer. Présentation en direct par un conseiller',
      },
      // {
      //   title: 'Trouver des clients plus facilement',
      //   description: 'Des techniques pour cibler sa clientèle, maitriser son pitch et passer à l\'action via une stratégie de prospection efficace.',
      //   date: '\'2024-05-27 19:00',
      //   previewImage: '/events/event-20.png',
      //   image: '/events/event-20.png',
      //   url: `https://calendly.com/corentin-de-abby/ateliers-trouver-des-clients?${_query ? _query + '&' : ''}`,
      //   type: RegisteredType.GESTION,
      //   newUser: false,
      //   menu: !dayjs().isAfter(dayjs('2024-05-27 19:00')),
      // },

    ].filter((e: CommunityEvent) => !e.date || dayjs(e.date).isSameOrAfter(dayjs().add(1, 'h')))
  },
}

export const mutations: MutationTree<AppState> = {
  SET_MOBILE_MENU_OPENED: (state, value: boolean) => {
    state.mobileMenuOpened = value
  },
  SET_NUXT_CLIENT_LOADING: (state, value: boolean) => {
    state.loading.nuxtClientLoading = value
  },
  SET_DEFAULT_LOADING: (state, value: boolean) => {
    state.loading.defaultLoading = value
  },
  SET_LOGOUT_LOADING: (state, value: boolean) => {
    state.loading.logoutLoading = value
  },
  SET_AUTH_LOADING: (state, value: boolean) => {
    state.loading.authLoading = value
  },
  SET_BASE_URL: (state, baseUrl: string) => {
    state.baseUrl = baseUrl
  },
  SET_API_HOST: (state, baseUrl: string) => {
    state.apiHost = baseUrl
  },
  SET_STRAPI_API_HOST: (state, strapiApiHost: string) => {
    state.strapiApiHost = strapiApiHost
  },
  SET_IS_CONTAINER_FULL_HEIGHT: (state, value: boolean) => {
    state.isContainerFullHeight = value
  },
  SET_PARENT_PAGE_HEADER_HEIGHT: (state, value: number) => {
    state.parentPageHeaderHeight = value
  },
  SET_INIT_TIMESTAMP: (state, value: number) => {
    state.initTimestamp = value
  },
  SET_FRILL_IS_OPEN (state, value: boolean) {
    state.frillIsOpen = value
  },
  SET_COMMUNITY_EVENT_IN_MODAL (state, value: CommunityEvent) {
    state.communityEventInModal = value
  },
  SET_COMMUNITY_EVENT_IN_NOTIFICATION (state, value: CommunityEvent) {
    state.communityEventInNotification = value
  },
  SET_COMMUNITY_EVENT_IN_BANNER (state, value: CommunityEvent) {
    state.communityEventInBanner = value
  },
  SET_UPDATE_IS_AVAILABLE (state, value: boolean) {
    state.isUpdateAvailable = value
  },
  RESET (_currentState: AppState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<AppState, AppState> = {
  setAppLoading: ({ commit }, params: { key: Loading, value: boolean}) => {
    if (params.key === Loading.NUXT_CLIENT_LOADING) {
      commit('SET_NUXT_CLIENT_LOADING', params.value)
    } else if (params.key === Loading.DEFAULT_LOADING) {
      commit('SET_DEFAULT_LOADING', params.value)
    } else if (params.key === Loading.LOGOUT_LOADING) {
      commit('SET_LOGOUT_LOADING', params.value)
    } else if (params.key === Loading.AUTH_LOADING) {
      commit('SET_AUTH_LOADING', params.value)
    }
  },
  setUpdateIsAvailable: ({ commit }, value: boolean) => {
    commit('SET_UPDATE_IS_AVAILABLE', value)
  },
  openFrill ({ commit }) {
    commit('SET_FRILL_IS_OPEN', true)
  },
  closeFrill ({ commit }) {
    commit('SET_FRILL_IS_OPEN', false)
  },
  openCommunityEventInModal ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_MODAL', value)
  },
  closeCommunityEventInModal ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_MODAL', null)
  },
  openCommunityEventInNotification ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_NOTIFICATION', value)
  },
  closeCommunityEventInNotification ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_NOTIFICATION', null)
  },
  openCommunityEventInBanner ({ commit }, value: CommunityEvent) {
    commit('SET_COMMUNITY_EVENT_IN_BANNER', value)
  },
  closeCommunityEventInBanner ({ commit }) {
    commit('SET_COMMUNITY_EVENT_IN_BANNER', null)
  },
  openMobileMenu ({ commit }) {
    commit('SET_MOBILE_MENU_OPENED', true)
  },
  closeMobileMenu ({ commit }) {
    commit('SET_MOBILE_MENU_OPENED', false)
  },
}
