
import { Component, ModelSync, Prop, Ref, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { GeoQuery } from '@abby/core-legacy'

export type AddressResponse = {
  label: string,
  score: number, // ex: 0.694770909090909,
  id: string, // ex: "55029_0890",
  name: string, // ex: "Rue du Port",
  postcode: string, // ex: "55000",
  citycode: string, // ex: "55029",
  x: number, // ex: 858198.71,
  y: number, // ex: 6855620.45,
  city: string, // ex: "Bar-le-Duc",
  context: string, // ex: "55, Meuse, Grand Est",
  type: 'municipality' | 'locality' | 'street' | 'housenumber',
  importance: number, // ex: 0.64248
}

type CustomAddress = { custom: boolean, label: string, city: string }

@Component({
  components: {
    ValidationProvider,
  },
})
export default class AddressInput extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: 'street' })
  type!: 'municipality' | 'locality' | 'street' | 'housenumber';

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ required: false, default: null })
  filters!: GeoQuery | null;

  @ModelSync('value', 'input')
  innerValue!: any;

  @Ref('autocomplete')
  autocomplete!: any;

  items: Array<CustomAddress & Partial<AddressResponse>> = [];
  loading = false;

  clear () {
    this.innerValue = null
  }

  addCustomCity (value: string) {
    const alreadyItem = this.items.find((el) => {
      return this.normalize(el.label) === this.normalize(value)
    })
    if (alreadyItem) {
      return
    }
    this.items.push({
      custom: true,
      label: value,
      city: value,
    })
  }

  async searchAddress (value: string | undefined) {
    if (!value) {
      return
    }

    if (value.length < 2) {
      this.items = this.items.filter(i => !i.custom)
      return
    }

    this.items = this.items.filter(i => !i.custom)
    if (this.filters?.postcode && this.items.length) {
      return this.addCustomCity(value)
    }
    await this.loadAddress(value)
    this.addCustomCity(value)
  }

  async loadAddress (value: string, prefill = false) {
    this.loading = true
    try {
      const results = await this.$api.address.search({
        ...this.$lodash.pickBy(this.filters),
        q: value,
        limit: 30,
      })
      this.items = results.features.map(({ properties }) => properties)
      if (prefill) {
        this.$emit('input', this.items[0])
      }
      return this.items
    } catch {
      this.items = []
      return []
    } finally {
      this.loading = false
    }
  }

  normalize (val: string) {
    return val.normalize('NFD').replace(/[\u0300-\u036F]/g, '').trim()
  }

  filterCity (_: string, queryText: string, itemText: string) {
    const hasValue = (val: string | null) => val !== null ? this.normalize(val) : ''
    const text = hasValue(itemText)
    const query = hasValue(queryText)

    return text.toString()
      .toLowerCase()
      .includes(query.toString().toLowerCase())
  }

  debounceSearch = this.$lodash.debounce(this.searchAddress, 300, { leading: true })

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }
}
