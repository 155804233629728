import { useQuery } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import axios from 'axios'
import {
  OpportunityOrderBy,
  OrderDirection,
} from '@abby/shared'
import { CategoryMapper } from '~/services/productivity/opportunity/mappers/Category.mapper'
import { OpportunityItemMapper } from '~/services/productivity/opportunity/mappers/OpportunityItem.mapper'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export type OpportunityFilterQuery = {
  search?: string | undefined,
  orderBy?: OpportunityOrderBy,
  orderDirection?: OrderDirection,
  customerId?: string,
}

export type OpportunityPaginateQuery = {
  page: number,
  limit: number,
} & OpportunityFilterQuery

export const useOpportunityRepository = () => {
  const { $backend, $api } = useContext()
  const alertManager = useAlertManager()

  const paginate = ({ page, limit, search, orderBy, orderDirection, customerId }: OpportunityPaginateQuery) => {
    const {
      data,
      isLoading, isFetching, isError, isInitialLoading,
    } = useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['opportunity', { page, limit, search, orderBy, orderDirection, customerId }],
      queryFn: async ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        const data = await $backend.opportunity.paginate({
          page,
          limit,
          customerId,
          search: search?.length ? search : undefined,
          orderBy: orderBy || OpportunityOrderBy.CREATED_AT,
          orderDirection: orderDirection || OrderDirection.DESC,
        })
        return data.docs.map(OpportunityItemMapper.toDomain)
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
    return {
      data,
      isLoading,
      isFetching,
      isError,
      isInitialLoading,
    }
  }

  const getOpportunityById = async (id: string) => {
    return await $api.opportunity.getOpportunity(id)
  }

  const retrieveFromCustomer = async (customerId: string) => {
    const { docs } = await $backend.opportunity.paginate({
      page: 1,
      limit: 100,
      orderBy: OpportunityOrderBy.CREATED_AT,
      orderDirection: OrderDirection.DESC,
      customerId,
    })
    return docs.map(OpportunityItemMapper.toDomain)
  }

  const retrieveCategories = async () => {
    const categories = await $backend.opportunity.getCategories()
    return categories.map(category => CategoryMapper.toDomain(category))
  }

  return {
    paginate,
    getOpportunityById,
    retrieveFromCustomer,
    retrieveCategories,
  }
}
