import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { CreateNote, INote } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface NoteState {
  notes: INote[];
}

export const state = (): NoteState => ({
  notes: [],
})

export const getters: GetterTree<NoteState, RootState> = {
  notes: (state: NoteState) => state.notes,
}

export const mutations: MutationTree<NoteState> = {
  SET_NOTES (state: NoteState, notes: INote[]) {
    state.notes = notes
  },
  PUSH_NEW_NOTE (state: NoteState, note: INote) {
    if (!state.notes) { state.notes = [] }
    state.notes.push(note)
  },
  REMOVE_NOTE (state: NoteState, id: string) {
    if (!state.notes) { return }
    const index = state.notes.findIndex(note => note.id === id)
    if (index !== -1 && index !== undefined) {
      state.notes.splice(index, 1)
    }
  },
  RESET (state: NoteState) {
    state.notes = []
  },
}

export const actions: ActionTree<NoteState, RootState> = {
  async fetchNotes ({ commit }, { companyId }: { companyId: string }) {
    try {
      const result = await this.$api.note.getAll(companyId)
      commit('SET_NOTES', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async create ({ commit, rootGetters }, params: CreateNote) {
    try {
      const newNote = await this.$api.note.create(params)
      newNote.author = rootGetters['auth/user']
      commit('PUSH_NEW_NOTE', newNote)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async delete ({ commit }, { companyId, id }: { companyId: string, id: string }) {
    try {
      await this.$api.note.delete({ companyId, id })
      commit('REMOVE_NOTE', id)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },

}
