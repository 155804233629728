export enum BillingSignatureErrors {
    FIRST_NAME_INVALID = 'FIRST_NAME_INVALID',
    LAST_NAME_INVALID = 'LAST_NAME_INVALID',
    EMAIL_INVALID = 'EMAIL_INVALID',
    PHONE_INVALID = 'PHONE_INVALID',
    PHONE_REQUIRED = 'PHONE_REQUIRED',
    EMAIL_REQUIRED = 'EMAIL_REQUIRED',
    FIRST_NAME_REQUIRED = 'FIRST_NAME_REQUIRED',
    LAST_NAME_REQUIRED = 'LAST_NAME_REQUIRED',
    ORGANIZATION_WITHOUT_CONTACT = 'ORGANIZATION_WITHOUT_CONTACT',
}
