export enum VatCode {
  // FRANCE
  FR_210 = 'FR_210',
  FR_550 = 'FR_550',
  FR_850 = 'FR_850',
  FR_1000 = 'FR_1000',
  FR_2000 = 'FR_2000',
  FR_00HT = 'FR_00HT',
  FR_00UE = 'FR_00UE',
  FR_0HUE = 'FR_0HUE',
}
