import { Middleware } from '@nuxt/types'
import { retrieveToken } from '~/middleware/auth'

const register: Middleware = async ({ redirect, route, store, $updateManager }) => {
  await $updateManager.checkForUpdate()
  const isAuthenticationInitialized = store.getters['auth/expirationTime'] !== null
  const { token } = retrieveToken()
  const isConnected = !!token

  const isPathOnboarding = route.path.includes('/start_') || route.path.includes('/start/creation')
  if (isConnected && !isAuthenticationInitialized && !isPathOnboarding) {
    redirect((route.query?.redirect_to as string) || '/')
  }

  if (isConnected && isPathOnboarding && !store.getters['auth/user']) {
    await store.dispatch('initializeUserRessources')
  }
}

export default register
