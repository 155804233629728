import { ActionTree, GetterTree } from 'vuex'
import { RootState } from '~/store/index'

export interface UpsellsState {
  promoCode: string,
}

export const state = (): UpsellsState => ({
  promoCode: '',
})

export const getters: GetterTree<UpsellsState, RootState> = {
  promoCode (state: UpsellsState) {
    return state.promoCode
  },
}

export const mutations = {
  SET_PROMO_CODE (state: UpsellsState, value: string) {
    state.promoCode = value
  },
  RESET (_currentState: UpsellsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  setPromoCode ({ commit }, promoCode: string) {
    commit('SET_PROMO_CODE', promoCode)
  },
}
