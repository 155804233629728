
import Vue from 'vue'
import { Action, Component, Getter, Watch } from 'nuxt-property-decorator'
import { Action as AbbyAction } from '@abby/shared'

import {
  AbbyAddons,
  AbbyPlans,
  AddonsConfig,
  CampaignType,
  PlansConfig, ReadCompanyStripeProduct,
  SegmentEvent,
  StripeProductFrequency,
  StripeProductType,
} from '@abby/core-legacy'
import { confetti } from 'dom-confetti'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import Link from '~/components-legacy/utils/Link.vue'
import ChoiceSelect from '~/components-legacy/inputs/ChoiceSelect.vue'
import NewPaymentCard from '~/components-legacy/cards/payment/NewPaymentCard.vue'
import { AbbyPlansStep, PaymentState } from '~/store/payment'
import { IAuthUser } from '~/store/auth'
import NewCallToAction from '~/components-legacy/cards/NewCallToAction.vue'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/interfaces/Promotion'
import MarketingProductPlanCard from '~/components/marketing/cards/MarketingProductPlanCard.vue'

@Component({
  components: {
    MarketingProductPlanCard,
    NewCallToAction,
    NewPaymentCard,
    ChoiceSelect,
    Link,
    ASDialog,
  },
  setup () {
    const { getActivePromotion } = usePromotionManager()
    return {
      getActivePromotion,
    }
  },
})
export default class PaymentModal extends Vue {
  close () {
    this.$store.dispatch('payment/closePaymentModal')
    this.displayCEUpsell = true
  }

  @Getter('payment/paymentModal')
  paymentModal!: PaymentState['paymentModal']

  @Getter('company/plans') plans!: ReadCompanyStripeProduct[];
  @Action('company/setPlans') setPlans!: (value: ReadCompanyStripeProduct[]) => Promise<void>;

  @Getter('auth/user')
  user!: IAuthUser

  getActivePromotion!: Function

  frequency: StripeProductFrequency.YEAR | StripeProductFrequency.MONTH = StripeProductFrequency.YEAR
  plan: StripeProductType = StripeProductType.ABBY_PRO
  option: StripeProductType | null = StripeProductType.ABBY_CE

  displayCEUpsell: boolean = false

  showThanks: boolean = false;

  interval: any

  ABVersion: number = 10;

  created () {
    // TODO: À adapter pour toujours afficher le CE sans la variable AB
    this.ABVersion = 10
    this.displayCEUpsell = this.canCEUpsellBeShowed
  }

  get CEPrice () {
    return AddonsConfig[AbbyAddons.ABBY_CE].pricing[this.$planManager.abGroupPricing()][this.frequency].amountByMonth
  }

  get displayPaymentModalWithoutCE () {
    return (!this.displayCEUpsell || !this.canCEUpsellBeShowed || !this.displayCEStep) && !this.showThanks
  }

  get isCheckoutGroupB () {
    return !this.$campaignsManager.isDisplayable(CampaignType.AB_GROUP_B_CHECKOUT)
  }

  get displayCEStep () {
    return this.displayCEUpsell && this.canCEUpsellBeShowed && !this.showThanks && !this.isCheckoutGroupB
  }

  @Watch('paymentModal.opened')
  handlePaymentModal (value: boolean) {
    if (value) {
      this.selectOption(this.paymentModal.option || null)
      this.selectPlan(this.paymentModal.product || ([StripeProductType.ABBY_START, StripeProductType.ABBY_PRO].includes(this.$planManager.whichPlanCompanyHas() as unknown as StripeProductType) ? this.$planManager.whichPlanCompanyHas() as unknown as StripeProductType : StripeProductType.ABBY_START))
      this.selectFrequency(this.paymentModal.frequency || StripeProductFrequency.YEAR)
      if (this.paymentModal.step === AbbyPlansStep.THANKS) {
        this.showThanks = true
        if (this.paymentModal.product) { this.addPlan(this.paymentModal.product, this.paymentModal.frequency || StripeProductFrequency.MONTH) }
      } else if (this.paymentModal.step === AbbyPlansStep.PAYMENT) {
        this.showThanks = false
        this.displayCEUpsell = false
      } else if (this.paymentModal.step === AbbyPlansStep.CE) {
        this.displayCEUpsell = true
      }

    }
  }

  addPlan (productId: StripeProductType, frequency: StripeProductFrequency) {
    const hasPlan = this.plans?.find(p => p.productId === productId)
    if (hasPlan) {
      this.setPlans(this.plans.map(p => ({
        ...p,
        ...(p.productId === productId
          ? {
            trialAt: null,
            deletedAt: null,
            subscriptionId: 'tmp_value',
            extendedTrialAt: null,
            frequency,
          }
          : {}),
      })))
      return
    }
    if (productId) {
      this.setPlans([
        {
          productId,
          companyId: '',
          id: '',
          trialAt: null,
          deletedAt: null,
          subscriptionId: 'tmp_value',
          extendedTrialAt: null,
          frequency,
        },
      ])
    }
  }

  scrollToPayment () {
    window.document.getElementById('payment-modal-card')?.scrollIntoView({ behavior: 'smooth' })
  }

  chooseCEUpsell (value: boolean) {
    this.selectOption(value ? StripeProductType.ABBY_CE : null)
    this.displayCEUpsell = false
    try {
      window.document.querySelector('.payment-modal')?.scrollTo({ top: 0, behavior: 'smooth' })
    } finally {
      const feature = this.$store.getters['payment/feature']
      this.$ap.track(AbbyAction.CHECKOUT_PAGE_VIEWED, { step: 'Checkout', feature, hasCEUpsell: value })
      this.$hotjar.sendHotjarEvent(SegmentEvent.CHOOSE_CE_WITH_PLAN as string)
    }
  }

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get canCEUpsellBeShowed () {
    return !!(this.optionItemToDisplay.length && !this.$planManager.has(StripeProductType.ABBY_CE)) && this.ABVersion > 5
  }

  get Frequency () {
    return StripeProductFrequency
  }

  get options () {
    if (this.option && this.optionItemToDisplay.length) {
      return [
        this.option,
      ]
    } else {
      return undefined
    }
  }

  get Plan () {
    return StripeProductType
  }

  selectOption (value: StripeProductType | null) {
    this.option = value
  }

  selectFrequency (value: StripeProductFrequency) {
    // @ts-ignore
    this.frequency = value
  }

  selectPlan (value: StripeProductType) {
    this.plan = value
    const monthFrequency = this.frequencyItemToDisplay.find(f => f.value === StripeProductFrequency.MONTH)
    if (this.frequency === StripeProductFrequency.MONTH && (monthFrequency?.disabled || monthFrequency?.success)) {
      this.selectFrequency(StripeProductFrequency.YEAR)
    }
  }

  closeShowThanks () {
    this.frequency = StripeProductFrequency.YEAR
    this.plan = StripeProductType.ABBY_PRO
    this.option = StripeProductType.ABBY_CE

    this.displayCEUpsell = this.canCEUpsellBeShowed
    this.showThanks = false
    this.$emit('succeed', null)
    clearInterval(this.interval)
    this.close()
  }

  succeed () {
    this.$campaignsManager.doNotDisplayAnymore(CampaignType.RENEW_OFFER_CAMPAIGN_END)
    this.explodeConfetti()
    this.showThanks = true
    this.$campaignsManager.doNotDisplayAnymore(CampaignType.EASTER_EGG_FINISHED)
    this.$hotjar.sendHotjarEvent(SegmentEvent.PAYMENT_SUCCEED as string)
  }

  get abbyStartPurchased () {
    return this.showThanks && this.plan === StripeProductType.ABBY_START
  }

  get abbyProPurchased () {
    return this.showThanks && this.plan === StripeProductType.ABBY_PRO
  }

  explodeConfetti () {
    let counter = 1000
    const intervalConfetti = () => {
      const modalElement = document.getElementById('payment-modal__thanks')
      clearInterval(this.interval)
      counter = Math.random() * 2500
      if (modalElement && document.hasFocus()) {
        confetti(modalElement, { elementCount: 30, angle: 65, startVelocity: 55 })
      }
      this.interval = setInterval(intervalConfetti, counter)
    }
    this.interval = setInterval(intervalConfetti, counter)
  }

  get frequencyItemToDisplay () {
    return [
      {
        title: 'Annuelle',
        value: StripeProductFrequency.YEAR,
        choiceAlignment: 'center',
        success: this.$planManager.whichStripeProductCompanyHasSubscribed() === this.plan && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
      },
      {
        title: 'Mensuelle',
        value: StripeProductFrequency.MONTH,
        choiceAlignment: 'center',
        success: this.$planManager.whichStripeProductCompanyHasSubscribed() === this.plan && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.MONTH,
        disabled: this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
      },
    ]
  }

  get planAmount () {
    return {
      [StripeProductType.ABBY_START]: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
      [StripeProductType.ABBY_PRO]: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      [StripeProductType.ABBY_BUSINESS]: {
        ...PlansConfig[AbbyPlans.ABBY_BUSINESS].pricing[this.$planManager.abGroupPricing()],
      },
      [StripeProductType.ABBY_CE]: {
        ...AddonsConfig[AbbyAddons.ABBY_CE].pricing[this.$planManager.abGroupPricing()],
      },
    }
  }

  get oneWeekLater () {
    return this.$dayjs().add(1, 'week').format('DD/MM/YYYY')
  }

  get upgradePromoCode () {
    if (!this.user?.firstname) { return '' }
    const sanitizeString = (str: string) => {
      let _str = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, '')
      _str = _str.normalize('NFD').replace('-', '')
      _str = _str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
      _str = _str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
      return _str.trim()
    }
    return `${sanitizeString(this.user.firstname).toUpperCase()}BIGBOSS`
  }

  async copyCode () {
    await this.$clipboard.copy(this.upgradePromoCode)
    this.$alertsManager.info('Code promo copié avec succès')
  }

  back () {
    if (this.isCheckoutGroupB) {
      this.$store.dispatch('payment/closePaymentModal')
      this.$store.dispatch('payment/closeAbbyPlansModal')
      this.displayCEUpsell = false
      return
    }
    if (!this.canCEUpsellBeShowed) {
      this.close()
    }
    this.displayCEUpsell = true
  }

  get taxText () {
    return 'mois ht'
  }

  get isPromotionDay () {
    const isSubBusinessYearly = this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    return !isSubBusinessYearly && this.activePromotion
  }

  get promotionRate () {
    if (this.isPromotionDay && this.activePromotion) {
      return this.frequency === StripeProductFrequency.YEAR ? this.activePromotion.ratioYear : this.activePromotion.ratioMonth
    }
    return 1
  }

  get plansOnPromotion () {
    return this.activePromotion && this.isPromotionDay ? this.activePromotion.plans : []
  }

  get planPrices () {
    const plan = this.plan as StripeProductType.ABBY_BUSINESS | StripeProductType.ABBY_PRO | StripeProductType.ABBY_START
    return {
      withoutPromotion: this.$amount.formatCents(this.planAmount[plan][this.frequency].amountByMonth).text,
      withPromotion: this.isPromotionDay && this.activePromotion
        ? this.$amount.formatCents(this.planAmount[plan][this.frequency].amountByMonth * this.promotionRate).text
        : this.$amount.formatCents(this.planAmount[plan][this.frequency].amountByMonth).text,
    }
  }

  get planItemToDisplay () {
    return [
      {
        title: 'Abby Business',
        ...(this.isPromotionDay && this.plansOnPromotion.includes(AbbyPlans.ABBY_BUSINESS))
          ? {
            subtitle: `<s>${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_BUSINESS][this.frequency].amountByMonth).decimals} €</s> ${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_BUSINESS][this.frequency].amountByMonth * this.promotionRate).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          }
          : {
            subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_BUSINESS][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          },
        imageSrc: '/plans/plan_business.png',
        value: StripeProductType.ABBY_BUSINESS,
        success: this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
        disabled: this.$planManager.has([StripeProductType.ABBY_CREATION_START_BUSINESS]),
      },
      {
        title: 'Abby Pro',
        ...(this.isPromotionDay && this.plansOnPromotion.includes(AbbyPlans.ABBY_PRO))
          ? {
            subtitle: `<s>${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth).decimals} €</s> ${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth * this.promotionRate).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          }
          : {
            subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          },
        imageSrc: '/plans/plan_pro.png',
        value: StripeProductType.ABBY_PRO,
        success: this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_PRO && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
        disabled: [AbbyPlans.ABBY_BUSINESS].includes(this.$planManager.whichPlanCompanyHasSubscribed()) || this.$planManager.has([StripeProductType.ABBY_CREATION_START_PREMIUM, StripeProductType.ABBY_CREATION_START_BUSINESS]),
      },
      {
        title: 'Abby Start',
        ...(this.isPromotionDay && this.plansOnPromotion.includes(AbbyPlans.ABBY_START))
          ? {
            subtitle: `<s>${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth).decimals} €</s> ${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth * this.promotionRate).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,

          }
          : {
            subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          },
        imageSrc: '/plans/plan_start.png',
        value: StripeProductType.ABBY_START,
        success: this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_START && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
        disabled: [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_BUSINESS].includes(this.$planManager.whichPlanCompanyHasSubscribed()) || this.$planManager.has([StripeProductType.ABBY_CREATION_START, StripeProductType.ABBY_CREATION_START_PREMIUM, StripeProductType.ABBY_CREATION_START_BUSINESS]),

      },
    ]
  }

  get optionItemToDisplay () {
    if (!this.$planManager.hasRelatedCESubscription() && this.$planManager.has(StripeProductType.ABBY_CE)) { return [] }
    return [
      {
        title: 'Le CE des indépendants',
        // subtitle: 'cc',
        subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_CE][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
        imageSrc: '/ce-landing/icon-1.png',
        value: StripeProductType.ABBY_CE,
        success: this.$planManager.has(StripeProductType.ABBY_CE),
      },
      ...!this.$planManager.has(StripeProductType.ABBY_CE)
        ? [{
          title: 'Sans option',
          choiceAlignment: 'center',
          value: null,
          titleNormal: true,
        }]
        : [],
    ]
  }
}
