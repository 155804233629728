
import Vue from 'vue'
import { Component, Getter, Action } from 'nuxt-property-decorator'

import { StripeProductType } from '@abby/core-legacy'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'

@Component({
  components: {
    Modal,
    ASDialog,
  },
})
export default class ExtendedTrialModal extends Vue {
  @Getter('company/isExtendedTrialModalOpened')
  isExtendedTrialModalOpened!: boolean

  @Action('payment/extendSubscriptionTrial') extendSubscriptionTrial!: (id: StripeProductType) => Promise<void>;
  close () {
    this.$store.dispatch('company/openIsExtendedModal', false)
  }

  async extendedTrial () {
    try {
      const productId = this.$planManager.whichPlanHasExpired()
      if (!productId) { this.$alertsManager.error('Impossible de prolonger votre période d\'essai'); return }
      await this.extendSubscriptionTrial(productId as unknown as StripeProductType)
      this.$alertsManager.success('Votre période d\'essai a bien été prolongée')
    } catch (e: any) {
      this.$alertsManager.error('Impossible de prolonger votre période d\'essai')
    } finally {
      this.close()
    }
  }
}
