import { ReadContact, ReadOrganization } from '@abby/shared'
import { OrganizationContactForm } from '~/services/client/organization/forms/OrganizationContact.form'

export class OrganizationContactFormMapper {
  public static toDomain (doc: ReadContact, organization: ReadOrganization): any {
    return OrganizationContactForm.create({
      id: doc.id,
      firstname: doc.firstname,
      lastname: doc.lastname,
      emails: doc.emails,
      jobTitle: doc.jobTitle,
      phone: doc.phone,
      defaultContact: doc.defaultContact,
      organization,
    })
  }
}
