import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'

export const useRemoveBillingAdmin = () => {
  const { i18n } = useContext()
  const confirmManager = useConfirmManager()
  const billingRepository = useBillingRepository()

  return async ({ id }: BillingItem) => {
    const confirm = await confirmManager.ask({
      title: i18n.t('confirmBilling.ADMIN_DELETE_DOCUMENT.title') as string,
      content: i18n.t('confirmBilling.ADMIN_DELETE_DOCUMENT.content') as string,
      confirmText: i18n.t('confirmBilling.ADMIN_DELETE_DOCUMENT.button') as string,
      confirmButton: { color: 'primary', depressed: true },
    })
    if (!confirm) {
      return
    }

    await billingRepository.removeBilling(id)
  }
}
