import { useContext } from '@nuxtjs/composition-api'
import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'

export const useCopyContactID = () => {
  const { $clipboard } = useContext()

  return async ({ id }: ContactItem) => {
    await $clipboard.copy(id)
  }
}
