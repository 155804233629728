import { PlanFeature } from '@abby/core-legacy'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { useHelpManager } from '~/composables/shared/manager/useHelpManager'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'

export const useActivateOnlineSignature = () => {
  const confirmManager = useConfirmManager()
  const planManager = usePlanManager()
  const helpManager = useHelpManager()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const billingStore = useBillingStore()

  return async (billing: BillingItem) => {
    const count = await billingRepository.fetchSignatureCount()
    const hasAccess = planManager.hasAccessToFeature(PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE)

    const confirmText = count ? 'Activer' : 'Acheter des crédits'
    const contentCountText = count ? `Il vous reste <b>${count} ${count > 1 ? 'crédits' : 'crédit'} de signature.</b>` : '<b>Attention</b> : vous n\'avez plus de crédits de signature.'
    const confirm = await confirmManager.ask({
      confirmText,
      title: 'Activer la signature en ligne',
      content: `Souhaitez-vous activer la signature en ligne pour le document ${billing.number} ?${hasAccess && count > -1 ? `<br><br>${contentCountText}` : ''}`,
      confirmButton: { color: 'primary', depressed: true },
    })
    if (!confirm) { return }

    if (!count) {
      return helpManager.openArticle('6762053#h_5f3293dc1d')
    }
    if (!billing.expiredAt || billing.isExpired) {
      return billingStore.openExpirationModal(billing)
    }
    await billingRepository.activateOnlineSignature(billing)
    alertManager.success('La signature en ligne a été activée avec succès sur votre document')
  }
}
