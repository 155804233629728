export interface ICompanyDocumentCategory {
  id: number;
  name: string;
  parent?: number;
}

export interface ICompanyDocumentCategoryConst {
  [key: string]: ICompanyDocumentCategory;
}

// catégories de documents
export const DocumentType: ICompanyDocumentCategoryConst = {
  ACCOUNTING: {
    id: 1,
    name: 'Document comptable',
  },
  LEGAL: {
    id: 2,
    name: 'Document juridique',
  },
};

// sous-catégories de documents
export const CompanyDocumentCategory: ICompanyDocumentCategoryConst = {
  ATTESTATIONS: {
    id: 10,
    name: 'Attestation',
    parent: 2,
  },
  MANDATS: {
    id: 20,
    name: 'Mandat',
    parent: 2,
  },
};
