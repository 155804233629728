import { render, staticRenderFns } from "./AbbyNewPricingPlan.vue?vue&type=template&id=228d5662&scoped=true"
import script from "./AbbyNewPricingPlan.vue?vue&type=script&lang=ts"
export * from "./AbbyNewPricingPlan.vue?vue&type=script&lang=ts"
import style0 from "./AbbyNewPricingPlan.vue?vue&type=style&index=0&id=228d5662&prod&scoped=true&lang=scss"
import style1 from "./AbbyNewPricingPlan.vue?vue&type=style&index=1&id=228d5662&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../common/temp/webapp-legacy/node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__pretti_ij34aobnxlkdmmy5vni36wgymq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228d5662",
  null
  
)

export default component.exports