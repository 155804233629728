import { BankInformations, BillingLocale, ClientAddress, CurrencyCode, ReadCustomerInformations } from '@abby/shared'
import { ContactAction } from '~/services/client/contact/enums/ContactAction.enum'

export type ContactProps = {
  id: string
  customerId?: string
  fullname: string
  emails?: string[]
  phone?: string
  billingAddress?: ClientAddress
  deliveryAddress?: ClientAddress
  bankInformations?: BankInformations
  jobTitle?: string
  notes?: string
  defaultContact?: boolean
  createdAt: Date
  archivedAt?: Date
  tiersPrestation?: ReadCustomerInformations
  hasTiersPrestation: boolean
  hasBillings: boolean
  language?: BillingLocale,
  currency?: CurrencyCode,
  paymentMethods?: string[],
  test: boolean;
  organization?: {
    id: string;
    name: string;
  }
}

export class Contact {
  readonly id: string
  public customerId?: string
  public tiersPrestation?: ReadCustomerInformations
  readonly fullname: string
  readonly emails?: string[]
  readonly phone?: string
  readonly billingAddress?: ClientAddress
  readonly deliveryAddress?: ClientAddress
  readonly bankInformations?: BankInformations
  readonly jobTitle?: string
  readonly defaultContact?: boolean
  readonly createdAt: Date
  readonly archivedAt?: Date
  readonly hasTiersPrestation: boolean
  readonly hasBillings: boolean
  readonly language?: BillingLocale
  readonly currency?: CurrencyCode
  readonly paymentMethods?: string[]
  readonly test: boolean
  notes?: string // pas readonly pour pouvoir refresh les notes

  readonly organization?: {
    id: string;
    name: string;
  }

  actions: ContactAction[] = [];

  private constructor (props: ContactProps) {
    this.id = props.id
    this.customerId = props.customerId
    this.fullname = props.fullname
    this.emails = props.emails
    this.phone = props.phone
    this.billingAddress = props.billingAddress
    this.deliveryAddress = props.deliveryAddress
    this.bankInformations = props.bankInformations
    this.jobTitle = props.jobTitle
    this.notes = props.notes
    this.defaultContact = props.defaultContact
    this.createdAt = props.createdAt
    this.archivedAt = props.archivedAt
    this.hasTiersPrestation = props.hasTiersPrestation
    this.hasBillings = props.hasBillings
    this.tiersPrestation = props.tiersPrestation
    this.language = props.language
    this.currency = props.currency
    this.paymentMethods = props.paymentMethods
    this.test = props.test
    this.organization = props.organization

    this.computeActions()
  }

  public static create (props: ContactProps): Contact {
    return new Contact(props)
  }

  computeActions () {
    this.actions.push(
      ContactAction.EDIT,
      ContactAction.ARCHIVE,
      ContactAction.UNARCHIVE,
      ContactAction.DELETE,
      ContactAction.VIEW_DETAILS,
      ContactAction.VIEW_ORGANIZATION_DETAILS,
      ContactAction.CREATE_INVOICE,
      ContactAction.CREATE_ESTIMATE,
      ContactAction.CREATE_ASSET,
      ContactAction.CREATE_PURCHASE_ORDER,
      ContactAction.ADMIN_COPY_CONTACT_ID,
      ContactAction.GO_TO_CLIENT_PORTAL,
      ContactAction.URSSAF_ACTIVATION_EMAIL_NOT_RECEIVED,
      ContactAction.UPDATED_EMAIL_URSSAF_ACCOUNT,
      ContactAction.DOWNLOAD_TAX_RETURN,
      ContactAction.DEFINE_AS_PRIMARY_CONTACT,
      ContactAction.EDIT_NOTES,
    )
  }
}
