import { Extension, Node as ProseMirrorNode } from '@tiptap/core'
// @ts-ignore
import { Plugin, PluginKey } from '@tiptap/pm/state'
// @ts-ignore
import { EditorView } from '@tiptap/pm/view'

interface ImageAttributes {
  src: string;
  alt?: string;
  title?: string;
  width?: number;
  height?: number;
}

export const ImageResizeMenu = Extension.create({
  name: 'imageResizeMenu',
  group: 'block',
  atom: true,

  addOptions () {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes () {
    return {
      src: {
        default: null,
      },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
      width: {
        default: null,
        parseHTML: (element: HTMLElement) => element.getAttribute('width'),
        renderHTML: (attributes: { width: string | number | null }) => {
          if (!attributes.width) {
            return {}
          }
          return {
            width: attributes.width,
          }
        },
      },
      height: {
        default: null,
      },
    }
  },

  parseHTML () {
    return [
      {
        tag: 'img[src]',
        getAttrs: (element: HTMLElement | string): ImageAttributes | {} => {
          if (typeof element === 'string') { return {} }
          const img = element as HTMLImageElement
          return {
            src: img.getAttribute('src'),
            alt: img.getAttribute('alt'),
            title: img.getAttribute('title'),
            width: img.width,
            height: img.height,
          }
        },
      },
    ]
  },

  renderHTML ({ HTMLAttributes }: { HTMLAttributes: Record<string, any> }) {
    return ['img', HTMLAttributes]
  },

  addProseMirrorPlugins () {
    let selectedImagePos: number | null = null
    let menuDiv: HTMLElement | null = null

    const hideMenu = () => {
      if (menuDiv) {
        menuDiv.style.display = 'none'
      }
      selectedImagePos = null
    }

    return [
      new Plugin({
        key: new PluginKey('imageResizeMenu'),
        props: {
          handleClick (view: EditorView, pos: number, event: MouseEvent) {
            // Check if the editor is editable
            if (!view.editable) {
              return false
            }

            const { schema, doc } = view.state
            let node = doc.nodeAt(pos)
            let _pos = pos
            if (!node?.type && doc.nodeAt(pos - 1)?.type.name === 'image') {
              node = doc.nodeAt(pos - 1)
              _pos = pos - 1
            }

            if (node && node?.type === schema.nodes.image) {
              hideMenu()

              selectedImagePos = _pos
              const domNode = view.nodeDOM(_pos) as HTMLElement | null
              if (!domNode) { return false }

              menuDiv = document.querySelector('div[data-image-resize-menu]')
              if (!menuDiv) { return false }

              const rect = domNode.getBoundingClientRect()
              menuDiv.style.left = `${rect.left}px`
              menuDiv.style.top = `${rect.bottom + window.scrollY + 5}px`
              menuDiv.style.display = 'block'

              menuDiv.innerHTML = `
                <div class="tw-p-1 tw-border tw-border-black-base tw-bg-white tw-rounded">
                  <button class="tw-mr-1 hover:tw-underline tw-text-primary-base" data-size="small">Petite</button>
                  <button class="tw-mx-1 hover:tw-underline tw-text-primary-base" data-size="medium">Moyenne</button>
                  <button class="tw-mx-1 hover:tw-underline tw-text-primary-base" data-size="large">Grande</button>
                  <button class="tw-mx-1 hover:tw-underline tw-text-primary-base" data-size="original">Taille d'origine</button>
                  <button class="tw-ml-1 hover:tw-underline tw-text-primary-base" data-action="delete">Supprimer</button>
                </div>
              `

              const resizeImage = (size: string, e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()

                if (selectedImagePos === null || node === null) { return }

                let width: number | null
                switch (size) {
                case 'small': width = 200; break
                case 'medium': width = 400; break
                case 'large': width = 600; break
                case 'original': width = null; break
                default: return
                }

                const { tr } = view.state
                const newAttrs = { ...node.attrs } as ImageAttributes

                if (width) {
                  newAttrs.width = width
                  newAttrs.height = undefined
                } else {
                  newAttrs.width = undefined
                  newAttrs.height = undefined
                }

                tr.setNodeMarkup(selectedImagePos, null, newAttrs)
                view.dispatch(tr)

                // Force re-render of the node
                const newState = view.state
                const newNode = newState.doc.nodeAt(selectedImagePos)
                if (newNode) {
                  const newDomNode = view.nodeDOM(selectedImagePos) as HTMLImageElement | null
                  if (newDomNode && newDomNode instanceof HTMLImageElement) {
                    if (width) {
                      newDomNode.width = width
                      newDomNode.removeAttribute('height')
                    } else {
                      newDomNode.removeAttribute('width')
                      newDomNode.removeAttribute('height')
                    }
                    newDomNode.style.width = ''
                    newDomNode.style.height = ''
                  }
                }

                // Update menu position after resize
                setTimeout(() => {
                  if (selectedImagePos !== null && menuDiv) {
                    const updatedDomNode = view.nodeDOM(selectedImagePos) as HTMLElement | null
                    if (updatedDomNode) {
                      const updatedRect = updatedDomNode.getBoundingClientRect()
                      menuDiv.style.left = `${updatedRect.left}px`
                      menuDiv.style.top = `${updatedRect.bottom + window.scrollY + 5}px`
                    }
                  }
                }, 0)
              }

              const deleteImage = (e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()

                if (selectedImagePos === null || node === null) { return }

                const { tr } = view.state
                tr.delete(selectedImagePos, selectedImagePos + node.nodeSize)
                view.dispatch(tr)
                hideMenu()
              }

              menuDiv.addEventListener('click', (e: Event) => {
                const target = e.target as HTMLElement
                if (target.tagName === 'BUTTON') {
                  if (target.dataset.action === 'delete') {
                    deleteImage(e as MouseEvent)
                  } else {
                    resizeImage(target.dataset.size || '', e as MouseEvent)
                  }
                }
              })

              const closeMenu = (e: MouseEvent) => {
                if (menuDiv && !menuDiv.contains(e.target as Node) && e.target !== domNode) {
                  hideMenu()
                  document.removeEventListener('click', closeMenu)
                }
              }
              setTimeout(() => {
                document.addEventListener('click', closeMenu)
              }, 0)

              event.stopPropagation()
              event.preventDefault()

              return true
            }
            return false
          },
        },
      }),
    ]
  },
})
