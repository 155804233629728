import getCustomIntegrations from '../sentryIntegrations.js'

import merge from '~lodash.mergewith'
import { init, vueRouterInstrumentation, BrowserTracing } from '~@sentry/vue'
import * as CoreSdk from '~@sentry/core'
import * as BrowserSdk from '~@sentry/browser-sdk'
import { ExtraErrorData, ReportingObserver, RewriteFrames } from '~@sentry/integrations'

export { init }
export const SentrySdk = { ...CoreSdk, ...BrowserSdk }

export async function getConfig (ctx) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  const config = {
    dsn:"https:\u002F\u002F45d33cab2ba86db334b364ea4739082a@o981517.ingest.sentry.io\u002F4505906169511936",
    environment:"production",
    tracePropagationTargets:["localhost:3000",new RegExp("https:\\\u002F\\\u002F((my|app|next)\\.)((abby.fr)|(app-abby.com))", "")],
    profilesSampleRate:0.01,
    replaysSessionSampleRate:0.01,
    replaysOnErrorSampleRate:0.01,
    tracesSampleRate:0.01,
    beforeSend:function(event, hint) {
          const error = hint.originalException;

          // Messages to filter out
          const filteredMessages = [
          'Navigation cancelled',
          'Request aborted',
          'Network Error',
          'Redirected when going from',
          'Unknown player. Probably unloaded'];

          // If the original error message has any unwanted text
          const isFilteredByOriginalException = filteredMessages.some(
            (msg) => error?.message?.includes(msg)
          );

          // OR if any captured exception values contain the unwanted text
          const isFilteredByEventException = filteredMessages.some((msg) =>
          event?.exception?.values?.some((e) => e.value?.includes(msg))
          );

          if (isFilteredByOriginalException || isFilteredByEventException) {
            return null; // Drop this event
          }

          // Otherwise, keep the event
          return event;
        },
    release:"3f0c0232e00df8bd6d00f8e35d95e5332f3dc37a",
  }

  config.integrations = [
    new ExtraErrorData(),
    new ReportingObserver({ types:["crash"] }),
    new RewriteFrames(),
  ]

  const { browserTracing, vueOptions, vueRouterInstrumentationOptions, ...tracingOptions } = {"tracesSampleRate":0.01,"browserTracing":{},"vueOptions":{"trackComponents":true,"tracing":true,"tracingOptions":{"hooks":["mount","update"],"timeout":2000,"trackComponents":true}},"vueRouterInstrumentationOptions":{"routeLabel":"name"},"browserOptions":{}}
  config.integrations.push(new BrowserTracing({
    ...(ctx.app.router ? { routingInstrumentation: vueRouterInstrumentation(ctx.app.router, vueRouterInstrumentationOptions) } : {}),
    ...browserTracing,
  }))
  merge(config, vueOptions, tracingOptions)

  const customIntegrations = await getCustomIntegrations(ctx)
  if (Array.isArray(customIntegrations)) {
    config.integrations.push(...customIntegrations)
  } else {
    console.error(`[@nuxtjs/sentry] Invalid value returned from customClientIntegrations plugin. Expected an array, got "${typeof customIntegrations}".`)
  }

  const runtimeConfigKey = "sentry"
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig)
  }

  return config
}
