import { PlanFeature } from '@abby/core-legacy'
import { useConfirmManagerStore } from '~/composables/_common/confirmManager.store'

export interface ConfirmParams {
  title?: string
  subtitle?: string
  content?: string
  confirmText?: string
  confirmButton?: object
  confirmButtonIcon?: string
  icon?: 'remove' | 'save'
  cancelText?: string
  cancelButton?: object
  confirmCallback?: () => void
  cancelCallback?: () => void
  noCancelButton?: boolean
  maxWidth?: number
  feature?: PlanFeature
}

const defaultConfirmConfig: ConfirmParams = {
  content: 'Êtes vous sur de vouloir continuer ?',
  confirmText: 'Confirmer',
  confirmButton: {
    disabled: false,
    depressed: true,
    color: 'error',
  },
  cancelText: 'Annuler',
  cancelButton: {
    text: true,
  },
  confirmCallback: () => {},
  cancelCallback: () => {},
}

export const useConfirmManager = () => {
  const confirmManagerStore = useConfirmManagerStore()

  const ask = async (params: ConfirmParams): Promise<boolean> => {
    return await new Promise(function (resolve) {
      confirmManagerStore.setConfirm({
        ...defaultConfirmConfig,
        ...params,
        confirmCallback: () => { params?.confirmCallback?.(); resolve(true) },
        cancelCallback: () => { params?.cancelCallback?.(); resolve(false) },
      })
    })
  }

  const clear = () => {
    confirmManagerStore.clearConfirm()
  }

  return { ask, clear }
}
