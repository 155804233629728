export enum BillingAction {
  MARK_AS_FINALIZED = 1,
  MARK_AS_PAID = 2,
  MARK_AS_UN_PAID = 3,
  UPDATE_DOCUMENT = 4,
  MARK_AS_REFUSED = 5,
  MARK_AS_SIGNED = 6,
  MARK_AS_NOT_REFUSED = 7,
  MARK_AS_NOT_SIGNED = 8,
  CREATE_AN_INVOICE = 9,
  CREATE_AN_ADVANCE = 10,
  DUPLICATE_AS_ESTIMATE = 11,
  DUPLICATE_AS_INVOICE = 12,
  DUPLICATE_AS_PURCHASE_ORDER = 21,
  DELETE_DOCUMENT = 13,
  DOWNLOAD = 14,
  SEND_BY_EMAIL = 15,
  CREATE_AN_ASSET = 16,
  MANAGE_FREQUENCY = 17,
  UPDATE_REMINDER = 18,
  COPY_STRIPE_PAYMENT_URL = 19,
  DO_NOT_SAVE = 20,
  ADMIN_DELETE_DOCUMENT = 22,
  OPEN = 23,
  ARCHIVE = 24,
  UNARCHIVE = 25,
  UNPAID_INVOICE = 26,
  COPY_SIGNATURE_URL = 27,
  UPDATE_TITLE = 28,
  ADMIN_COPY_DOCUMENT_ID = 29,
  ADMIN_COPY_ISSUE_PARTICULIER_INCONNU = 30,
  ADMIN_COPY_BILLING_CONFIGURATION_ID = 31,
  CHECK_URSSAF_TP_STATUT = 32,
  DOWNLOAD_SIGNATURE_PROOF = 33,
  CANCEL_BILLING = 34,
  MARK_AS_FINALIZED_AND_SEND = 35,
  DOWNLOAD_FRENCH_LOCALE = 36,
  ADMIN_REGENERATE_DOCUMENT = 37,
  ADMIN_UNLINK_FROM_CREATE_FROM = 38,
  ACTIVATE_ONLINE_SIGNATURE = 39,
  ACTIVATE_ONLINE_PAYMENT = 40,
}

export enum CustomerAction {
  UPDATE = 1,
  DELETE = 2,
  URSSAF_EMAIL_NOT_RECEIVED = 3,
  COPY_CUSTOMER_ID = 4,
  EMAIL_CHANGE = 5,
  DOWNLOAD_CERTIFICATE = 6,
  CREATE_INVOICE = 7,
  CREATE_ESTIMATE = 8,
  CREATE_ASSET = 9,
  CREATE_PURCHASE_ORDER = 10,
  GO_TO_CUSTOMER_PORTAL = 11,
}

export enum ProviderAction {
  UPDATE = 1,
  DELETE = 2,
}

export enum ProductAction {
  UPDATE = 1,
  DELETE = 2,
}

export enum IncomeAction {
  UPDATE = 1,
  DELETE = 2,
  COPY_INCOME_ID = 3,
  CHANGE_PRODUCT_TYPE_COMMERCIAL_OR_CRAFT_SERVICES = 4,
  CHANGE_PRODUCT_TYPE_SERVICE_DELIVERY = 5,
  CHANGE_PRODUCT_TYPE_SALE_OF_MANUFACTURED_GOODS = 6,
  CHANGE_PRODUCT_TYPE_SALE_OF_GOODS = 7,
  SHOW_ASSOCIATED_DOCUMENT = 8,
  ASSOCIATE_DOCUMENT_TO_ENTRY = 9,
  SHOW_ASSOCIATED_TRANSACTION = 10,
}
