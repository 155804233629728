import { useContext, useStore } from '@nuxtjs/composition-api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { ComputedRef, Ref } from 'vue'
import { ReadBillingConfiguration, BillingType, BillingState, BillingRangeType } from '@abby/shared'
import axios from 'axios'
import {
  BillingActivityEvent,
  BillingLocale,
  BillingState as BillingLegacyState,
  QueryDownloadBilling,
  IApiError,
  BillingType as LegacyBillingType,
} from '@abby/core-legacy'
import { saveAs } from 'file-saver'

import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { BillingConfiguration } from '~/services/billing/_common/entities/BillingConfiguration.entity'
import { BillingReminderActivity } from '~/services/billing/_common/valueObjects/BillingReminderActivity.valueObject'
import { BillingPaymentRequest } from '~/services/billing/_common/entities/BillingPaymentRequest.entity'
import { BillingCustomer } from '~/services/billing/_common/entities/BillingCustomer.entity'
import { BillingCustomerMapper } from '~/services/billing/_common/mappers/BillingCustomer.mapper'
import { BillingItemMapper } from '~/services/billing/_common/mappers/BillingItem.mapper'
import { useHelpManager } from '~/composables/shared/manager/useHelpManager'

export type BillingFilterQuery = {
  type: ComputedRef<BillingType[]> | Ref<BillingType[]>,
  search: Ref<string | undefined>,
  state: Ref<BillingState[] | undefined>,
  range: Ref<[string, string] | undefined>,
  rangeType: Ref<BillingRangeType | undefined>,
  archived: Ref<boolean>,
  test: Ref<boolean>,
  late: Ref<boolean | undefined>,
  onlyReminderActive?: Ref<boolean>,
  onlyOnlineSignature?: Ref<boolean>,
  onlyOnlinePayment?: Ref<boolean>,
}

export type BillingPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
} & BillingFilterQuery

export const useBillingRepository = () => {
  const { $backend, $dayjs, $api, $file, $config, $alertsManager } = useContext()
  const store = useStore()
  const queryClient = useQueryClient()
  const helpManager = useHelpManager()
  const isConnectedFromDashboard = store.getters['auth/connectedFromDashboard']

  const paginate = ({
    page,
    type,
    limit,
    state,
    search,
    rangeType,
    range,
    late,
    archived,
    test,
    onlyReminderActive,
    onlyOnlineSignature,
    onlyOnlinePayment,
  }: BillingPaginateQuery) => {
    const configuration = fetchConfiguration()
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['billings', { page, type, search, limit, state, range, archived, test, late, rangeType, onlyReminderActive, onlyOnlineSignature, onlyOnlinePayment }],
      queryFn: async ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        const data = await $backend.billing.paginate({
          page: page.value,
          type: type.value,
          state: state.value,
          search: search.value?.length ? search.value : undefined,
          limit: limit.value,
          rangeType: rangeType.value,
          archived: archived.value,
          test: isConnectedFromDashboard ? test.value : configuration.value.test,
          range: range.value,
          late: late.value,
          onlyReminderActive: onlyReminderActive?.value,
          onlyOnlineSignature: onlyOnlineSignature?.value,
          onlyOnlinePayment: onlyOnlinePayment?.value,
          cancelToken: source.token,
        })
        const adminActionsActivated = isConnectedFromDashboard || $config.nodeEnv === 'development'
        return {
          ...data,
          docs: data.docs.map(doc => BillingItemMapper.toDomain(doc, { adminActionsActivated })),
        }
      },
      onError: (error: IApiError) => {
        $alertsManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const fetchStatistics = ({
    type,
    search,
    range,
    rangeType,
    archived,
    test,
  }: Omit<BillingFilterQuery, 'late' | 'state'>) => {
    const configuration = fetchConfiguration()
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['billingStatistics', { type, search, range, rangeType, archived, test }],
      queryFn: ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        return $backend.billing.getStatistics({
          type: type.value,
          search: search.value?.length ? search.value : undefined,
          range: range.value,
          rangeType: rangeType.value,
          archived: archived.value,
          test: isConnectedFromDashboard ? test.value : configuration.value.test,
          cancelToken: source.token,
        })
      },
    })
  }

  const fetchConfiguration = () => {
    const { data } = useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['billingConfiguration'],
      queryFn: async () => {
        return await $backend.billing.getConfiguration()
      },
      select: (data: ReadBillingConfiguration) => {
        return BillingConfiguration.create({
          id: data.id,
          test: !!data.test,
          tiersPrestationCredentials: data.tiersPrestationCredentials,
          tiersPrestationActivated: data.tiersPrestationActivated,
        })
      },
    })
    return data as Ref<BillingConfiguration>
  }

  const { mutateAsync: archiveBilling } = useMutation({
    mutationFn: (id: string) => $backend.billing.archive(id),
    onSuccess: async () => await queryClient.invalidateQueries({
      predicate: query => ['billings', 'billingStatistics'].includes(query.queryKey[0] as string),
    }),
  })

  const { mutateAsync: unarchiveBilling } = useMutation({
    mutationFn: (id: string) => $backend.billing.unarchive(id),
    onSuccess: async () => await queryClient.invalidateQueries({
      predicate: query => ['billings', 'billingStatistics'].includes(query.queryKey[0] as string),
    }),
  })

  const retrieveFile = async (id: string): Promise<{ url: string, relativeUrl: string, id: string }> => {
    const document = await $api.billing.get(id)
    const fileByState: { [K in BillingLegacyState]?: { id?: string, url?: string, relativeUrl?: string } } = {
      [BillingLegacyState.DRAFT]: document.draftFile,
      [BillingLegacyState.FINALIZED]: document.finalizedFile,
      [BillingLegacyState.SIGNED]: document.signedFile,
      [BillingLegacyState.PAID]: document.paidFile,
      [BillingLegacyState.REFUSED]: document.refusedFile,
    }
    const file = fileByState[document.billingState]
    return {
      url: file?.url || '',
      relativeUrl: file?.relativeUrl || '',
      id: file?.id || '',
    }
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries({
      predicate: query => ['billings', 'billingStatistics'].includes(query.queryKey[0] as string),
    })
  }

  const markAsUnpaid = async (id: string) => {
    await $api.billing.markAsUnPaid(id)
    await refreshPaginate()
  }

  const markAsRefused = async (id: string) => {
    await $api.billing.markAsRefused(id)
    await refreshPaginate()
  }

  const markAsNotRefused = async (id: string) => {
    await $api.billing.markAsNotRefused(id)
    await refreshPaginate()
  }

  const markAsFinalized = async (id: string) => {
    const billing = await $api.billing.markAsFinalized(id)

    const legacyTypeToType = {
      [LegacyBillingType.INVOICE]: 'invoice',
      [LegacyBillingType.ESTIMATE]: 'estimate',
      [LegacyBillingType.ASSET]: 'asset',
      [LegacyBillingType.ADVANCE]: 'advance',
      [LegacyBillingType.PURCHASE_ORDER]: 'purchaseOrder',
    }

    helpManager.sendChatEvent('BillingFinalized', {
      type: legacyTypeToType[billing.billingType],
      ...[LegacyBillingType.INVOICE, LegacyBillingType.ADVANCE].includes(billing.billingType) ? { onlinePaymentActivated: billing.useStripePayment } : {},
      ...[LegacyBillingType.ESTIMATE, LegacyBillingType.PURCHASE_ORDER].includes(billing.billingType) ? { onlineSignatureActivated: billing.useSignature } : {},
    })
    await refreshPaginate()
  }

  const removeBilling = async (id: string) => {
    await $api.billing.deleteBillingDocument(id)
    await refreshPaginate()
  }

  const downloadSignatureProof = async ({ id, number }: BillingItem) => {
    const result = await $api.signature.downloadProof(id)
    const isPdf = result.contentType === 'application/pdf'

    saveAs(new Blob([result.file], { type: isPdf ? 'application/pdf' : 'application/zip' }), `Attestation de signature ${number}.${isPdf ? 'pdf' : 'zip'}`)
  }

  const fetchSignatureCount = async (): Promise<number> => {
    const result = await $api.signature.count()
    return result.remaining
  }

  const activateOnlineSignature = async (billing: BillingItem) => {
    await $api.billing.activateOnlineSignature(billing.id, billing.expiredAt!)
    await refreshPaginate()
  }

  const markAsSigned = async ({ id }: BillingItem) => {
    await $api.billing.markAsSigned(id)
    await refreshPaginate()
  }

  const markAsNotSigned = async ({ id }: BillingItem) => {
    await $api.billing.markAsNotSigned(id)
    await refreshPaginate()
  }

  const unlinkBilling = async ({ id }: BillingItem) => {
    await $api.billing.unlinkFromCreateFrom(id)
    await refreshPaginate()
  }

  const regenerateBilling = async ({ id }: BillingItem) => {
    await $api.billing.regenerateDocument(id)
    await refreshPaginate()
  }

  const fixCustomerAddress = async ({ id }: BillingItem) => {
    await $api.billing.fixCustomerAddress(id)
    await $api.billing.regenerateDocument(id)
    await refreshPaginate()
  }

  const activateOnlinePayment = async ({ id }: BillingItem) => {
    await $api.billing.activateStripePayment(id)
    await refreshPaginate()
  }

  const refreshBillingUrssafSyncStatus = async ({ id }: BillingItem) => {
    await $api.billing.checkUrssafTpStatut(id)
    await refreshPaginate()
  }

  const downloadBilling = async (billing: BillingItem, options?: QueryDownloadBilling) => {
    const { id, url } = await retrieveFile(billing.id)
    // si le document a déjà été généré et que l'on ne force pas des options on le télécharge
    if (id && !options) {
      $file.saveAs(id, () => (billing.number || 'Brouillon') + '.pdf')
      await $api.billing.addDownloadedActivity(billing.id, {
        name: billing.number,
        url,
      })
      await refreshPaginate()
      return
    }
    // on génère à la volée le document demandé par le client
    const document = await $api.billing.generateFileToDownload(billing.id, options || { locale: billing.locale || BillingLocale.FR })
    const fileByState = {
      [BillingLegacyState.DRAFT]: document.draftFile,
      [BillingLegacyState.FINALIZED]: document.finalizedFile,
      [BillingLegacyState.SIGNED]: document.signedFile,
      [BillingLegacyState.PAID]: document.paidFile,
      [BillingLegacyState.REFUSED]: document.refusedFile,
    }
    const generatedFile = fileByState[document.billingState]
    $file.saveAs(generatedFile?.id || '', () => (billing.number || 'Brouillon') + '.pdf')
    await $api.billing.addDownloadedActivity(billing.id, {
      name: billing.number,
      url: generatedFile?.url || '',
    })
    await refreshPaginate()
  }

  const retrieveReminderHistory = async (billing: BillingItem) => {
    const result = await $api.billing.get(billing.id)
    const remindersActivity = result.activity?.filter((activity: any) => activity.action === BillingActivityEvent.REMINDER_SENT) || []
    queryClient.setQueriesData({ queryKey: ['billings'], exact: false }, ({ docs, ...data }) => {
      const index = docs.findIndex((doc: BillingItem) => doc.id === billing.id)
      if (index === -1) {
        return { ...data, docs }
      }
      docs[index].setReminders(
        remindersActivity
          .map(({ data, date }: any) => BillingReminderActivity.create({
            date: $dayjs(date).toDate(),
            from: data.from.email,
            to: data.to.map((to: any) => to.email),
            cc: data.cc.email,
            isTest: data.isTest,
          })))
      return {
        ...data,
        docs,
      }
    })
  }

  const retrySendBillingToUrssaf = async ({
    billing,
    paymentRequest,
  }: {
      billing: BillingItem, paymentRequest?: BillingPaymentRequest
    }): Promise<BillingPaymentRequest> => {
    const result = await $api.billing.retrySendBillingUrssaf(billing.id, paymentRequest as any)
    return BillingPaymentRequest.create({
      mntVirement: result.paymentRequest?.mntVirement,
      advanceAlreadyPayed: result.paymentRequest?.advanceAlreadyPayed || 0,
      state: billing.state,
      statut: result.paymentRequest?.statut as any,
      customerRegisteredAt: $dayjs(result.customer.tiersPrestation!.registeredAt).toDate(),
      dateVirement: result.paymentRequest?.dateVirement ? $dayjs(result.paymentRequest.dateVirement).toDate() : undefined,
      dateDebutEmploi: $dayjs(result.paymentRequest?.dateDebutEmploi).toDate(),
      dateFinEmploi: $dayjs(result.paymentRequest?.dateFinEmploi).toDate(),
      idDemandePaiement: result.paymentRequest?.idDemandePaiement,
    })
  }

  const fetchBillingUrssafSyncStatus = async (billing: BillingItem) => {
    const result = await $api.urssafTp.checkUrssafTpBillingStatut(billing.id)
    if (result?.type) {
      throw new Error('Nous n\'avons pu récupérer le statut de votre facture, vous pouvez retenter demain. Si le problème persiste, contactez le support')
    }

    const idDemandePaiement = result.infoDemandePaiements[0].idDemandePaiement
    const code = result.infoDemandePaiements[0].statut.code
    const dateVirement = new Date(result.infoDemandePaiements[0].infoVirement.dateVirement)

    queryClient.setQueriesData({ queryKey: ['billings'], exact: false }, ({ docs, ...data }) => {
      const index = docs.findIndex((doc: BillingItem) => doc.id === billing.id)
      if (index === -1) {
        return docs
      }
      docs[index].paymentRequest.idDemandePaiement = idDemandePaiement
      docs[index].paymentRequest.statut = code
      docs[index].paymentRequest.dateVirement = dateVirement
      return {
        ...data,
        docs,
      }
    })
    return result
  }

  const updateNovaNumber = async (novaNumber: string) => {
    await $api.billing.updateNovaNumber(novaNumber)
    await queryClient.invalidateQueries({
      queryKey: ['billingConfiguration'],
    })
    await store.dispatch('billingConfiguration/fetchBillingConfiguration')
  }

  const { mutateAsync: disableTestMode } = useMutation({
    mutationFn: async () => {
      const newBillingConfiguration = {
        initialNumber: {
          invoice: 1,
          estimate: 1,
          asset: 1,
          purchaseOrder: 1,
        },
      }
      await $backend.company.desactivateTestMode()
      await $api.billing.updateConfiguration(newBillingConfiguration)
      await store.dispatch('billingConfiguration/fetchBillingConfiguration')
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['billingConfiguration'] })
      await refreshPaginate()
    },
  })

  const fetchCustomerFromContact = async (contactId: string): Promise<BillingCustomer> => {
    const result = await $backend.billing.getCustomerFromContact(contactId)
    return BillingCustomerMapper.toDomain(result)
  }

  const fetchCustomerFromOrganization = async (organizationId: string): Promise<BillingCustomer> => {
    const result = await $backend.billing.getCustomerFromOrganization(organizationId)
    return BillingCustomerMapper.toDomain(result)
  }

  const regenerateNumber = async (billing: BillingItem): Promise<void> => {
    await $backend.billing.regenerateNumber(billing.id)
  }

  return {
    paginate,
    fetchConfiguration,
    fetchStatistics,
    archiveBilling: async (id: string) => {
      await archiveBilling(id)
    },
    unarchiveBilling: async (id: string) => {
      await unarchiveBilling(id)
    },
    retrieveFile,
    refreshPaginate,
    markAsUnpaid,
    markAsRefused,
    markAsNotRefused,
    markAsFinalized,
    removeBilling,
    downloadSignatureProof,
    fetchSignatureCount,
    activateOnlineSignature,
    markAsSigned,
    markAsNotSigned,
    unlinkBilling,
    regenerateBilling,
    fixCustomerAddress,
    activateOnlinePayment,
    refreshBillingUrssafSyncStatus,
    downloadBilling,
    retrieveReminderHistory,
    retrySendBillingToUrssaf,
    fetchBillingUrssafSyncStatus,
    updateNovaNumber,
    disableTestMode,
    fetchCustomerFromContact,
    fetchCustomerFromOrganization,
    regenerateNumber,
  }
}
