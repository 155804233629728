import { Context } from '@nuxt/types'

interface TokenInfo {
  token: string | null;
  refreshToken: string | null;
  expirationTime: number | null;
}

export class TokenManager {
  private static instance: TokenManager;
  private currentToken: string | null = null;
  private tokenPromise: Promise<string> | null = null;
  private refreshTimeout: any | null = null;

  private constructor () {
    // Initialize token from localStorage on instantiation
    const { token } = retrieveToken()
    this.currentToken = token
  }

  static getInstance (): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager()
    }
    return TokenManager.instance
  }

  getToken (): string | null {
    // If token is not set, try to get it from localStorage
    if (!this.currentToken) {
      const { token } = retrieveToken()
      this.currentToken = token
    }
    return this.currentToken
  }

  setToken (token: string | null): void {
    this.currentToken = token
  }

  getTokenPromise (): Promise<string> | null {
    return this.tokenPromise
  }

  setTokenPromise (promise: Promise<string> | null): void {
    this.tokenPromise = promise
  }

  clearRefreshTimeout (): void {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout)
      this.refreshTimeout = null
    }
  }

  setRefreshTimeout (callback: () => void, delay: number): void {
    this.clearRefreshTimeout()
    this.refreshTimeout = setTimeout(callback, delay)
  }
}

export const retrieveToken = (): TokenInfo => {
  const tokenKey = Object.entries(localStorage).find(([key]) =>
    key.includes('firebase:authUser:'),
  )

  if (!tokenKey) {
    return {
      token: null,
      refreshToken: null,
      expirationTime: null,
    }
  }

  const { stsTokenManager } = JSON.parse(localStorage[tokenKey[0]])
  return {
    token: stsTokenManager.accessToken,
    refreshToken: stsTokenManager.refreshToken,
    expirationTime: stsTokenManager.expirationTime,
  }
}

export const refreshToken = async (
  $fire: any,
  hasFirebaseUser: boolean,
  store: any,
): Promise<string> => {
  const tokenManager = TokenManager.getInstance()

  try {
    if (hasFirebaseUser) {
      const token = await $fire.auth.currentUser.getIdToken(true)
      const expirationTime = Date.now() + 3600 * 1000

      await store.dispatch('auth/setExpirationTime', expirationTime, { root: true })
      return token
    }

    const { refreshToken, expirationTime } = retrieveToken()
    const apiKey = $fire.auth.config.apiKey

    if (!refreshToken) {
      throw new Error('No refresh token available')
    }

    await store.dispatch('auth/setExpirationTime', expirationTime, { root: true })

    const response = await fetch('https://securetoken.googleapis.com/v1/token', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        key: apiKey,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    })

    if (!response.ok) {
      throw new Error('Token refresh failed')
    }

    const data = await response.json()
    return data.id_token
  } finally {
    tokenManager.setTokenPromise(null)
  }
}

// Shared authentication configuration
export const configureAuth = (context: Context) => {
  const tokenManager = TokenManager.getInstance()

  const logout = async () => {
    tokenManager.setToken(null)
    tokenManager.setTokenPromise(null)
    tokenManager.clearRefreshTimeout()
    await context.store.dispatch('auth/logout', null, { root: true })
  }

  const handleTokenRefresh = async () => {
    const expirationTime = context.store.getters['auth/expirationTime'] || 0
    const storedToken = retrieveToken()
    const hasUser = !!context.$fire.auth.currentUser || !!storedToken.refreshToken
    const hasFirebaseUser = !!context.$fire.auth.currentUser

    // If we don't have a token in memory but have one in localStorage, set it
    if (!tokenManager.getToken() && storedToken.token) {
      tokenManager.setToken(storedToken.token)
    }

    if (hasUser && expirationTime < Date.now()) {
      try {
        if (!tokenManager.getTokenPromise()) {
          const promise = refreshToken(context.$fire, hasFirebaseUser, context.store)
          tokenManager.setTokenPromise(promise)
          tokenManager.setRefreshTimeout(() => tokenManager.setTokenPromise(null), 10000)

          const token = await promise
          tokenManager.setToken(token)
          return token
        }
        return await tokenManager.getTokenPromise()
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Token refresh failed:', error)
        await logout()
        throw error
      }
    }

    return tokenManager.getToken()
  }

  return {
    logout,
    handleTokenRefresh,
    tokenManager,
  }
}
