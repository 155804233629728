import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { Alert } from '~/plugins/alertsManager'

export const useAlertManagerStore = () => {
  const useAlertManagerStore = defineStore('alertManager', () => {
    const alerts = ref<Alert[]>([])

    const getAlerts = computed((): Alert[] => {
      return alerts.value.filter(a => a.component === 'alert')
    })

    const getSnack = computed((): Alert | undefined => {
      return alerts.value.find(a => a.component === 'snack')
    })

    const addAlert = (alert: Alert) => {
      alerts.value = [...[alert]]
    }

    const setAlerts = (newAlerts: Alert[]) => {
      alerts.value = newAlerts
    }

    const removeAlert = (alert: Alert) => {
      alerts.value = alerts.value.filter(a => a !== alert)
    }

    const reset = () => {
      alerts.value = []
    }

    return {
      alerts,
      getAlerts,
      getSnack,
      addAlert,
      setAlerts,
      removeAlert,
      reset,
    }
  })

  const alertManagerStore = useAlertManagerStore()
  const { addAlert, setAlerts, removeAlert, reset } = alertManagerStore
  const { alerts, getAlerts, getSnack } = storeToRefs(alertManagerStore)

  return {
    alerts,
    getAlerts,
    getSnack,
    addAlert,
    setAlerts,
    removeAlert,
    reset,
  }
}
