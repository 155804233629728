import { AbbyPlans, CampaignType } from '@abby/core-legacy'
import { Promotion } from '~/services/marketing/interfaces/Promotion'

export const usePromotionManager = () => {
  const promotions = [
    {
      title: 'Soldes de janvier',
      promoCode: 'JANVIER35',
      promoText: '-35%',
      labelYear: '-35% sur l\'année',
      labelMonth: '-35% sur 3 mois',
      labelYearMobile: '-35% annuel',
      labelMonthMobile: '-35% sur 3 mois',
      ratioYear: 0.65,
      ratioMonth: 0.65,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-01-06'),
      endDate: new Date('2025-01-19T23:59:50'),

      bannerApp: {
        title: 'Soldes de janvier sur Abby',
        subtitle: '<b>-35% sur tous les abonnements</b>',
        mobileTitle: '<b>-35% sur tous les abonnements</b>',
        miniTitle: '<b>-35% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez des soldes de janvier sur Abby',
        subtitle: 'Tous nos abonnements à partir de -35%',
        promotionText: '-35 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Soldes de janvier -35%',
    },
    {
      title: 'Soldes de janvier',
      promoCode: 'JANVIER40',
      promoText: '-40%',
      labelYear: '-40% sur l\'année',
      labelMonth: '-40% sur 3 mois',
      labelYearMobile: '-40% annuel',
      labelMonthMobile: '-40% sur 3 mois',
      ratioYear: 0.60,
      ratioMonth: 0.60,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-01-20'),
      endDate: new Date('2025-02-03T23:59:50'),

      bannerApp: {
        title: 'Soldes de janvier sur Abby',
        subtitle: '<b>-40% sur tous les abonnements</b>',
        mobileTitle: '<b>-40% sur tous les abonnements</b>',
        miniTitle: '<b>-40% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez des soldes de janvier sur Abby',
        subtitle: 'Tous nos abonnements à partir de -40%',
        promotionText: '-40 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Soldes de janvier -40%',
    },
    {
      title: 'Promotions de 30%',
      promoCode: 'SOLDES30',
      promoText: '-30%',
      labelYear: '-30% sur l\'année',
      labelMonth: '-30% sur 3 mois',
      labelYearMobile: '-30% annuel',
      labelMonthMobile: '-30% sur 3 mois',
      ratioYear: 0.70,
      ratioMonth: 0.70,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-02-04'),
      endDate: new Date('2025-02-10T23:59:50'),

      bannerApp: {
        title: 'Promotions de 30% sur Abby',
        subtitle: '<b>-30% sur tous les abonnements</b>',
        mobileTitle: '<b>-30% sur tous les abonnements</b>',
        miniTitle: '<b>-30% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Profitez des promotions sur Abby',
        subtitle: 'Tous nos abonnements à partir de -30%',
        promotionText: '-30 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promotions -30%',
    },
  ] as Promotion[]

  const seasonalPromotions = [
    {
      title: 'Promotions de Printemps',
      campaignType: CampaignType.SEASONAL_PROMO_BANNER_PRIMPTEMPS,
      promoCode: 'SPRINGSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-03-20'),
      endDate: new Date('2025-06-20T23:59:50'),

      bannerApp: {
        title: 'Promotions de Printemps',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre de Printemps sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo de Printemps -20%',
    },
    {
      title: 'Promotions d\'Été',
      campaignType: CampaignType.SEASONAL_PROMO_BANNER_ETE,
      promoCode: 'SUMMERSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2025-06-21'),
      endDate: new Date('2025-09-21T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Été',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Été sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Été -20%',
    },
    {
      title: 'Promotions d\'Automne',
      campaignType: CampaignType.SEASONAL_PROMO_BANNER_AUTOMNE,
      promoCode: 'AUTUMNSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-09-22'),
      endDate: new Date('2024-12-20T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Automne',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Automne sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Automne -20%',
    },
    {
      title: 'Promotions d\'Hiver',
      campaignType: CampaignType.SEASONAL_PROMO_BANNER_HIVER,
      promoCode: 'WINTERSALES',
      promoText: '-20%',
      labelYear: '-20% sur l\'année',
      labelMonth: '-20% sur 3 mois',
      labelYearMobile: '-20% annuel',
      labelMonthMobile: '-20% sur 3 mois',
      ratioYear: 0.80,
      ratioMonth: 0.80,
      plans: [
        AbbyPlans.ABBY_START,
        AbbyPlans.ABBY_PRO,
        AbbyPlans.ABBY_BUSINESS,
      ],
      startDate: new Date('2024-12-21'),
      endDate: new Date('2025-03-19T23:59:50'),

      bannerApp: {
        title: 'Promotions d\'Hiver',
        subtitle: '<b>-20% sur tous les abonnements</b>',
        mobileTitle: '<b>-20% sur tous les abonnements</b>',
        miniTitle: '<b>-20% sur tous les abonnements</b>',
      },

      bannerPlanModal: {
        title: 'Offre d\'Hiver sur Abby',
        subtitle: 'Tous nos abonnements à -20%',
        promotionText: '-20 % en souscrivant maintenant avec le code ',
      },

      navigationCta: 'Promo d\'Hiver -20%',
    },
  ] as Promotion[]

  const isDateBetween = (date: Date, startDate: Date, endDate: Date): boolean => {
    return date >= startDate && date <= endDate
  }

  const getActivePromotion = (): Promotion | undefined => {
    const now = new Date()
    const promotion = promotions.find(p => isDateBetween(now, p.startDate, p.endDate))
    if (promotion) { return promotion }
    return seasonalPromotions.find(p => isDateBetween(now, p.startDate, p.endDate))
  }

  const isSeasonalPromotion = (): boolean => {
    const now = new Date()
    return !promotions.find(p => isDateBetween(now, p.startDate, p.endDate))
  }

  const getSeasonalPromotion = (): Promotion | undefined => {
    const now = new Date()
    return seasonalPromotions.find(p => isDateBetween(now, p.startDate, p.endDate))
  }

  return {
    getActivePromotion,
    isSeasonalPromotion,
    getSeasonalPromotion,
  }
}
