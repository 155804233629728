import { ReadCustomerStatistics } from '@abby/shared'
import { ClientStatistics } from '~/services/client/_common/valueObjects/ClientStatistics.valueObject'
import { AbbyAmount } from '~/services/abby/valueObjects/AbbyAmount.valueObject'

export class ClientStatisticsMapper {
  static toDomain (result: ReadCustomerStatistics): ClientStatistics {
    return ClientStatistics.create({
      countLate: result.count.late,
      amountLate: AbbyAmount.create(result.amount.late),
      amountPaid: AbbyAmount.create(result.amount.paid),
      revenuesData: result.revenueData,
    })
  }
}
