type BillingReminderActivityProps = {
    isTest: boolean;
    to: string[];
    from: string;
    cc: string;
    date: Date;
}

export class BillingReminderActivity {
    readonly isTest: boolean;
    readonly to: string[];
    readonly from: string;
    readonly cc: string;
    readonly date: Date;

    private constructor (props: BillingReminderActivityProps) {
      this.isTest = props.isTest
      this.to = props.to
      this.from = props.from
      this.cc = props.cc
      this.date = props.date
    }

    static create (props: BillingReminderActivityProps): BillingReminderActivity {
      return new BillingReminderActivity(props)
    }
}
