
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  IBillingConfiguration,
  PersonaFeature,
  PlansConfig,
  ReadPersona,
  StripeProductFrequency,
  PlanFeature,
} from '@abby/core-legacy'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/interfaces/Promotion'

@Component({
  components: { AbbyPlanChip },
  setup () {
    const { getActivePromotion } = usePromotionManager()
    return {
      getActivePromotion,
    }
  },
})
export default class AbbyNewComparativePlan extends Vue {
  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration;

  @Getter('guide/persona') persona!: ReadPersona | null;

  @Prop()
  frequency!: StripeProductFrequency;

  @Prop({ default: true })
  showHeader!: boolean;

  @Prop({ default: null })
  tableHeaderStickyTop!: boolean;

  getActivePromotion!: Function

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get tableHeaderStickyTopValue () {
    if (this.tableHeaderStickyTop) { return this.tableHeaderStickyTop }
    return this.$vuetify.breakpoint.smAndDown ? 75 : 78
  }

  get taxText () {
    return 'mois ht'
  }

  get plansOnPromotion () {
    return this.activePromotion && this.isPromotionDay ? this.activePromotion.plans : []
  }

  get isPromotionDay () {
    const isSubBusinessYearly = this.$planManager.whichPlanCompanyHasSubscribed() === AbbyPlans.ABBY_BUSINESS && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    return !isSubBusinessYearly && this.activePromotion
  }

  get promotionRate () {
    if (this.isPromotionDay && this.activePromotion) {
      return this.frequency === StripeProductFrequency.YEAR ? this.activePromotion.ratioYear : this.activePromotion.ratioMonth
    }
    return 1
  }

  get hasTiersPrestation () {
    return this.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || this.billingConfiguration?.tiersPrestationActivated
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  get SAPProPlan () {
    return {
      title: 'Offre SAP',
      subtitle: 'Tous les outils pour facturer avec l\'avance immédiate',

    }
  }

  get plans () {
    return [{
      title: 'Basique',
      callToAction: 'Je m\'inscris',
      subtitle: 'Facturez vos clients simplement',
      condition: 'Sans engagement',
      plan: AbbyPlans.ABBY_FREE,
      free: true,
      source: 'abby-free',
      pricing: {
        [StripeProductFrequency.MONTH]: { text: 'Gratuit' },
        [StripeProductFrequency.YEAR]: { text: 'Gratuit' },
      },
    }, {
      title: 'Commencer',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Mettez en valeur votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_START,
      source: 'abby-start',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
    }, {
      title: 'Maitriser',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Travaillez l’esprit libre avec un environnement tout-en-un',
      condition: '14 jours offerts, sans engagement',
      popular: true,
      plan: AbbyPlans.ABBY_PRO,
      source: 'abby-pro',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      ...this.hasTiersPrestation
        ? this.SAPProPlan
        : {},
    }, {
      title: 'Aller plus loin',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Utilisez Abby sans limite pour assurer la croissance de votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_BUSINESS,
      soon: true,
      source: 'abby-business',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_BUSINESS].pricing[this.$planManager.abGroupPricing()],
      },
    }]
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get hasAccessToOldStripeFees () {
    return this.$planManager.hasAccessTo(PlanFeature.BILLING_OLD_STRIPE_FEES)
  }

  get stripePlanType () {
    if (this.hasAccessToOldStripeFees) {
      return 'oldStripeFees'
    }
    return 'stripe'
  }

  generateFeesText (plan: AbbyPlans) {
    if (this.stripePlanType === 'stripe' && plan === AbbyPlans.ABBY_FREE) {
      return 'Non'
    }
    const stripe = PlansConfig[plan][this.stripePlanType]
    const rate = stripe.rate + 1.5
    const flat = stripe.flat + 25
    return `${rate}% + ${this.$amount.formatCents(flat).text}`
  }

  get comparations () {
    return [
      {
        title: 'Facturation',
        contents: [{
          title: 'Devis et facture',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Paiement en ligne avec stripe',
          type: 'text',
          values: [
            this.generateFeesText(AbbyPlans.ABBY_FREE),
            this.generateFeesText(AbbyPlans.ABBY_START),
            this.generateFeesText(AbbyPlans.ABBY_PRO),
            this.generateFeesText(AbbyPlans.ABBY_BUSINESS),
          ],
        }, {
          title: 'Personnalisation basique des documents',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Répertoire clients',
          type: 'text',
          values: ['Illimité', 'Illimité', 'Illimité', 'Illimité'],
        }, {
          title: 'Bibliothèque d\'articles',
          type: 'text',
          values: ['Illimité', 'Illimité', 'Illimité', 'Illimité'],
        },
        {
          title: 'Factures d\'acompte',
          type: 'check',
          values: [false, true, true, true],
        },
        {
          title: 'Retirer le logo Abby de vos documents',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Archivage des documents',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Personnalisation du titre d\'un document',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Gestion des acomptes depuis vos devis',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Relances automatiques (impayés)',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Factures et devis à la TVA',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Signature électronique (achat en supplément disponible)',
          type: 'text',
          values: ['Non', 'Non', '2 / mois', 'Illimité'],
        }, {
          title: 'Factures récurrentes',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Avance de crédit d\'impôts (API tiers de prestation)',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Personnalisez vos e-mails',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Ajoutez des pièces jointes à vos documents',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Ajoutez des logos légaux de pieds de page',
          type: 'check',
          values: [false, false, true, true],
        },
        {
          title: 'Gestion des stocks',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Factures en langues et devises étrangères',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Téléchargez vos attestations fiscales',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Etats d’Activités Mensuels (EMA) pour la déclaration NOVA',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Signature personnalisée dans les emails',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Paiement des factures en plusieurs fois avec Klarna',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Formulaire de saisie des clients SAP',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Envoi des e-mails avec son propre client mail (Gmail, Outlook, SMTP)',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Gestion des notes privées et pièces jointes sur les documents',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Programmation des e-mails à envoyer',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Accusé de réception, confirmation, lecture, clique sur CTA des emails',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Image d\'articles dans les documents',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        ],
      },
      {
        title: 'Comptabilité',
        contents: [{
          title: 'Livre des recettes',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Livre des achats',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Estimation CA et cotisations Urssaf',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Déclaration et paiement Urssaf',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Rappel Urssaf, Impôts et CFE',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Connexion au compte bancaire',
          type: 'text',
          values: ['Non', 'Non', '1 compte', 'Illimité'],
        }, {
          title: 'Compte de résultat',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Plan comptable personnalisé',
          type: 'check',
          values: [false, false, true, true],
        },
        {
          title: 'Règles manuelles pour la catégorisation bancaire',
          type: 'check',
          values: [false, false, false, true],
        }, {
          title: 'Certification des justificatifs par cachet serveur',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Export FEC pour votre expert comptable',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Favoris dans les catégories comptables',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Lecture OCR',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        {
          title: 'Récupération des factures par e-mail',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        },
        ],
      },
      {
        title: 'Productivité',
        contents: [{
          title: 'Gestion de vos tâches',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Tableau de suivi',
          type: 'text',
          values: ['5', '10', 'Illimité', 'Illimité'],
        }, {
          title: 'Suivi du temps',
          type: 'text',
          values: ['10', '20', 'Illimité', 'Illimité'],
        }, {
          title: 'Automatisation / intégrations',
          type: 'check',
          icon: {
            color: 'black',
            text: 'Bêta',
          },
          values: [false, false, true, true],
        }, {
          title: 'Export CSV et Excel des données',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Compte de résultat',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Accès formations Académie',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Multi-utilisateurs',
          type: 'check',
          values: [false, false, false, true],
        },
        {
          title: 'Assistant de rédaction par IA dans les éditeurs de texte',
          type: 'check',
          values: [false, false, false, true],
        },
        ],
      },
      {
        title: 'Avantages',
        contents: [{
          title: 'Gain de parrainage par filleul jusqu\'à',
          type: 'text',
          values: ['30 €', '30 €', '60 €', '75 €'],
        }],
      },
      {
        title: 'Support',
        contents: [{
          title: 'Support par ticket',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Support par chat',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Support par visio',
          type: 'check',
          values: [false, false, false, true],
        }, {
          title: 'Support prioritaire par chat',
          type: 'check',
          values: [false, false, false, true],
        }, {
          title: 'Conseiller dédié',
          type: 'check',
          values: [false, false, false, true],
        }],
      },
    ]
  }
}
