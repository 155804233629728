import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useDownloadSignatureProof = () => {
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    return await billingRepository.downloadSignatureProof(billing)
  }
}
