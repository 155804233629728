
import { Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import { ReadBank } from '@abby/shared'
import HomeGuideModal from '~/components-legacy/home/home-guide/HomeGuideModal.vue'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import Link from '~/components-legacy/utils/Link.vue'
import AsSelect from '~/components-legacy/inputs/ASSelect.vue'
import CountryFlag from '~/components-legacy/utils/CountryFlag.vue'
import { GuideState } from '~/store/guide'

@Component({
  components: { CountryFlag, AsSelect, Link, AsTextField, HomeGuideModal },
})
export default class HomeGuideModalBankSynchro extends Vue {
  @Getter('guide/modal')
  guideModal!: GuideState['modal']

  search: string = ''
  displayAllBanks: boolean = false
  countryCode: string = 'fr'
  selectedParentBank: string | null = null
  redirectLoading: boolean = false
  banks: ReadBank[] = []

  banksLoading: boolean = false

  get countryItems () {
    return [
      { text: 'France', value: 'fr' },
      { text: 'Italie', value: 'it' },
      { text: 'Allemagne', value: 'de' },
      { text: 'Espagne', value: 'es' },
      { text: 'Portugal', value: 'pt' },
      { text: 'Luxembourg', value: 'lu' },
      { text: 'Pays-Bas', value: 'nl' },
      { text: 'Belgique', value: 'be' },
    ]
  }

  @Watch('guideModal.bankSynchroModal')
  async handleOpened (value: boolean) {
    if (value) {
      await this.fetchBanks()
    }
  }

  async fetchBanks () {
    try {
      this.banksLoading = true

      this.banks = await this.$backend.bank.getBanks()
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.banksLoading = false
    }
  }

  get selectedParentBankLogo () {
    return this.banks.find(b => b.name === this.selectedParentBank)?.logoUrl || this.banks.find(b => b.parentName === this.selectedParentBank)?.logoUrl
  }

  get childBanks () {
    return this.banks.filter(b => b.parentName === this.selectedParentBank).map(b => ({ name: b.name, img: b.logoUrl, id: b.id, externalId: b.externalId }))
  }

  selectParentBank (parentName: string) {
    this.selectedParentBank = parentName
  }

  async selectBank (bank: any) {
    if (bank.isParent) {
      this.selectParentBank(bank.name)
    } else {
      this.redirectLoading = true
      const link = await this.fetchBridgeLink(bank.id)
      if (link) {
        setTimeout(() => {
          window.location = link as any
        }, 1500)
      } else {
        this.redirectLoading = false
      }
    }
  }

  async fetchBridgeLink (id: number) {
    const redirectUrl = this.$route.query.redirect_url as string
    try {
      const result = await this.$backend.bank.connect({
        bankId: id,
        ...(redirectUrl ? { redirectUrl } : {}),
      })
      return result.redirectUrl
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
    return ''
  }

  showAllBanks () {
    this.displayAllBanks = true
  }

  goToBridgeSecurityPage () {
    window.open('https://bridgeapi.io/conformite-securite', '_blank')
  }

  close () {
    this.$store.dispatch('guide/closeBankSynchroModal')
  }

  get parentBanks () {
    const banksWithoutChild = this.banks.filter(b => !b.parentName && this.countryCode.toUpperCase() === b.countryCode).map(b => ({ name: b.name, img: b.logoUrl, id: b.id, bankId: b.externalId, isHidden: b.isHidden }))
    const banksParent = this.banks.reduce((acc: any, item: ReadBank) => {
      if (item.parentName && !acc.some((accItem: any) => accItem.name === item.parentName) && item.countryCode === this.countryCode.toUpperCase()) {
        acc.push({ name: item.parentName, img: item.logoUrl, isParent: true, isHidden: item.isHidden })
      }
      return acc
    }, [])
    const result = [...banksWithoutChild, ...banksParent]
    const banksToDisplay = result.filter((bank: { isHidden: boolean }) => !bank.isHidden)

    return banksToDisplay.filter((i) => {
      if (!this.search) { return true }
      const escapedSearchTerm = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      const regexPattern = new RegExp(escapedSearchTerm.replace(/%/g, '.*'), 'i') // 'i' pour ignorer la casse
      return regexPattern.test(i.name)
    }).sort((a, b) => {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    })
  }

  get groupedByLetter () {
    const banks = this.parentBanks
    const grouped: any = {}
    for (const bank of banks) {
      const firstLetter = bank.name[0].toUpperCase()
      if (!grouped[firstLetter]) {
        grouped[firstLetter] = []
      }
      grouped[firstLetter].push(bank)
    }

    return Object.keys(grouped).sort().reduce((acc: any, key) => {
      acc[key] = grouped[key]
      return acc
    }, {})
  }

  get mainBanks () {
    return [
      {
        name: 'BoursoBank',
        img: '/home/onboarding/banks/boursobank.png',
        id: 71,
      },
      {
        name: 'Crédit Mutuel',
        img: '/home/onboarding/banks/credit-mutuel.png',
        isParent: true,
      },
      {
        name: 'CIC',
        img: '/home/onboarding/banks/cic.png',
        isParent: true,
      },
      {
        name: 'Qonto',
        img: '/home/onboarding/banks/qonto.png',
        id: 86,
      },
      {
        name: 'Shine',
        img: '/home/onboarding/banks/shine.png',
        id: 42,
      },
      {
        name: 'LCL',
        img: '/home/onboarding/banks/lcl.png',
        idParent: true,

      },
      {
        name: 'BNP Paribas',
        img: '/home/onboarding/banks/bnp.png',
        isParent: true,
      },

    ]
  }
}

