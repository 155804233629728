import { ReadBankAccount } from '@abby/shared'
import { BankAccount } from '~/services/accounting/bank/entities/BankAccount.entity'
import { BankItemMapper } from '~/services/accounting/bank/mappers/BankItem.mapper'

export class BankAccountMapper {
  static fromHttp (data: ReadBankAccount): BankAccount {
    return BankAccount.create({
      id: data.id,
      name: data.name,
      isSynced: data.isSynchronized,
      balance: data.balance || 0,
      transactionsToAnnotate: data.transactionsToAnnotate,
      ...(data.bankItem && { bankItem: BankItemMapper.fromHttp(data.bankItem) }),
    })
  }
}
