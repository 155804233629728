import { ActionTree, GetterTree } from 'vuex'
import {
  BillingRangeType,
  BillingState,
  BillingType,
  EmailType,
  BasePaginateMongo,
  ReadBillingStatistics,
  UpdateBillingFrequency,
  PaymentRequestStatusUrssafTp,
  IBilling,
  CreateBillingFrequency,
} from '@abby/core-legacy'

import {

  BillingType as NewBillingType,
} from '@abby/shared'

import debounce from 'lodash/debounce'
import { CancelTokenSource, CancelTokenStatic } from 'axios'
import { RootState } from '~/store/index'

let fetchBillingCancelToken: CancelTokenStatic | undefined
let fetchBillingCancelTokenSource: CancelTokenSource | undefined
let fetchBillingStatisticsCancelToken: CancelTokenStatic | undefined
let fetchBillingStatisticsCancelTokenSource: CancelTokenSource | undefined

export interface BillingsQuery {
  search?: string | undefined,
  billingState?: Array<BillingState>
  billingType?: Array<BillingType>
  billingRangeType?: Array<BillingRangeType>
  tiersPrestationIsActivatedForThisBilling?: boolean
  paymentRequestStatusUrssafTp?: Array<PaymentRequestStatusUrssafTp>
  archived?: boolean
  onlyOnlineSignature?: boolean
  onlyOnlinePayment?: boolean
  onlyReminderActive?: boolean
  range?: [string, string]
  rangeType?: BillingRangeType
}

export interface BillingsPaginationQuery { page: number, limit: number }

export interface BillingsState {
  isSendBillingByEmailModalOpened: boolean;
  isBillingPaidModalOpened: boolean;
  isUpdateReminderModalOpened: boolean;
  billingDocumentForAction: IBilling | null;
  isBillingFrequencyModalOpened: boolean;
  isBillingValidityDateModalOpened: boolean;
  isBillingTitleModalOpened: boolean;
  isBillingCancelModalOpened: boolean;
  pagination: BasePaginateMongo<IBilling> | null;
  statistics: ReadBillingStatistics | null;
  query: BillingsQuery;
  queryLoading: boolean;
  initialLoading: boolean;
  paginationQuery: BillingsPaginationQuery;
  statisticsLoading: boolean;
  sidePanelBillingOpened: IBilling[];
  importType: string | null;
  emailCustomisationModalDatas: { open: boolean, type: EmailType | null };
}

export const state = (): BillingsState => ({
  isSendBillingByEmailModalOpened: false,
  isBillingPaidModalOpened: false,
  isBillingFrequencyModalOpened: false,
  isBillingValidityDateModalOpened: false,
  isBillingTitleModalOpened: false,
  isBillingCancelModalOpened: false,
  billingDocumentForAction: null,
  isUpdateReminderModalOpened: false,
  statistics: null,
  pagination: null,
  paginationQuery: {
    page: 1,
    limit: 15,
  },
  query: {
    search: undefined,
    billingState: undefined,
    billingType: undefined,
    range: undefined,
    rangeType: undefined,
    tiersPrestationIsActivatedForThisBilling: false,
  },
  queryLoading: true,
  initialLoading: true,
  statisticsLoading: true,
  sidePanelBillingOpened: [],
  importType: null,
  emailCustomisationModalDatas: {
    open: false,
    type: null,
  },
})

export const getters: GetterTree<BillingsState, RootState> = {
  isSendBillingByEmailModalOpened (state: BillingsState) {
    return state.isSendBillingByEmailModalOpened
  },
  isBillingPaidModalOpened (state: BillingsState) {
    return state.isBillingPaidModalOpened
  },
  isBillingFrequencyModalOpened (state: BillingsState) {
    return state.isBillingFrequencyModalOpened
  },
  isBillingValidityDateModalOpened (state: BillingsState) {
    return state.isBillingValidityDateModalOpened
  },
  isBillingTitleModalOpened (state: BillingsState) {
    return state.isBillingTitleModalOpened
  },
  isBillingCancelModalOpened (state: BillingsState) {
    return state.isBillingCancelModalOpened
  },
  isUpdateReminderModalOpened (state: BillingsState) {
    return state.isUpdateReminderModalOpened
  },
  billingDocumentForAction (state: BillingsState) {
    return state.billingDocumentForAction
  },
  pagination (state: BillingsState) {
    return state.pagination
  },
  statistics (state: BillingsState) {
    return state.statistics
  },
  query (state: BillingsState) {
    return state.query
  },
  paginationQuery (state: BillingsState) {
    return state.paginationQuery
  },
  billings (state: BillingsState) {
    return state.pagination?.docs || []
  },
  queryLoading (state: BillingsState) {
    return state.queryLoading
  },
  initialLoading (state: BillingsState) {
    return state.initialLoading
  },
  statisticsLoading (state: BillingsState) {
    return state.statisticsLoading
  },
  stateFilters (state: BillingsState) {
    let filters = [BillingState.DRAFT, BillingState.FINALIZED]

    if ([BillingType.INVOICE, BillingType.ADVANCE].every(type => state.query.billingType?.includes(type))) {
      filters = [...filters, BillingState.PAID]
    } else if (state.query.billingType?.includes(BillingType.ESTIMATE)) {
      filters = [...filters, BillingState.SIGNED, BillingState.REFUSED]
    }

    return filters
  },
  rangeTypeFilters (state: BillingsState) {
    let filters = [BillingRangeType.DATE]

    if ([BillingType.ASSET].every(type => state.query.billingType?.includes(type))) {
      filters = [...filters, BillingRangeType.DUE_DATE]
    } else if ([BillingType.INVOICE, BillingType.ADVANCE].every(type => state.query.billingType?.includes(type))) {
      filters = [...filters, BillingRangeType.PAID_AT, BillingRangeType.DUE_DATE]
    } else if (state.query.billingType?.includes(BillingType.ESTIMATE) || state.query.billingType?.includes(BillingType.PURCHASE_ORDER)) {
      filters = [...filters, BillingRangeType.VALIDITY_DATE, BillingRangeType.SIGNED_AT, BillingRangeType.REFUSED_AT]
    }

    return filters
  },
  sidePanelBillingOpened (state: BillingsState) {
    return state.sidePanelBillingOpened
  },
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.sidePanelBillingOpened.slice(-1)?.[0]?._id === id,
  importType: state => state.importType,
  emailCustomisationModalDatas: state => state.emailCustomisationModalDatas,
}

export const mutations = {
  SET_IMPORT_TYPE (state: BillingsState, value: string | null) {
    state.importType = value
  },
  SET_BILLING_DOCUMENT_FOR_ACTION (state: BillingsState, value: IBilling | null) {
    state.billingDocumentForAction = value
  },
  SET_IS_SEND_BILLING_BY_EMAIL_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isSendBillingByEmailModalOpened = value
  },
  // isBillingPaidModalOpened
  SET_IS_BILLING_PAID_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isBillingPaidModalOpened = value
  },
  SET_IS_BILLING_FREQUENCY_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isBillingFrequencyModalOpened = value
  },
  SET_IS_BILLING_VALIDITY_DATE_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isBillingValidityDateModalOpened = value
  },
  SET_IS_BILLING_TITLE_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isBillingTitleModalOpened = value
  },
  SET_IS_BILLING_CANCEL_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isBillingCancelModalOpened = value
  },
  SET_IS_UPDATE_REMINDER_MODAL_OPENED (state: BillingsState, value: boolean) {
    state.isUpdateReminderModalOpened = value
  },
  SET_PAGINATION_QUERY (state: BillingsState, paginationQuery: BillingsPaginationQuery) {
    state.paginationQuery = paginationQuery
  },
  SET_PAGINATION (state: BillingsState, pagination: BasePaginateMongo<IBilling> | null): void {
    state.pagination = pagination
  },
  SET_QUERY_LOADING (state: BillingsState, value: boolean): void {
    state.queryLoading = value
  },
  UPDATE_QUERY (_state: BillingsState, query: Partial<BillingsQuery>): void {
    _state.query = {
      ..._state.query,
      ...query,
    }
    const newPaginationQuery = state().paginationQuery
    _state.paginationQuery = Object.assign(_state.paginationQuery, newPaginationQuery)
  },
  RESET (_currentState: BillingsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  RESET_QUERY (_currentState: BillingsState) {
    const newQuery = state().query
    const newPaginationQuery = state().paginationQuery
    _currentState.query = Object.assign(_currentState.query, newQuery)
    _currentState.paginationQuery = Object.assign(_currentState.paginationQuery, newPaginationQuery)
  },
  SET_STATISTICS (state: BillingsState, value: any | null): void {
    state.statistics = value
  },
  SET_INITIAL_LOADING (state: BillingsState, value: boolean): void {
    state.initialLoading = value
  },
  SET_STATISTICS_LOADING (state: BillingsState, value: boolean): void {
    state.statisticsLoading = value
  },
  SET_BILLING_SIDE_PANEL_OPENED (state: BillingsState, value: IBilling[]) {
    state.sidePanelBillingOpened = value
  },
  UPDATE_BILLING_IN_BILLINGS (state: BillingsState, billing: IBilling): void {
    const index = state.pagination?.docs.findIndex((doc: IBilling) => doc._id === billing._id)
    if (index !== undefined && index >= 0 && state.pagination?.docs) {
      state.pagination.docs[index] = billing
    }
  },
  SET_EMAIL_CUSTOMISATION_MODAL_OPENED (state: BillingsState, value: { open: boolean, type: EmailType }) {
    state.emailCustomisationModalDatas = value
  },
}

export const actions: ActionTree<any, any> = {
  setImportType ({ commit }, value: string | null) {
    commit('SET_IMPORT_TYPE', value)
  },
  setSendBillingByEmailModal ({ commit }, value: boolean) {
    commit('SET_IS_SEND_BILLING_BY_EMAIL_MODAL_OPENED', value)
  },
  async fetchStatistics ({ commit, getters, rootGetters }) {
    let canceled = false
    try {
      fetchBillingStatisticsCancelTokenSource?.cancel?.('operation_canceled')
      fetchBillingStatisticsCancelToken = this.$axios.CancelToken
      fetchBillingStatisticsCancelTokenSource = fetchBillingStatisticsCancelToken.source()
      commit('SET_STATISTICS_LOADING', true)
      const legacyToNewBillingType = {
        [BillingType.INVOICE]: NewBillingType.INVOICE,
        [BillingType.ADVANCE]: NewBillingType.ADVANCE,
        [BillingType.ESTIMATE]: NewBillingType.ESTIMATE,
        [BillingType.ASSET]: NewBillingType.ASSET,
        [BillingType.PURCHASE_ORDER]: NewBillingType.PURCHASE_ORDER,
        [NewBillingType.INVOICE]: NewBillingType.INVOICE,
        [NewBillingType.ADVANCE]: NewBillingType.ADVANCE,
        [NewBillingType.ESTIMATE]: NewBillingType.ESTIMATE,
        [NewBillingType.ASSET]: NewBillingType.ASSET,
        [NewBillingType.PURCHASE_ORDER]: NewBillingType.PURCHASE_ORDER,
      }
      const statistics = await this.$backend.billing.getStatistics({
        type: getters.query.billingType?.map((type: BillingType | NewBillingType) => legacyToNewBillingType[type]),
        range: getters.query.range,
        rangeType: getters.query.rangeType,
        archived: getters.query.archived,
        cancelToken: fetchBillingStatisticsCancelTokenSource.token,
        test: rootGetters['billingConfiguration/billingConfiguration'].test, // TODO à déplacer dans billingBackend lorsque ce composant sera réécrit
      })
      commit('SET_STATISTICS', statistics)
    } catch (e: any) {
      if (e.message === 'operation_canceled') {
        canceled = true
      } else {
        this.$alertsManager.autoError(e as any)
      }
    } finally {
      if (!canceled) {
        commit('SET_STATISTICS_LOADING', false)
      }
    }
  },
  async fetchBillings ({ commit, getters, dispatch, rootGetters }) {
    let canceled = false
    try {
      fetchBillingCancelTokenSource?.cancel?.('operation_canceled')
      fetchBillingCancelToken = this.$axios.CancelToken
      fetchBillingCancelTokenSource = fetchBillingCancelToken.source()
      if (!rootGetters['billingConfiguration/billingConfiguration']) { return }
      commit('SET_QUERY_LOADING', true)
      // TODO: requeter avec un array de BillingType
      let statisticsBillingType = null
      if (getters.query?.billingType?.includes(BillingType.INVOICE)) {
        statisticsBillingType = BillingType.INVOICE
      } else if (getters.query?.billingType?.includes(BillingType.ESTIMATE)) {
        statisticsBillingType = BillingType.ESTIMATE
      } else if (getters.query?.billingType?.includes(BillingType.PURCHASE_ORDER)) {
        statisticsBillingType = BillingType.PURCHASE_ORDER
      } else if (getters.query?.billingType?.includes(BillingType.ASSET)) {
        statisticsBillingType = BillingType.ASSET
      }
      if (statisticsBillingType) {
        dispatch('fetchStatistics')
      }
      const pagination = await this.$api.billing.getPaginated({
        ...getters.paginationQuery as BillingsPaginationQuery,
        ...getters.query,
        test: rootGetters['billingConfiguration/billingConfiguration'].test,
        countWithoutFilters: true,
      }, { cancelToken: fetchBillingCancelTokenSource.token })
      commit('SET_PAGINATION', pagination)
    } catch (e) {
      if (e instanceof Error && e.message === 'operation_canceled') {
        canceled = true
      }
    } finally {
      if (!canceled) {
        commit('SET_INITIAL_LOADING', false)
        commit('SET_QUERY_LOADING', false)
      }
    }
  },

  debounceFetchBillings: debounce(async ({ dispatch }, _) => {
    await dispatch('fetchBillings')
  }, 300, { leading: true }),
  updateQuery ({ commit, dispatch }, query: Partial<BillingsQuery>) {
    commit('UPDATE_QUERY', query)
    dispatch('debounceFetchBillings')
  },
  async createFrequency ({ state, commit }, payload: CreateBillingFrequency) {
    const result = await this.$api.frequency.createFrequency(payload)
    const billing = {
      ...state.billingDocumentForAction,
      frequencyId: result.id,
      frequency: result,
    }
    commit('SET_BILLING_DOCUMENT_FOR_ACTION', billing)
    commit('UPDATE_BILLING_IN_BILLINGS', billing)
    return result
  },
  async updateFrequency ({ state, commit }, payload: UpdateBillingFrequency) {
    const result = await this.$api.frequency.updateFrequency(state.billingDocumentForAction._id, {
      ...payload,
      offset: Number(payload?.offset),
      startAt: new Date(payload?.startAt || ''),
      stopAt: new Date(payload?.stopAt || ''),
    })
    commit('SET_BILLING_DOCUMENT_FOR_ACTION', result)
    commit('UPDATE_BILLING_IN_BILLINGS', result)
    return result
  },
  updatePaginationQuery ({ commit, dispatch }, query: Partial<BillingsPaginationQuery>) {
    commit('SET_PAGINATION_QUERY', query)
    dispatch('debounceFetchBillings')
  },
  openBillingSidePanel ({ commit, getters }, billing: IBilling) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(billing._id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_BILLING_SIDE_PANEL_OPENED', [
      ...getters.sidePanelBillingOpened,
      billing,
    ])
  },
  closeBillingSidePanel ({ commit, getters }, index: number) {
    commit('SET_BILLING_SIDE_PANEL_OPENED', getters.sidePanelBillingOpened.filter((_: any, i: number) => i !== index))
  },
  setBillingDocumentForAction ({ commit }, value: IBilling | null) {
    commit('SET_BILLING_DOCUMENT_FOR_ACTION', value)
  },
  openEmailCustomisationModalOpened ({ commit, dispatch }, { open, type }: { open: boolean, type: EmailType }) {
    if (open) {
      dispatch('helpCenter/hideHelpCenterButton', null, { root: true })
    } else {
      dispatch('helpCenter/showHelpCenterButton', null, { root: true })
    }
    commit('SET_EMAIL_CUSTOMISATION_MODAL_OPENED', { open, type })
  },
  openBillingTitleModal ({ commit }) {
    commit('SET_IS_BILLING_TITLE_MODAL_OPENED', true)
  },
  closeBillingTitleModal ({ commit }) {
    commit('SET_IS_BILLING_TITLE_MODAL_OPENED', false)
  },
  setBillingCancelModal ({ commit }, value: boolean) {
    commit('SET_IS_BILLING_CANCEL_MODAL_OPENED', value)
  },
  setBillingValidityDateModal ({ commit }, value: boolean) {
    commit('SET_IS_BILLING_VALIDITY_DATE_MODAL_OPENED', value)
  },
  async updateBillingTitle ({ commit }, { id, title }: {id: string, title: string}) {
    try {
      const result = await this.$api.billing.updateTitle(id, title)
      commit('SET_BILLING_DOCUMENT_FOR_ACTION', result)
      commit('UPDATE_BILLING_IN_BILLINGS', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async activateOnlineSignature ({ commit }, { id, validityDate }: {id: string, validityDate: Date}) {
    try {
      const result = await this.$api.billing.activateOnlineSignature(id, validityDate)
      commit('SET_BILLING_DOCUMENT_FOR_ACTION', result)
      commit('UPDATE_BILLING_IN_BILLINGS', result)
      return result
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async activateStripePayment ({ commit }, id: string) {
    try {
      const result = await this.$api.billing.activateStripePayment(id)
      commit('SET_BILLING_DOCUMENT_FOR_ACTION', result)
      commit('UPDATE_BILLING_IN_BILLINGS', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  openMarkAsPaidModal ({ commit }, item: IBilling) {
    commit('SET_BILLING_DOCUMENT_FOR_ACTION', item)
    commit('SET_IS_BILLING_PAID_MODAL_OPENED', true)
  },
  async retrySendBillingUrssaf ({ commit }, data: { _id: string, paymentRequest?: IBilling['paymentRequest'] }) {
    if (!data._id) { return }
    const result = await this.$api.billing.retrySendBillingUrssaf(data._id, data.paymentRequest)
    commit('UPDATE_BILLING_IN_BILLINGS', result)
  },
}
