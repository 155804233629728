import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import { Vat } from '@abby/core-legacy'
import type { IVat } from '@abby/core-legacy'

export interface VatGroup {
 name: string;
 slug: string;
 vats: IVat[];
}

export const useVatsStore = () => {
  const useVatsStore = defineStore('vats', () => {
    const vats = ref<IVat[]>(Vat)

    const groupedVats = computed((): VatGroup[] => {
      return [
        {
          name: 'Classique',
          slug: 'classic',
          vats: vats.value.filter((v: IVat) => !v.isODT && !v.isEuropeanUnion && !v.isOutsideEuropeanUnion),
        },
        {
          name: 'DOM-TOM',
          slug: 'ODT',
          vats: vats.value.filter((v: IVat) => v.isODT),
        },
        {
          name: 'Étranger',
          slug: 'abroad',
          vats: vats.value.filter((v: IVat) => v.isEuropeanUnion || v.isOutsideEuropeanUnion),
        },
      ]
    })

    const reset = () => {
      vats.value = Vat
    }

    return {
      vats,
      groupedVats,
      reset,
    }
  })

  const vatsStore = useVatsStore()
  const { reset } = vatsStore
  const { vats, groupedVats } = storeToRefs(vatsStore)

  return {
    vats,
    groupedVats,
    reset,
  }
}
