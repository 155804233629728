import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useUnarchiveContact = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()

  return async (contact: ContactItem) => {
    await contactRepository.unarchiveContact(contact.id)
    alertManager.success('Le contact a été désarchivé avec succès.')
  }
}
