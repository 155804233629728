export enum StockEvent {
  INVOICE_FINALIZED = 'invoice.finalized',
  INVOICE_PAID = 'invoice.paid',
  ESTIMATE_PURCHASE_ORDER_FINALIZED = 'estimatePurchaseOrder.finalized',
  ESTIMATE_PURCHASE_ORDER_SIGNED = 'estimatePurchaseOrder.signed',
  INVOICE_UNPAID = 'invoice.unpaid',
  ESTIMATE_PURCHASE_ORDER_UNSIGNED = 'estimatePurchaseOrder.unsigned',
  ESTIMATE_PURCHASE_ORDER_REFUSED = 'estimatePurchaseOrder.refused',
  USER = 'user',
}
