import { PaymentMethod, ITransaction } from '@abby/core-legacy'
import { Money, mapPaymentMethodFromLegacy, roundDecimal, ReadSimilarTransaction, CurrencyCode } from '@abby/shared'
import { BankTransactionItem } from '~/services/accounting/bank/entities/BankTransactionItem.entity'

export class BankTransactionItemMapper {
  static toDomain (doc: ITransaction[] | ReadSimilarTransaction[]): BankTransactionItem[] {
    return doc.map((item: ITransaction | ReadSimilarTransaction) => {
      return BankTransactionItem.create({
        id: item.id as string,
        accountId: item.accountId,
        label: item.label,
        fullLabel: item?.fullLabel,
        method: mapPaymentMethodFromLegacy[PaymentMethod.TRANSFER as PaymentMethod],
        amount: Money.create({ amountInCents: roundDecimal(item.amount * 100) }),
        valueDate: new Date(item.valueDate),
        currencyCode: CurrencyCode.EUR,
      })
    })
  }
}
