
import { Action, Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  ABGroupPricing,
  ICompany,
  LegalStatus,
  PlanFeature,
  PlansConfig,
  StripeProductFrequency,
} from '@abby/core-legacy'
import { Action as AbbyAction } from '@abby/shared'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import AbbyGestionPlans from '~/components-legacy/modals/commercialOffers/AbbyGestionPlans.vue'
import Page from '~/components-legacy/layout/containers/Page.vue'
import { CampaignsState } from '~/store/campaigns'
import { BillingConfigurationState } from '~/store/billingConfiguration'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import ASChoiceInput from '~/components-legacy/inputs/ASChoiceInput.vue'
import Link from '~/components-legacy/utils/Link.vue'
import Modal from '~/components-legacy/layout/containers/Modal.vue'
import AbbyNewComparativePlan from '~/components-legacy/plans/AbbyNewComparativePlan.vue'
import ASNewModal from '~/components-legacy/modals/ASNewModal.vue'
import NewPaymentCard from '~/components-legacy/cards/payment/NewPaymentCard.vue'
import VimeoPlayer from '~/components-legacy/academy/VimeoPlayer.vue'

export enum MockStatisticType {
  // BUSINESS
  TOTAL_TURNOVER_AMOUNT = 'totalTurnoverAmount', // PlanFeature.ACCOUNTING_2042_C_PRO_DECLARATION ✅
  NUMBER_OF_SENT_EMAIL = 'numberOfSentEmail', // PlanFeature.BILLING_SET_EMAIL_SIGNATURE ✅
  NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG = 'numberOfWrittenWordsInWysiwyg', // PlanFeature.ABBY_AI ✅
  TOTAL_SAP_BILLED_AMOUNT = 'totalSapBilledAmount', // PlanFeature.BILLING_NOVA_EMA ✅
  NUMBER_OF_SAP_INVOICE = 'numberOfSapInvoice', // PlanFeature.BILLING_TAX_RETURN_DOCUMENT ✅
  NUMBER_OF_ONLINE_SIGNED_ESTIMATES = 'numberOfOnlineSignedEstimates', // PlanFeature.BILLING_ESTIMATE_UNLIMITED_ONLINE_SIGNATURE ✅
  NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS = 'numberOfStockCompatibleProducts', // PlanFeature.STOCK_MANAGEMENT ✅
  NUMBER_OF_UPLOADED_FILES = 'numberOfUploadedFiles', // PlanFeature. ✅
  NUMBER_OF_ANNOTATED_TRANSACTIONS = 'numberOfAnnotatedTransactions', // PlanFeature.TRANSACTIONS_MANAGE_CATEGORIZATION_RULES ✅

  // PRO
  NUMBER_OF_LATE_INVOICES = 'numberOfLateInvoices', // PlanFeature.BILLING_REMINDER ✅
  NUMBER_OF_SIGNED_ESTIMATES = 'numberOfSignedEstimates', // PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE ✅
  IS_VAT_LIMIT_ALMOST_REACHED = 'vatLimitAlmostReached', // PlanFeature.BILLING_VAT ✅
  PERCENTAGE_OF_VAT_LIMIT_REACHED = 'percentageOfVatLimitReached', // PlanFeature.BILLING_VAT ✅
  NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK = 'numberOfManualEntriesInPurchasesBook', // PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION ✅
  NUMBER_OF_FOREIGN_CUSTOMERS = 'numberOfForeignCustomers', // PlanFeature.BILLING_MULTI_CURRENCY, PlanFeature.BILLING_LOCALE ✅
  NUMBER_OF_MISSING_STATISTICS_DAYS = 'numberOfMissingStatisticsDays', // PlanFeature.STATISTICS_4_MONTHS_HISTORY ✅

  // START
  NUMBER_OF_MISSING_URSSAF_DECLARATIONS = 'numberOfMissingUrssafDeclarations', // PlanFeature.ACCOUNTING_URSSAF_DECLARATION ✅
  NUMBER_OF_DOWNLOADED_DOCUMENTS = 'numberOfDownloadedDocuments', // PlanFeature.BILLING_SEND_BY_EMAIL ✅
  NUMBER_OF_CREATED_DOCUMENTS = 'numberOfCreatedDocuments', // PlanFeature.BILLING_WATERMARK_REMOVAL ✅

  // MULTI PLANS
  AMOUNT_OF_ONLINE_PAYMENT_FEES = 'totalOnlinePaymentFees', // ✅
  TOTAL_SPONSORSHIP_SHORTFALL = 'totalSponsorshipShortfall', // ✅
}

export enum StatisticColor {
  GREEN = 'green',
  RED = 'red',
  BLUE = 'blue',
  ORANGE = 'orange',
}

@Component({
  components: {
    ASNewModal,
    AbbyNewComparativePlan,
    Link,
    ASChoiceInput,
    AbbyPlanChip,
    Page,
    AbbyGestionPlans,
    ASDialog,
    Modal,
    NewPaymentCard,
    VimeoPlayer,
  },
})
export default class BlackFridayReport2024 extends Vue {
  @Getter('campaigns/modals') campaignsModal!: CampaignsState['modals'];
  @Action('payment/fetchSubscriptions') fetchSubscriptions!: () => Promise<void>;
  @Getter('billingConfiguration/billingConfiguration') billingConfiguration!: BillingConfigurationState['billingConfiguration'];
  @Getter('company/company') company!: ICompany
  @Getter('billingConfiguration/stripeAccount') stripeAccount!: BillingConfigurationState['stripeAccount']

  loading: boolean = true
  frequency: StripeProductFrequency = StripeProductFrequency.YEAR
  // prorata: boolean = true
  comparativePlanOpened: boolean = false
  paymentModalOpened: boolean = false
  featureInspectionModalOpened: boolean = false
  inspectedFeature: PlanFeature | null = null
  detailStatisticOpened: MockStatisticType | null = null

  median: any = {
    emailSent: 6,
    writtenWords: 88,
    onlineSignedEstimates: 1,
    compatibleProductStock: 2,
    uploadedFiles: 7,
    annotatedTransactions: 89,
    manuelPurchaseRegister: 7,
    lateInvoices: 4,
    signedEstimates: 3,
    missingStatistics: 410,
    foreignCustomers: 2,
    totalOnlinePaymentFees: 581,
    totalSponsorshipShortfall: 30,
  };

  promotionTimer = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    daysStr1: '0',
    daysStr2: '0',
    hoursStr1: '0',
    hoursStr2: '0',
    minutesStr1: '0',
    minutesStr2: '0',
    secondsStr1: '0',
    secondsStr2: '0',
  }

  checkBoxeValues = {
    [AbbyPlans.ABBY_START]: false,
    [AbbyPlans.ABBY_PRO]: false,
    [AbbyPlans.ABBY_BUSINESS]: false,
  }

  report: {
    [k in MockStatisticType]: number
  } = {
    totalSponsorshipShortfall: 0,
    numberOfCreatedDocuments: 0,
    numberOfLateInvoices: 0,
    numberOfOnlineSignedEstimates: 0,
    numberOfSignedEstimates: 0,
    vatLimitAlmostReached: 0,
    percentageOfVatLimitReached: 0,
    totalTurnoverAmount: 0,
    numberOfSentEmail: 0,
    numberOfStockCompatibleProducts: 0,
    numberOfWrittenWordsInWysiwyg: 0,
    numberOfManualEntriesInPurchasesBook: 0,
    totalOnlinePaymentFees: 0,
    totalSapBilledAmount: 0,
    numberOfSapInvoice: 0,
    numberOfUploadedFiles: 0,
    numberOfAnnotatedTransactions: 0,
    numberOfForeignCustomers: 0,
    numberOfMissingStatisticsDays: 0,
    numberOfMissingUrssafDeclarations: 0,
    numberOfDownloadedDocuments: 0,
  };

  toggleDetailStatistic (stat: MockStatisticType) {
    if (this.detailStatisticOpened === stat) {
      this.detailStatisticOpened = null
    } else {
      this.detailStatisticOpened = stat
    }
  }

  get reviews () {
    return [
      {
        avatar: '/plans/blackFridayReport2024/reviews/avatar-1.png',
        name: 'Arnaud Decault',
        activity: 'Entrepreneur indépendant',
        plan: AbbyPlans.ABBY_BUSINESS,
        content: '“J’ai gagné un temps précieux. Merci !”',
      },
    ]
  }

  get displayFrequencySelector () {
    return this.$planManager.whichFrequencyPlanCompanyHas() !== StripeProductFrequency.YEAR
  }

  get discount () {
    if (this.$dayjs().isAfter(this.$dayjs('2024-11-25').startOf('day')) && this.$dayjs().isBefore(this.$dayjs('2024-11-28').endOf('day'))) {
      const discountRate = 0.6
      return {
        discountRate,
        promotion: (1 - discountRate) * 100,
        promoCode: 'BF40',
      }
    } else if (this.$dayjs().isAfter(this.$dayjs('2024-11-29').startOf('day')) && this.$dayjs().isBefore(this.$dayjs('2024-12-02').endOf('day'))) {
      const discountRate = 0.55
      return {
        discountRate,
        promotion: (1 - discountRate) * 100,
        promoCode: 'BF45',
      }
    } else if (this.$dayjs().isAfter(this.$dayjs('2024-12-03').startOf('day')) && this.$dayjs().isBefore(this.$dayjs('2024-12-09').endOf('day'))) {
      const discountRate = 0.65
      return {
        discountRate,
        promotion: (1 - discountRate) * 100,
        promoCode: 'BF35',
      }
    }
  }

  get priceConfig () {
    const pricing = PlansConfig[this.highestSelectedPlan].pricing[ABGroupPricing.DEFAULT][this.frequency as StripeProductFrequency.MONTH | StripeProductFrequency.YEAR]

    return {
      amountByMonth: pricing.amountByMonth,
      amountByMonthWithDiscount: pricing.amountByMonth * (this.discount?.discountRate || 1),
    }

  }

  get inspectedObject () {
    return [
      ...this.statisticsToDisplay.sales[AbbyPlans.ABBY_START] || [],
      ...this.statisticsToDisplay.sales[AbbyPlans.ABBY_PRO] || [],
      ...this.statisticsToDisplay.sales[AbbyPlans.ABBY_BUSINESS] || [],
    ].find(i => i.feature === this.inspectedFeature)
  }

  openFeatureInspectionModal (feature: PlanFeature) {
    this.inspectedFeature = feature
    this.featureInspectionModalOpened = true
  }

  closeFeatureInspectionModal () {
    this.featureInspectionModalOpened = false
    this.inspectedFeature = null
  }

  openPaymentModal () {
    this.paymentModalOpened = true
  }

  closePaymentModal () {
    this.paymentModalOpened = false
  }

  openComparativePlanModal () {
    this.comparativePlanOpened = true
  }

  closeComparativePlanModal () {
    this.comparativePlanOpened = false
  }

  paymentSucceed () {
    this.closePaymentModal()
    this.close()
  }

  get endDate () {
    return `${this.promotionTimer.days}j ${this.promotionTimer.hours}h ${this.promotionTimer.minutes}m ${this.promotionTimer.seconds}s`
  }

  get highestSelectedPlan () {
    if (this.checkBoxeValues[AbbyPlans.ABBY_BUSINESS]) {
      return AbbyPlans.ABBY_BUSINESS
    } else if (this.checkBoxeValues[AbbyPlans.ABBY_PRO]) {
      return AbbyPlans.ABBY_PRO
    } else {
      return AbbyPlans.ABBY_START
    }
  }

  get lowestSelectablePlan () {
    if (this.statisticsToDisplay.sales[AbbyPlans.ABBY_START]) {
      return AbbyPlans.ABBY_START
    } else if (this.statisticsToDisplay.sales[AbbyPlans.ABBY_PRO]) {
      return AbbyPlans.ABBY_PRO
    } else {
      return AbbyPlans.ABBY_BUSINESS
    }
  }

  get CheckBoxColor () {
    return {
      [AbbyPlans.ABBY_START]: 'primary',
      [AbbyPlans.ABBY_PRO]: '#e7a50a',
      [AbbyPlans.ABBY_BUSINESS]: 'purple',
    }
  }

  get AdditionalPlan () {
    return {
      [AbbyPlans.ABBY_START]: null,
      [AbbyPlans.ABBY_PRO]: AbbyPlans.ABBY_START,
      [AbbyPlans.ABBY_BUSINESS]: AbbyPlans.ABBY_PRO,
    }
  }

  get NumberOfFeatures () {
    return {
      [AbbyPlans.ABBY_START]: 19,
      [AbbyPlans.ABBY_PRO]: 34,
      [AbbyPlans.ABBY_BUSINESS]: 58,
    }
  }

  get StatisticColor () {
    return StatisticColor
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  get statisticsToDisplay () {
    const statisticsBusiness = []
    const statisticsPro = []
    const statisticsStart = []
    const statisticsMultiplans = []

    /*
     * BUSINESS
     */
    if (this.billingConfiguration?.tiersPrestationActivated && this.billingConfiguration?.tiersPrestationValidated && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_NOVA_EMA)) {
      statisticsBusiness.push({
        color: StatisticColor.GREEN,
        feature: PlanFeature.BILLING_NOVA_EMA,
        type: MockStatisticType.TOTAL_SAP_BILLED_AMOUNT,
        weight: 9999,
        highlight: {
          title: `${this.report[MockStatisticType.TOTAL_SAP_BILLED_AMOUNT]} €`,
          subtitle: 'Chiffre d\'affaires en SAP',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_SAP_BILLED_AMOUNT}.png`,
        },
        sales: {
          title: 'Calcul de la déclaration EMA automatique',
          description: 'Gérez efficacement vos obligations SAP, Abby calcule votre déclaration EMA a effectuer sur Nova chaque trimestre.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_SAP_BILLED_AMOUNT}_black.png`,
        },
        inspection: {
          title: 'Calcul de la déclaration EMA automatique',
          description: 'Vous en avez assez de perdre du temps à compiler vos factures et à calculer vos déclarations Nova ? Avec Abby Business, dites adieu à cette corvée. Dans l\'onglet Comptabilité, générez votre déclaration Nova en un seul clic. Abby vous fournit les chiffres précis à reporter, vous évitant oublis et erreurs. Gagnez de précieuses heures et concentrez-vous sur le développement de votre activité.',
          video: '1031977860',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.TOTAL_SAP_BILLED_AMOUNT}.png`,
        },
      })
    }

    if (this.billingConfiguration?.tiersPrestationActivated && this.billingConfiguration?.tiersPrestationValidated && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_TAX_RETURN_DOCUMENT)) {
      statisticsBusiness.push({
        color: StatisticColor.RED,
        feature: PlanFeature.BILLING_TAX_RETURN_DOCUMENT,
        type: MockStatisticType.NUMBER_OF_SAP_INVOICE,
        weight: 9999,
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_SAP_INVOICE],
          subtitle: 'Factures en SAP',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SAP_INVOICE}.png`,
        },
        sales: {
          title: 'Attestations fiscales SAP',
          description: 'Téléchargez les attestation fiscales de vos clients à transmettre avant le 31 mars en 1 clic. Abby génère des documents 100% conformes. Gagnez un temps précieux.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SAP_INVOICE}_black.png`,
        },
        inspection: {
          title: 'Attestations fiscales SAP',
          description: 'Fini le temps où vous passiez des semaines sur la création fastidieuse d\'attestations fiscales pour vos clients. Avec Abby Business, produisez-les en un seul clic pour toute l\'année ! Suivez simplement quelques étapes intuitives pour télécharger vos attestations fiscales. Simplifiez vos obligations administratives, gagnez un temps précieux et concentrez-vous sur le développement de votre activité.',
          video: '1031977789',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_SAP_INVOICE}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.TOTAL_TURNOVER_AMOUNT] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.ACCOUNTING_2042_C_PRO_DECLARATION) && [LegalStatus.EI, LegalStatus.MICRO_ENTREPRISE].includes(this.company?.legalStatus || LegalStatus.EI)) {
      statisticsBusiness.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.ACCOUNTING_2042_C_PRO_DECLARATION,
        type: MockStatisticType.TOTAL_TURNOVER_AMOUNT,
        weight: 9999,
        highlight: {
          title: `${this.report[MockStatisticType.TOTAL_TURNOVER_AMOUNT]} €`,
          subtitle: 'Chiffre d\'affaires réalisé sur 2024',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_TURNOVER_AMOUNT}.png`,
        },
        sales: {
          title: 'Calcul de votre déclaration d\'impôts',
          description: 'Simplifiez vos obligations fiscales. Abby prépare et pré-remplit votre déclaration 2042-C PRO avec vos données comptables.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_TURNOVER_AMOUNT}_black.png`,
        },
        inspection: {
          title: 'Calcul de votre déclaration d\'impôts',
          description: 'Fin d’année rime avec déclaration d’impôts. Évitez les erreurs avec Abby business, nous vous accompagnons dans le remplissage de votre déclaration 2042 C-PRO. Abby vous guide à travers les différentes étapes en vous restituant votre chiffre d’affaires à déclarer et en vous accompagnant sur les options que vous avez à sélectionner.',
          video: '1031977662',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.TOTAL_TURNOVER_AMOUNT}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_SENT_EMAIL] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_SET_EMAIL_SIGNATURE)) {
      statisticsBusiness.push({
        color: StatisticColor.ORANGE,
        feature: PlanFeature.BILLING_SET_EMAIL_SIGNATURE,
        type: MockStatisticType.NUMBER_OF_SENT_EMAIL,
        weight: this.report[MockStatisticType.NUMBER_OF_SENT_EMAIL] / this.median[MockStatisticType.NUMBER_OF_SENT_EMAIL],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_SENT_EMAIL],
          subtitle: 'E-mails envoyés depuis Abby',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SENT_EMAIL}.png`,
        },
        sales: {
          title: 'Personnalisation de la signature e-mail',
          description: 'Ajoutez une touche professionnelle à vos e-mails en personnalisant les signatures e-mail des communications envoyées depuis Abby.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SENT_EMAIL}_black.png`,
        },
        inspection: {
          title: 'Personnalisation de la signature e-mail',
          description: 'Personnalisez vos emails avec une signature unique grâce à notre fonctionnalité intuitive. Ajoutez facilement votre logo, vos coordonnées et des liens vers vos réseaux sociaux pour renforcer votre image professionnelle. Créez une signature qui reflète votre identité et laissez une impression durable à chaque envoi.',
          video: '1031900499',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_SENT_EMAIL}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.ABBY_EDITOR_AI)) {
      statisticsBusiness.push({
        color: StatisticColor.RED,
        feature: PlanFeature.ABBY_EDITOR_AI,
        type: MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG,
        weight: this.report[MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG] / this.median[MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG],
          subtitle: 'Mots écris dans les éditeurs de texte',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG}.png`,
        },
        sales: {
          title: 'Assistant d\'écriture intelligent (Abby AI)',
          description: 'Rédigez rapidement avec l\'aide d\'un assistant intelligent. Corrigez, reformulez et optimisez vos contenus directement dans vos éditeurs de texte.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG}_black.png`,
        },
        inspection: {
          title: 'Assistant d\'écriture intelligent (Abby AI)',
          description: 'L\'IA intégrée d\'Abby Business optimise votre rédaction professionnelle. Accessible dans tous les éditeurs, elle génère rapidement des descriptions précises et offre des outils d\'édition variés. Simplifiez la création de vos documents tout en restant professionnel. Activez cette fonctionnalité pour gagner en efficacité !',
          video: '1031914575',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_WRITTEN_WORDS_IN_WYSIWYG}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_ESTIMATE_UNLIMITED_ONLINE_SIGNATURE)) {
      statisticsBusiness.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.BILLING_ESTIMATE_UNLIMITED_ONLINE_SIGNATURE,
        type: MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES,
        weight: this.report[MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES] / this.median[MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES],
          subtitle: 'Devis signés en ligne',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES}.png`,
        },
        sales: {
          title: 'Signature illimitée des devis en ligne',
          description: 'Facilitez la signature de vos devis avec une fonctionnalité illimitée. Accélérez vos cycles de vente et améliorez la satisfaction client.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES}_black.png`,
        },
        inspection: {
          title: 'Signature illimitée des devis en ligne',
          description: 'Accélérez vos ventes avec la signature en ligne illimitée d\'Abby ! Offrez à vos clients une expérience fluide et professionnelle : ils peuvent signer leurs devis ou bons de commande en quelques clics, sur ordinateur ou mobile. Plus rapide qu’une signature papier, elle sécurise vos transactions grâce à une certification par email et numéro de téléphone, tout en renforçant leur valeur légale. Transformez vos interactions commerciales en succès, dès aujourd’hui !',
          video: '1031902253',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_ONLINE_SIGNED_ESTIMATES}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS] && !this.$planManager.hasAccessToWithSubscription(PlanFeature.STOCK_MANAGEMENT)) {
      statisticsBusiness.push({
        color: StatisticColor.ORANGE,
        feature: PlanFeature.STOCK_MANAGEMENT,
        type: MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS,
        weight: this.report[MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS] / this.median[MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS],
          subtitle: 'Articles de vente enregistrés ',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS}.png`,
        },
        sales: {
          title: 'Gestion des stocks simplifiée',
          description: 'Suivez et gérez efficacement vos stocks pour éviter les ruptures ou les excédents. Visualisez en temps réel les niveaux de vos articles de vente.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS}_black.png`,
        },
        inspection: {
          title: 'Gestion des stocks simplifiée',
          description: 'Optimisez la gestion de vos stocks avec notre solution intuitive. Suivez en temps réel vos niveaux de stock, anticipez les besoins de réapprovisionnement et évitez les ruptures. Notre interface conviviale vous permet de gérer efficacement vos produits, réduisant les coûts et améliorant la satisfaction client. Prenez le contrôle de votre inventaire et boostez votre rentabilité dès aujourd\'hui.',
          video: '1031906211',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_STOCK_COMPATIBLE_PRODUCTS}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_UPLOADED_FILES] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.ELECTRONIC_SEAL)) {
      statisticsBusiness.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.ELECTRONIC_SEAL,
        type: MockStatisticType.NUMBER_OF_UPLOADED_FILES,
        weight: this.report[MockStatisticType.NUMBER_OF_UPLOADED_FILES] / this.median[MockStatisticType.NUMBER_OF_UPLOADED_FILES],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_UPLOADED_FILES],
          subtitle: 'Justificatifs d\'achats ou de ventes associés',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_UPLOADED_FILES}.png`,
        },
        sales: {
          title: 'Certification de vos justificatifs d\'achats',
          description: 'Assurez l\'authenticité et l\'intégrité de vos justificatifs grâce à un sceau électronique certifié. Renforcez la conformité de votre entreprise.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_UPLOADED_FILES}_black.png`,
        },
        inspection: {
          title: 'Certification de vos justificatifs d\'achats',
          description: 'Vous aussi, vous avez un tas de factures que vous gardez au cas où il y aurait un contrôle des impôts ? Avec Abby Business, c’est de l’histoire ancienne. Grâce à notre partenaire Yousign, nous certifions vos documents. Elles ont ainsi la même valeur juridique qu’une facture papier. Vous pouvez donc les jeter au tri !',
          video: '1031977730',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_UPLOADED_FILES}.png`,
        },
      })
    }

    if (this.report[MockStatisticType.NUMBER_OF_UPLOADED_FILES] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.TRANSACTIONS_MANAGE_CATEGORIZATION_RULES)) {
      statisticsBusiness.push({
        color: StatisticColor.GREEN,
        feature: PlanFeature.TRANSACTIONS_MANAGE_CATEGORIZATION_RULES,
        type: MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS,
        weight: this.report[MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS] / this.median[MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS],
          subtitle: 'Transactions catégorisées',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS}.png`,
        },
        sales: {
          title: 'Règles de catégorisation automatiques',
          description: 'Configurez Abby pour qu\'il classe automatiquement vos transactions bancaires dans les bons postes de dépenses et de recettes.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS}_black.png`,
        },
        inspection: {
          title: 'Règles de catégorisation automatiques',
          description: 'Simplifiez votre comptabilité avec Abby Business ! Marre de catégoriser les mêmes transactions tous les mois ? Grâce à nos paramètres intelligents, automatisez la catégorisation de vos opérations répétitives. Gagnez du temps, éliminez les tâches fastidieuses et concentrez-vous sur ce qui compte vraiment pour votre activité !',
          video: '1031907506',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_ANNOTATED_TRANSACTIONS}.png`,
        },
      })
    }

    /*
    * PRO
    */
    if (this.report[MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION)) {
      statisticsPro.push({
        color: StatisticColor.RED,
        feature: PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION,
        type: MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK,
        weight: this.report[MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK] / this.median[MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK],
          subtitle: 'Entrées manuelles dans le livre des achats',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK}.png`,
        },
        sales: {
          title: 'Synchronisation bancaire',
          description: 'Connectez vos comptes bancaires, synchronisez vos transactions automatiquement et catégorisez vos entrées / sorties. Réduisez les tâches manuelles et les risques d\'erreurs.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK}_black.png`,
        },
        inspection: {
          title: 'Synchronisation bancaire',
          description: 'Dites adieu à la saisie manuelle et fastidieuse de vos achats, grâce au plan Abby Pro, vous pouvez connecter votre compte bancaire et catégoriser vos achats de marchandise. Abby se chargera alors d’ajouter cette dépense dans votre livre des achats. En cas de contrôle, vous n’avez qu’à télécharger votre livre des achats en PDF et le présenter au controleur.',
          video: '1031977480',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_MANUAL_ENTRIES_IN_PURCHASES_BOOK}.png`,
        },
      })
    }
    if (this.report[MockStatisticType.NUMBER_OF_LATE_INVOICES] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_REMINDER)) {
      statisticsPro.push({
        color: StatisticColor.ORANGE,
        feature: PlanFeature.BILLING_REMINDER,
        type: MockStatisticType.NUMBER_OF_LATE_INVOICES,
        weight: this.report[MockStatisticType.NUMBER_OF_LATE_INVOICES] / this.median[MockStatisticType.NUMBER_OF_LATE_INVOICES],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_LATE_INVOICES],
          subtitle: 'Factures ayant déjà été en retard',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_LATE_INVOICES}.png`,
        },
        sales: {
          title: 'Relances automatiques en cas d\'impayés',
          description: 'Déchargez vous de cette tâche chronophage. Abby relance automatiquement vos clients s\'ils ne vous paient pas dans les temps. Sécurisez vos revenus sans efforts.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_LATE_INVOICES}_black.png`,
        },
        inspection: {
          title: 'Relances automatiques en cas d\'impayés',
          description: 'Réduisez vos impayés avec la fonctionnalité de relances automatiques d\'Abby. Activez-la en un clic lors de l\'édition de vos documents, définissez la fréquence d\'envoi et personnalisez entièrement vos emails de relance. Cette solution vous aide à maintenir une trésorerie saine en automatisant le suivi des paiements.',
          video: '1031910684',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_LATE_INVOICES}.png`,
        },
      })
    }
    if (this.report[MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE)) {
      statisticsPro.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.BILLING_ESTIMATE_ONLINE_SIGNATURE,
        type: MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES,
        weight: this.report[MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES] / this.median[MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES],
          subtitle: 'Devis signés',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES}.png`,
        },
        sales: {
          title: 'Signature des devis en ligne',
          description: 'Accélérez la signature de vos devis. Avec la signature électronique certifiée, ne perdez plus aucune preuve de signature.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES}_black.png`,
        },
        inspection: {
          title: 'Signature des devis en ligne',
          description: 'Simplifiez vos ventes avec la signature en ligne ! Vos clients peuvent signer leurs devis ou bons de commande en quelques clics, directement depuis leur téléphone ou leur ordinateur. Grâce à la certification par numéro de téléphone et email, cette méthode offre une sécurité renforcée et une valeur légale supérieure à une simple signature sur PDF. Accélérez vos transactions, sécurisez vos ventes et transformez vos prospects en clients en un rien de temps !',
          video: '1031912351',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_SIGNED_ESTIMATES}.png`,
        },
      })
    }
    if (!this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_VAT)) {
      statisticsPro.push({
        color: StatisticColor.GREEN,
        feature: PlanFeature.BILLING_VAT,
        type: MockStatisticType.PERCENTAGE_OF_VAT_LIMIT_REACHED,
        weight: this.report[MockStatisticType.IS_VAT_LIMIT_ALMOST_REACHED] ? 9999 : 0,
        highlight: {
          title: `${this.report[MockStatisticType.PERCENTAGE_OF_VAT_LIMIT_REACHED]} %`,
          subtitle: 'De CA atteint par rapport au seuil de TVA',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.PERCENTAGE_OF_VAT_LIMIT_REACHED}.png`,
        },
        sales: {
          title: 'Gestion et facturation de la TVA',
          description: 'Réalisez vos factures en toute conformité avec votre régime de TVA. Abby calcule automatiquement vos déclarations de TVA.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.PERCENTAGE_OF_VAT_LIMIT_REACHED}_black.png`,
        },
        inspection: {
          title: 'Gestion et facturation de la TVA',
          description: 'Vous avez la chair de poule quand vous entendez le mot TVA ? Avec Abby Pro, nous vous fournissons un outil qui vous indiquera l’intégralité des chiffres à remplir sur votre déclaration de TVA qu’elle soit mensuelle ou trimestrielle. En connectant votre compte bancaire pour vos achats et en marquant vos factures comme payées, votre déclaration sera générée automatiquement.',
          video: '1031977585',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.PERCENTAGE_OF_VAT_LIMIT_REACHED}.png`,
        },
      })
    }
    if (!this.$planManager.hasAccessToWithSubscription(PlanFeature.STATISTICS_4_MONTHS_HISTORY)) {
      statisticsPro.push({
        color: StatisticColor.RED,
        feature: PlanFeature.STATISTICS_4_MONTHS_HISTORY,
        type: MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS,
        weight: this.report[MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS] / this.median[MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS],
          subtitle: 'Jours d\'analyse statistique masqués sur Abby',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS}.png`,
        },
        sales: {
          title: 'Historique d\'analyse statistique illimité',
          description: 'Accédez à vos statistiques sans aucune limite de durée. Consultez et analysez vos données à tout moment pour optimiser vos décisions stratégiques.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS}_black.png`,
        },
        inspection: {
          title: 'Historique d\'analyse statistique illimité',
          description: 'Pilotez votre entreprise avec des statistiques détaillées ! Accédez à des analyses complètes de votre chiffre d’affaires, entrées, sorties, et résultats sur des périodes personnalisables (mois, trimestre, année ou tout le temps). Découvrez vos performances par article ou par client, identifiez vos meilleurs clients pour les remercier, et suivez vos factures en attente. Passez au plan Pro pour une gestion optimisée et une vision claire de vos performances, à tout moment !',
          video: '1031914398',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_MISSING_STATISTICS_DAYS}.png`,
        },
      })
    }
    if (!this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_MULTI_CURRENCY) || !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_LOCALE)) {
      statisticsPro.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.BILLING_LOCALE,
        type: MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS,
        weight: this.report[MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS] / this.median[MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS],
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS],
          subtitle: 'Clients domiciliés à l\'étranger',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS}.png`,
        },
        sales: {
          title: 'Factures en langues et devises étrangères',
          description: 'Créez des documents conformes pour vos clients à l\'étranger. Les documents sont traduits et les taux de change automatiquement appliqués selon les données de la BCE.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS}_black.png`,
        },
        inspection: {
          title: 'Factures en langues et devises étrangères',
          description: 'Développez votre activité à l’international avec la traduction automatique des documents ! Factures, devis ou autres documents peuvent être instantanément traduits dans plusieurs langues. En quelques clics, proposez des documents clairs et adaptés à vos clients étrangers. Facilitez vos échanges, ouvrez de nouveaux marchés, et boostez vos ventes à l’international grâce à cette fonctionnalité pratique et intuitive !',
          video: '1031912822',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_FOREIGN_CUSTOMERS}.png`,
        },
      })
    }

    /*
     * START
     */
    if (this.report[MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.ACCOUNTING_URSSAF_DECLARATION) && [LegalStatus.EI, LegalStatus.MICRO_ENTREPRISE].includes(this.company?.legalStatus || LegalStatus.EI)) {
      statisticsStart.push({
        color: StatisticColor.GREEN,
        feature: PlanFeature.ACCOUNTING_URSSAF_DECLARATION,
        type: MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS,
        weight: 9999,
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS],
          subtitle: 'Déclarations Urssaf manquantes',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS}.png`,
        },
        sales: {
          title: 'Déclaration Urssaf',
          description: 'Connectez votre compte Urssaf, estimer vos cotisations et déclarer votre chiffre d\'affaires. Recevez des rappels pour ne jamais oublier.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS}_black.png`,
        },
        inspection: {
          title: 'Déclaration Urssaf',
          description: 'Simplifiez vos déclarations Urssaf avec Abby ! Grâce à Abby, connectez facilement votre compte Urssaf pour récupérer vos informations et estimer vos cotisations à partir de vos transactions et vos documents édités. Recevez des rappels, choisissez le montant à déclarer, et payez directement depuis l\'application. Tout est pensé pour vous faire gagner du temps et vous permettre de gérer vos obligations en toute sérénité, sans quitter Abby !',
          video: '1031913152',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_MISSING_URSSAF_DECLARATIONS}.png`,
        },
      })
    }
    if (this.report[MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_WATERMARK_REMOVAL)) {
      statisticsStart.push({
        color: StatisticColor.BLUE,
        feature: PlanFeature.BILLING_WATERMARK_REMOVAL,
        type: MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS,
        weight: 9999,
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS],
          subtitle: 'Documents créés',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS}.png`,
        },
        sales: {
          title: 'Retirer le logo Abby de vos documents',
          description: 'Affirmez l\'image de votre entreprise. Le logo Abby n\'apparaîtra plus en base de page de vos documents',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS}_black.png`,
        },
        inspection: {
          title: 'Retirer le logo Abby de vos documents',
          description: 'À partir du plan Start, personnalisez vos documents en retirant le logo Abby en un clic. Mettez en avant votre propre identité visuelle pour des factures et devis 100 % professionnels et à votre image. Adoptez cette fonctionnalité pour valoriser votre entreprise et séduire vos clients dès le premier regard !',
          video: '1031914663',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_CREATED_DOCUMENTS}.png`,
        },
      })
    }
    if (this.report[MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS] > 0 && !this.$planManager.hasAccessToWithSubscription(PlanFeature.BILLING_SEND_BY_EMAIL)) {
      statisticsStart.push({
        color: StatisticColor.RED,
        feature: PlanFeature.BILLING_SEND_BY_EMAIL,
        type: MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS,
        weight: 9999,
        highlight: {
          title: this.report[MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS],
          subtitle: 'Factures téléchargées',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS}.png`,
        },
        sales: {
          title: 'Envoi des documents par e-mail',
          description: 'Gagnez un temps précieux en les envoyant directement depuis votre outil de facturation. Vos documents s\'ajouteront en pièces jointes automatiquement',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS}_black.png`,
        },
        inspection: {
          title: 'Envoi des documents par e-mail',
          description: 'Envoyez vos factures en un clic avec Abby ! À partir du plan Abby Start, finalisez et expédiez vos documents directement par email. Personnalisez facilement l’objet, le contenu et la signature de vos emails, en ajoutant par exemple votre logo pour renforcer votre image professionnelle. Gagnez du temps, améliorez votre communication et simplifiez l’envoi de vos factures à vos clients grâce à cette fonctionnalité intuitive et efficace !',
          video: '1031913775',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.NUMBER_OF_DOWNLOADED_DOCUMENTS}.png`,
        },
      })
    }

    const subscribedPlan = this.$planManager.whichPlanCompanyHasSubscribed()
    const maximumOfItemsToHighlight = 6
    let numberOfStartItemsToHighlight = 0; let numberOfProItemsToHighlight = 0; let numberOfBusinessItemsToHighlight = 0
    let numberOfRemainingItemsToHighlight = maximumOfItemsToHighlight

    if (subscribedPlan === AbbyPlans.ABBY_FREE) {
      numberOfStartItemsToHighlight = statisticsStart.length < 2 ? statisticsStart.length : 2
      numberOfRemainingItemsToHighlight -= numberOfStartItemsToHighlight
      numberOfProItemsToHighlight = statisticsPro.length < numberOfRemainingItemsToHighlight ? statisticsPro.length : 2
      numberOfRemainingItemsToHighlight -= numberOfProItemsToHighlight
      numberOfBusinessItemsToHighlight = statisticsBusiness.length < numberOfRemainingItemsToHighlight ? statisticsBusiness.length : 2
      numberOfRemainingItemsToHighlight -= numberOfBusinessItemsToHighlight
    } else if (subscribedPlan === AbbyPlans.ABBY_START) {
      numberOfProItemsToHighlight = statisticsPro.length < 3 ? statisticsPro.length : 3
      numberOfRemainingItemsToHighlight -= numberOfProItemsToHighlight
      numberOfBusinessItemsToHighlight = statisticsBusiness.length < numberOfRemainingItemsToHighlight ? statisticsBusiness.length : 3
      numberOfRemainingItemsToHighlight -= numberOfBusinessItemsToHighlight
    } else if (subscribedPlan === AbbyPlans.ABBY_PRO) {
      numberOfBusinessItemsToHighlight = statisticsBusiness.length < 6 ? statisticsBusiness.length : 6
      numberOfRemainingItemsToHighlight -= numberOfBusinessItemsToHighlight
    }

    const maximumOfItemsForSalesPart = 9
    let numberOfStartItemsForSalesPart = 0; let numberOfProItemsForSalesPart = 0; let numberOfBusinessItemsForSalesPart = 0
    let numberOfRemainingItemsForSalesPart = maximumOfItemsForSalesPart

    if (subscribedPlan === AbbyPlans.ABBY_FREE) {
      numberOfStartItemsForSalesPart = statisticsStart.length < 3 ? statisticsStart.length : 3
      numberOfRemainingItemsForSalesPart -= numberOfStartItemsForSalesPart
      numberOfProItemsForSalesPart = statisticsPro.length < numberOfRemainingItemsForSalesPart ? statisticsPro.length : 3
      numberOfRemainingItemsForSalesPart -= numberOfProItemsForSalesPart
      numberOfBusinessItemsForSalesPart = statisticsBusiness.length < numberOfRemainingItemsForSalesPart ? statisticsBusiness.length : 3
      numberOfRemainingItemsForSalesPart -= numberOfBusinessItemsForSalesPart
    } else if (subscribedPlan === AbbyPlans.ABBY_START) {
      numberOfProItemsForSalesPart = statisticsPro.length < 3 ? statisticsPro.length : 3
      numberOfRemainingItemsForSalesPart -= numberOfProItemsForSalesPart
      numberOfBusinessItemsForSalesPart = statisticsBusiness.length < numberOfRemainingItemsForSalesPart ? statisticsBusiness.length : 3
      numberOfRemainingItemsForSalesPart -= numberOfBusinessItemsForSalesPart
    } else if (subscribedPlan === AbbyPlans.ABBY_PRO) {
      numberOfBusinessItemsForSalesPart = statisticsBusiness.length < 6 ? statisticsBusiness.length : 6
      numberOfRemainingItemsForSalesPart -= numberOfBusinessItemsForSalesPart
    }

    /*
     * MULTI PLANS
     */
    let multiPlansPlan
    if (numberOfBusinessItemsToHighlight > 0) {
      multiPlansPlan = AbbyPlans.ABBY_BUSINESS
    } else if (numberOfProItemsToHighlight > 0) {
      multiPlansPlan = AbbyPlans.ABBY_PRO
    } else {
      multiPlansPlan = AbbyPlans.ABBY_START
    }

    if (this.report[MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES] > 0 && this.stripeAccount?.type === 'express' && this.stripeAccount?.charges_enabled) {
      statisticsMultiplans.push({
        plan: multiPlansPlan,
        color: StatisticColor.ORANGE,
        type: MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES,
        weight: this.report[MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES] / this.median[MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES],
        feature: 'onlinePayment',
        highlight: {
          title: `${this.report[MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES]} €`,
          subtitle: 'Encaissé en ligne',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES}.png`,
        },
        sales: {
          title: 'Optimisation des frais de paiement en ligne',
          description: 'Diminuez considérablement les coûts associés à vos transactions en ligne.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES}_black.png`,
        },
        inspection: {
          title: 'Optimisation des frais de paiement en ligne',
          description: 'En choisissant nos plans supérieurs, vous diminuez considérablement les coûts associés à vos transactions en ligne. Par exemple, avec le forfait Abby Business, les frais sur une facture de 1 000 € ne sont que de 15,25 €, contre 34,60 € avec le forfait Start. Cela représente une économie substantielle qui peut atteindre des centaines d\'euros par an. Non seulement vous économisez de l\'argent, mais vous accélérerez également votre cycle de vente. Investissez dans un forfait adapté à vos besoins et boostez votre rentabilité dès aujourd\'hui.',
          video: '1031977914',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.AMOUNT_OF_ONLINE_PAYMENT_FEES}.png`,
        },
      })
    }
    if (this.report[MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL] > 0) {
      statisticsMultiplans.push({
        plan: multiPlansPlan,
        color: StatisticColor.RED,
        type: MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL,
        weight: this.report[MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL] / this.median[MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL],
        feature: 'sponsorship',
        highlight: {
          title: `${this.report[MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL]} €`,
          subtitle: 'Manque à gagner sur le parrainage',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL}.png`,
        },
        sales: {
          title: 'x2,5 sur vos gains de parrainage',
          description: 'Gagnez jusqu\'à 100 € par parrainage avec un multiplicateur supplémentaire. Vous nous recommandez, vous êtes récompensez.',
          icon: `/plans/blackFridayReport2024/${MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL}_black.png`,
        },
        inspection: {
          title: 'Jusqu\'à 100 € par parrainage',
          description: 'Depuis près de quatre ans, nous nous consacrons à développer Abby en partageant la valeur créée avec vous, nos utilisateurs fidèles. Aujourd\'hui, nous sommes ravis de lancer le nouveau multiplicateur du programme de parrainage Abby Business, qui vous permet de multiplier vos gains par 2,5! Par exemple, en parrainant seulement 3 à 4 personnes, vous pouvez gagner plus de 300 €. C\'est un bon moyen d’augmenter vos revenus tout en aidant d\'autres entrepreneurs à découvrir les avantages d\'Abby.',
          video: '1031977985',
          image: `/plans/blackFridayReport2024/reviews/${MockStatisticType.TOTAL_SPONSORSHIP_SHORTFALL}.png`,
        },
      })
    }

    const sortedStatisticsBusiness = [...statisticsBusiness, ...multiPlansPlan === AbbyPlans.ABBY_BUSINESS ? statisticsMultiplans : []].sort((a, b) => b.weight - a.weight)
    const sortedStatisticsPro = [...statisticsPro, ...multiPlansPlan === AbbyPlans.ABBY_PRO ? statisticsMultiplans : []].sort((a, b) => b.weight - a.weight)
    const sortedStatisticsStart = [...statisticsStart, ...multiPlansPlan === AbbyPlans.ABBY_START ? statisticsMultiplans : []].sort((a, b) => b.weight - a.weight)

    return {
      highlights: [
        ...sortedStatisticsStart.slice(0, numberOfStartItemsToHighlight),
        ...sortedStatisticsPro.slice(0, numberOfProItemsToHighlight),
        ...sortedStatisticsBusiness.slice(0, numberOfBusinessItemsToHighlight),
      ],
      sales: {
        ...statisticsStart.length ? { [AbbyPlans.ABBY_START]: [...statisticsStart.slice(0, numberOfStartItemsForSalesPart), ...multiPlansPlan === AbbyPlans.ABBY_START ? statisticsMultiplans : []] } : {},
        ...statisticsPro.length ? { [AbbyPlans.ABBY_PRO]: [...statisticsPro.slice(0, numberOfProItemsForSalesPart), ...multiPlansPlan === AbbyPlans.ABBY_PRO ? statisticsMultiplans : []] } : {},
        ...statisticsBusiness.length ? { [AbbyPlans.ABBY_BUSINESS]: [...statisticsBusiness.slice(0, numberOfBusinessItemsForSalesPart), ...multiPlansPlan === AbbyPlans.ABBY_BUSINESS ? statisticsMultiplans : []] } : {},
      },
    }
  }

  selectOffer (plan: AbbyPlans.ABBY_START | AbbyPlans.ABBY_PRO | AbbyPlans.ABBY_BUSINESS) {
    const keysArray = Object.keys(this.checkBoxeValues)
    for (let i = 0; i < keysArray.length; i++) {
      this.checkBoxeValues[keysArray[i] as AbbyPlans.ABBY_START | AbbyPlans.ABBY_PRO | AbbyPlans.ABBY_BUSINESS] = false
    }
    this.checkBoxeValues[plan] = true
  }

  setFrequency (value: StripeProductFrequency) {
    this.frequency = value
  }

  async fetchReport () {
    try {
      const { data }: any = await this.$axios.get('/v2/statistics/personal-report')
      delete data.companyId
      delete data.createdAt
      delete data.id
      delete data.updatedAt
      this.report = data
    } catch (_) {
      //
    }
  }

  mounted () {
    setInterval(() => {
      const endDate = this.$dayjs('2024-12-02').endOf('d').toDate().getTime()
      const now = this.$dayjs().toDate().getTime()
      const diff = endDate - now

      let seconds = Math.floor(diff / 1000)
      let minutes = Math.floor(seconds / 60)
      let hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      seconds %= 60
      minutes %= 60
      hours %= 24

      this.promotionTimer = {
        days,
        hours,
        minutes,
        seconds,
        daysStr1: days.toString().padStart(2, '0').charAt(0),
        daysStr2: days.toString().padStart(2, '0').charAt(1),
        hoursStr1: hours.toString().padStart(2, '0').charAt(0),
        hoursStr2: hours.toString().padStart(2, '0').charAt(1),
        minutesStr1: minutes.toString().padStart(2, '0').charAt(0),
        minutesStr2: minutes.toString().padStart(2, '0').charAt(1),
        secondsStr1: seconds.toString().padStart(2, '0').charAt(0),
        secondsStr2: seconds.toString().padStart(2, '0').charAt(1),
      }
    }, 1000)
  }

  get isOpen () {
    return this.campaignsModal.blackFridayReport2024
  }

  set isOpen (value: boolean) {
    this.$store.dispatch('campaigns/closeModal', 'blackFridayReport2024')
    this.$help.sendChatEvent('BlackFridayReport2024Closed')
  }

  @Watch('campaignsModal.blackFridayReport2024')
  async handleIsOpen (value: boolean) {
    if (value) {
      try {
        this.loading = true
        this.frequency = StripeProductFrequency.YEAR
        await this.fetchReport()
        await this.$store.dispatch('upsells/setPromoCode', this.discount?.promoCode)
        this.checkBoxeValues[this.lowestSelectablePlan] = true
        await this.$stripe.loadStripe()
        await this.fetchSubscriptions()
        await this.$store.dispatch('company/fetchPlans')
        this.$ap.track(AbbyAction.BLACK_FRIDAY_REPORT_2024_OPENED)
      } catch (e) {
        //
      } finally {
        this.loading = false
      }
    }
  }

  close () {
    this.isOpen = false
  }
}
