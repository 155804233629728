import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { CreateTask, QueryTasks, ReadCountTasks, ReadTask, UpdateTask } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface TaskState {
  search: string;
  tasks: ReadTask[];
  isTaskModalOpened: boolean;
  range: string[],
  sidePanelTaskOpened: ReadTask[];
  counts: ReadCountTasks,
}

export const state = (): {
  search: string;
  counts: { all: number; late: number; today: number; completed: number; upcoming: number };
  isTaskModalOpened: boolean;
  range: any[];
  sidePanelTaskOpened: ReadTask[];
  tasks: any[]
} => ({
  search: '',
  tasks: [],
  range: [],
  isTaskModalOpened: false,
  sidePanelTaskOpened: [],
  counts: {
    all: 0,
    today: 0,
    upcoming: 0,
    late: 0,
    completed: 0,
  },
})

export const getters: GetterTree<TaskState, RootState> = {
  tasks: state => state.tasks,
  isTaskModalOpened: state => state.isTaskModalOpened,
  sidePanelTaskOpened: state => state.sidePanelTaskOpened,
  isAlreadyOpenedOnSidePanel: state => (id: string) => state.sidePanelTaskOpened.slice(-1)?.[0]?.id === id,
  search: state => state.search,
  range: state => state.range,
  counts: state => state.counts,
}

export const mutations: MutationTree<TaskState> = {
  SET_TASKS (state, value: ReadTask[]) {
    state.tasks = value
  },
  SET_SEARCH (state, value: string) {
    state.search = value
  },
  SET_RANGE (state, value: string[]) {
    state.range = value
  },
  SET_COUNTS (state, value: ReadCountTasks) {
    state.counts = value
  },
  SET_IS_TASK_MODAL_OPENED (state, value: boolean) {
    state.isTaskModalOpened = value
  },
  UPDATE_TASK (state, value: Partial<ReadTask>) {
    const taskIndex = state.tasks.findIndex(t => t.id === value.id)
    state.tasks.splice(taskIndex, 1, value as ReadTask)
  },
  SET_TASK_SIDE_PANEL_OPENED (state: TaskState, value: ReadTask[]) {
    state.sidePanelTaskOpened = value
  },
  RESET (_currentState: TaskState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<TaskState, RootState> = {
  async createTask ({ commit, getters, dispatch }, value: CreateTask) {
    const result = await this.$api.task.createTask(value)
    dispatch('fetchCounts')
    this.$busManager.emit('taskCreated', result)
    if (value.afterRank) {
      commit('SET_TASKS', [
        ...getters.tasks,
        result,
      ].sort((a, b) => a.rank?.localeCompare?.(b.rank)))
      return result
    }
    if (getters.range.length) {
      if (!this.$dayjs(result.dueDate).isBetween(getters.range[0], this.$dayjs(getters.range[1]).add(1, 'day'), 'day', '[)')) {
        return result
      }
    }
    commit('SET_TASKS', [
      ...getters.tasks,
      result,
    ])
    return result
  },
  setTasks ({ commit }, value: ReadTask[]) {
    commit('SET_TASKS', value)
  },
  setTaskModalOpened ({ commit }, value: boolean) {
    commit('SET_IS_TASK_MODAL_OPENED', value)
  },
  async updateTask ({ commit, getters, dispatch }, payload: UpdateTask & { id: string }) {
    const { id, ..._payload } = payload
    const result = await this.$api.task.updateTask(id, _payload)
    this.$busManager.emit('taskUpdated', result)

    if (getters.range.length) {
      if (!this.$dayjs(result.dueDate).isBetween(getters.range[0], this.$dayjs(getters.range[1]).add(1, 'day'), 'day', '[)')) {
        const data = getters.tasks.filter((task: ReadTask) => task.id !== result.id)
        commit('SET_TASKS', data)
        return result
      }
    }

    let data
    const isInTasks = !!getters.tasks.find((t: ReadTask) => t.id === result.id)
    if (!result.doneAt && isInTasks) {
      data = getters.tasks.map((task: ReadTask) => task.id === result.id ? result : task)
    } else if (!result.doneAt && !isInTasks) {
      data = [...getters.tasks, result].sort((a, b) => a.rank?.localeCompare?.(b.rank))
    } else {
      data = getters.tasks.filter((t: ReadTask) => t.id !== result.id)
    }

    commit('SET_TASKS', data)
    dispatch('fetchCounts')
    return result
  },
  setRange ({ commit }, value: string[]) {
    commit('SET_RANGE', value)
  },
  setSearch ({ commit }, value: string[]) {
    commit('SET_SEARCH', value)
  },
  async fetchTasks ({ commit, getters }, query: QueryTasks) {
    const results = await this.$api.task.getTasks({
      ...(getters.range?.length
        ? {
          range: getters.range,
        }
        : {}),
      ...query,
    })
    commit('SET_TASKS', query.lastTask
      ? [
        ...getters.tasks,
        ...results.data,
      ]
      : results.data)
  },
  async fetchCounts ({ commit }) {
    const results = await this.$api.task.countTasks()
    commit('SET_COUNTS', results)
  },
  openTaskSidePanel ({ commit, getters }, task: ReadTask) {
    const alreadyOpenedAsLastPanel = getters.isAlreadyOpenedOnSidePanel(task.id)
    if (alreadyOpenedAsLastPanel) { return }
    commit('SET_TASK_SIDE_PANEL_OPENED', [
      ...getters.sidePanelTaskOpened,
      task,
    ])
  },
  setSidePanelTaskOpened ({ commit }, value: ReadTask[]) {
    commit('SET_TASK_SIDE_PANEL_OPENED', value)
  },
  closeTaskSidePanel ({ commit, getters }, index: number) {
    commit('SET_TASK_SIDE_PANEL_OPENED', getters.sidePanelTaskOpened.filter((_: any, i: number) => i !== index))
  },
  async removeTask ({ commit, getters, dispatch }, task: ReadTask) {
    const tasks = [...getters.tasks]
    try {
      commit('SET_TASKS', getters.tasks.filter((t: ReadTask) => t.id !== task.id))
      this.$busManager.emit('taskRemoved', task)
      await this.$api.task.removeTask(task.id)
      dispatch('fetchCounts')
      this.$busManager.emit('taskRemoved', task)
    } catch (e) {
      commit('SET_TASKS', tasks)
      throw e
    }
  },
}
