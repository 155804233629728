export enum CurrencySymbol {
  EUR = '€',
  USD = '$',
  GBP = '£',
  CHF = 'CHF',
  AUD = 'A$',
  CAD = 'C$',
  JPY = '¥',
  CNH = 'CN¥',
  CZK = 'Kč',
  DKK = 'kr',
  BGN = 'лв',
  PLN = 'zł',
  HUF = 'Ft',
  RON = 'lei',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  SEK = 'kr',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NOK = 'kr',
  TRY = '₺',
  BRL = 'R$',
  HKD = 'HK$',
  ILS = '₪',
  INR = '₹',
  KRW = '₩',
  MXN = 'Mex$',
}
