import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { PersonaFeature } from '@abby/core-legacy'

export interface Signup2State {
  utm: {
    utmSource?: string,
    utmMedium?: string,
    utmCampaign?: string,
    utmContent?: string,
    utmTerm?: string,
    utmCid?: string,
    utmCgid?: string,
  },
  user: {
    email?: string
    firstname?: string
    lastname?: string
  } | null
  company: {
    name?: string,
    legalStatus?: string,
    activityId?: number,
  }
  persona: {
    mainFeature?: PersonaFeature
  }
}

export const state = (): Signup2State => ({
  utm: {},
  user: {},
  company: {},
  persona: {},
})

export const getters: GetterTree<Signup2State, Signup2State> = {
  utm: state => state.utm,
  user: state => state.user,
  company: state => state.company,
  persona: state => state.persona,
}

export const mutations: MutationTree<Signup2State> = {
  SET_UTM (_currentState: Signup2State, utm: Signup2State['utm']) {
    _currentState.utm = utm
  },
  SET_USER (_currentState: Signup2State, user: Signup2State['user']) {
    _currentState.user = user
  },
  SET_COMPANY (_currentState: Signup2State, company: Signup2State['company']) {
    _currentState.company = company
  },
  SET_PERSONA (_currentState: Signup2State, persona: Signup2State['persona']) {
    _currentState.persona = persona
  },
  RESET (_currentState: Signup2State) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<Signup2State, Signup2State> = {
  setUTM ({ commit }, utm: Signup2State['utm']) {
    commit('SET_UTM', utm)
  },
  setUser ({ commit }, user: Signup2State['user']) {
    commit('SET_USER', user)
  },
  setCompany ({ commit }, company: Signup2State['company']) {
    commit('SET_COMPANY', company)
  },
  setPersona ({ commit }, persona: Signup2State['persona']) {
    commit('SET_PERSONA', persona)
  },
  reset ({ commit }) {
    commit('RESET')
  },
}
