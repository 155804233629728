import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { OrganizationMapper } from '~/services/client/organization/mappers/Organization.mapper'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'

export const useOpenOrganizationDetailsSidePanel = () => {
  const organizationRepository = useOrganizationRepository()
  const billingRepository = useBillingRepository()
  const clientStore = useClientStore()

  return async (organizationId: string, options?: any) => {
    const readOrganization = await organizationRepository.getOrganizationById(organizationId)
    const organization = OrganizationMapper.toDomain(readOrganization)

    const billingCustomer = await billingRepository.fetchCustomerFromOrganization(organizationId)
    organization.customerId = billingCustomer.id
    clientStore.toggleOrganizationDetailsSidePanel(true, organization, options)
  }
}
