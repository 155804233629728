import { PlanFeature } from '@abby/core-legacy'
import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { usePlanManager } from '~/composables/shared/manager/usePlanManager'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'

export const useMarkAsFinalized = () => {
  const { i18n } = useContext()
  const confirmManager = useConfirmManager()
  const planManager = usePlanManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    if (!planManager.hasAccessToFeature(PlanFeature.BILLING_VAT)) {
      return planManager.openPlansModal(PlanFeature.BILLING_VAT)
    }

    const confirm = await confirmManager.ask({
      title: i18n.t('confirmBilling.MARK_AS_FINALIZED.title') as string,
      content: i18n.t('confirmBilling.MARK_AS_FINALIZED.content') as string,
      confirmText: i18n.t('confirmBilling.MARK_AS_FINALIZED.button') as string,
      confirmButton: { color: 'primary', depressed: true },
    })

    if (!confirm) {
      return
    }
    await billingRepository.markAsFinalized(billing.id)
  }
}
