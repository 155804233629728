import { Context } from '@nuxt/types'
import { sdk } from '@abby/sdk'
import debounce from 'lodash/debounce'
import { configureAuth } from '~/plugins/auth/authToken'

// Screen dimension state management
let cachedScreenHeight: number = window.innerHeight
let cachedScreenWidth: number = window.innerWidth

// Debounced dimension update function
const updateScreenDimensions = debounce(() => {
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
}, 250)

export default (context: Context, inject: any) => {
  const { handleTokenRefresh, logout, tokenManager } = configureAuth(context)

  // Set initial dimensions
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth

  // Add event listener for resize with debounce
  window.addEventListener('resize', updateScreenDimensions)

  // Request interceptor
  sdk.client.interceptors.request.use(async (request: Request) => {
    // Add screen dimensions and support headers
    request.headers.set('screen-height', cachedScreenHeight.toString())
    request.headers.set('screen-width', cachedScreenWidth.toString())
    request.headers.set('support', 'web-app')

    // Handle authentication
    try {
      const token = await handleTokenRefresh()
      if (token) {
        request.headers.set('Authorization', `Bearer ${token}`)
      }
      return request
    } catch (e) {
      await logout()
      throw e
    }
  })

  sdk.client.interceptors.response.use((response: Response) => {
    if (response.status === 401) {
      logout()
    }
    return response
  })

  sdk.client.setConfig({
    baseUrl: '/api',
    throwOnError: true,
  })

  inject('sdk', {
    ...sdk,
    resetToken: () => tokenManager.setToken(null),
    setToken: (token: string) => tokenManager.setToken(token),
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
  interface Context {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}

declare module 'vuex' {
  interface Store<S> {
    $sdk: typeof sdk & { resetToken: () => void, setToken: (token: string) => void };
  }
}
