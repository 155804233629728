import { ActionTree, GetterTree } from 'vuex'
import type { IActivity, IParentActivity } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ActivitiesState {
  activities: Array<IActivity>;
  parentActivities: Array<IParentActivity>;
}

export const state = (): ActivitiesState => ({
  activities: [],
  parentActivities: [],
})

export const getters: GetterTree<ActivitiesState, RootState> = {
  activities (state: ActivitiesState) {
    return state.activities
  },
  parentActivities (state: ActivitiesState) {
    return state.parentActivities
  },
}

export const mutations = {
  SET_ACTIVITIES (state: ActivitiesState, activities: Array<IActivity>): void {
    state.activities = activities
  },
  SET_PARENT_ACTIVITIES (state: ActivitiesState, parentActivities: Array<IParentActivity>): void {
    state.parentActivities = parentActivities
  },
  RESET (_currentState: ActivitiesState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<any, any> = {
  async fetchActivities ({ commit }) {
    const activities = await this.$api.activity.getAll()
    commit('SET_ACTIVITIES', activities)
  },
  async fetchParentActivities ({ commit }) {
    const parentActivities = await this.$api.activity.getAllParentActivities()
    commit('SET_PARENT_ACTIVITIES', parentActivities)
  },
}
