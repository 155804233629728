import type { AxiosInstance } from 'axios';

type Axios = AxiosInstance & {
  $get: AxiosInstance['get'];
  $put: AxiosInstance['put'];
  $patch: AxiosInstance['patch'];
  $post: AxiosInstance['post'];
  $delete: AxiosInstance['delete'];
};

export class Resource {
  protected http: Axios;
  constructor(axios: Axios) {
    this.http = axios;
  }
}
