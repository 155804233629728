
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import AbbyAlertManager from '~/components/abby/managers/AbbyAlertManager.vue'

@Component({
  middleware: ['redirection'],
  components: {
    AbbyAlertManager,
  },
})
export default class ErrorLayout extends Vue {
  head () {
    return this.$brandManager.head()
  }

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;
}

