import { Time, ReadOrganizationListItem } from '@abby/shared'
import { OrganizationItem } from '~/services/client/organization/entities/OrganizationItem.entity'

export class OrganizationItemMapper {
  static toDomain (result: ReadOrganizationListItem) {
    return OrganizationItem.create({
      id: result.id,
      name: result.name,
      countContacts: result.countContacts,
      hasBillings: result.hasBillings,
      test: result.test,
      createdAt: Time.toDate(result.createdAt),
      archivedAt: result.archivedAt ? Time.toDate(result.archivedAt) : undefined,
    })
  }
}
