import { defineStore, storeToRefs } from 'pinia'
import { ref } from 'vue'
import { IBilling } from '@abby/core-legacy'
import { BillingDetails } from '~/services/billing/_common/entities/BillingDetails.entity'
import { BillingPayment } from '~/services/billing/_common/entities/BillingPayment.entity'

export const useBillingStore = () => {
  const useBillingStore = defineStore('billing', () => {
    const isMarkAsPaidModalOpened = ref(false)
    const selectedBilling = ref<IBilling>()
    const selectedBillingDetails = ref<BillingDetails>()
    const selectedBillingPayments = ref<BillingPayment[]>([])

    const toggleMarkAsPaidModal = (value: boolean, params?: {billing?: IBilling, details?: BillingDetails, payments: BillingPayment[] }) => {
      selectedBilling.value = params?.billing
      selectedBillingDetails.value = params?.details
      selectedBillingPayments.value = params?.payments || []
      isMarkAsPaidModalOpened.value = value
    }

    return {
      isMarkAsPaidModalOpened,
      selectedBilling,
      selectedBillingDetails,
      selectedBillingPayments,
      toggleMarkAsPaidModal,
    }
  })
  const billingStore = useBillingStore()
  const {
    isMarkAsPaidModalOpened,
    selectedBilling,
    selectedBillingDetails,
    selectedBillingPayments,
  } = storeToRefs(billingStore)

  return {
    isMarkAsPaidModalOpened,
    selectedBilling,
    selectedBillingDetails,
    selectedBillingPayments,
    toggleMarkAsPaidModal: billingStore.toggleMarkAsPaidModal,
  }
}
