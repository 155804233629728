import { AbbyAmount } from '~/services/abby/valueObjects/AbbyAmount.valueObject'

interface ClientStatisticsProps {
    amountLate: AbbyAmount;
    amountPaid: AbbyAmount;
    countLate: number;
    revenuesData: number[];
}

export class ClientStatistics {
    readonly amountLate: AbbyAmount;
    readonly amountPaid: AbbyAmount;
    readonly countLate: number;
    readonly revenuesData: number[];

    private constructor (props: ClientStatisticsProps) {
      this.amountLate = props.amountLate
      this.amountPaid = props.amountPaid
      this.countLate = props.countLate
      this.revenuesData = props.revenuesData
    }

    static create (props: ClientStatisticsProps): ClientStatistics {
      return new ClientStatistics(props)
    }
}
