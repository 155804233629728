
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-text-field',
  components: {
    ValidationProvider,
  },
})
export default class ASTextField extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop()
  dataTestid?: string;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ default: 'primary' })
  tooltipIconColor!: string;

  @Prop({ default: 'help_outline' })
  tooltipIcon!: string;

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get isProviderDisabled () {
    return (!Object.keys(this.rules).length || this.$attrs.disabled)
  }

  @ModelSync('value', 'input')
  readonly innerValue: any;
}
