
import { Action, Component, Getter, Vue, Watch } from 'nuxt-property-decorator'
import { SegmentEvent } from '@abby/core-legacy'
import { Action as AbbyAction } from '@abby/shared'
import { v1 as uuidv1 } from 'uuid'
import AbbyAlertManager from '~/components/abby/managers/AbbyAlertManager.vue'
import ConfirmModal from '~/components-legacy/modals/ConfirmModal.vue'

@Component({
  middleware: ['redirection', 'register'],
  components: {
    AbbyAlertManager,
    ConfirmModal,
  },
})
export default class Auth2 extends Vue {
  @Action('auth/setFatherSponsorshipCode') setFatherSponsorshipCode!: (code: string) => Promise<boolean>;

  loadGTM () {
    if (process.env.NODE_ENV !== 'production') { return }
    const script = document.createElement('script')
    script.async = true
    script.id = 'gtm'
    script.src = '/scripts/mtg-app.js'
    document.head.appendChild(script)
  }

  triggerPageView () {
    if (!process.browser) { return }
    if (!this.$cookies.get('ay_anonymous_id')) {
      // @ts-ignore
      this.$cookies.set('ay_anonymous_id', uuidv1(), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
        domain: process.env.NODE_ENV === 'development' ? 'localhost' : '.abby.fr',
      })
    }
    this.$ap.track(AbbyAction.PAGE_VIEWED)
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange () {
    this.$ap.sendToGTM({
      event: SegmentEvent.PAGE_VIEW,
    })
    this.triggerPageView()
  }

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  mounted () {
    this.$help.init()
    this.loadGTM()
    const fatherSponsorshipCode = this.$route.query.code as string
    if (fatherSponsorshipCode) { // sponsorship
      localStorage.setItem('fatherSponsorshipCode', fatherSponsorshipCode || '')
    }
    // psap = personaSAP pour l'affichage de l'offre SAP
    const personaSAP = this.$route.query.psap as string
    if (personaSAP) {
      localStorage.setItem('psap', personaSAP?.toString())
    }
  }

  head () {
    return this.$brandManager.head()
  }
}

