import { defineStore, storeToRefs } from 'pinia'
import { ref, computed } from 'vue'
import type { ReadCountSignature } from '@abby/core-legacy'
import { useContext } from '@nuxtjs/composition-api'

export const useBillingSignatureStore = () => {
  const useBillingSignatureStore = defineStore('billingSignature', () => {
    const { $api } = useContext()

    const counts = ref<ReadCountSignature>({
      debits: 0,
      credits: 0,
      remaining: 0,
    })

    const getCounts = computed(() => counts.value)

    const setCounts = (value: ReadCountSignature) => {
      counts.value = value
    }

    const reset = () => {
      counts.value = {
        debits: 0,
        credits: 0,
        remaining: 0,
      }
    }

    // TODO : à déplacer dans un repository le jour où il existera
    const fetchCounts = async () => {
      const response = await $api.signature.count()
      setCounts(response)
    }

    return {
      counts,
      getCounts,
      setCounts,
      reset,
      fetchCounts,
    }
  })

  const billingSignatureStore = useBillingSignatureStore()
  const { setCounts, reset, fetchCounts } = billingSignatureStore
  const { counts, getCounts } = storeToRefs(billingSignatureStore)

  return {
    counts,
    getCounts,
    setCounts,
    reset,
    fetchCounts,
  }
}
