import { useContext } from '@nuxtjs/composition-api'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useCopyTiersPrestationError = () => {
  const { $clipboard } = useContext()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem) => {
    const { value: billingConfiguration } = billingRepository.fetchConfiguration()
    const message = `Bonjour, \n
        Nous avons une erreur ERR_PARTICULIER_INCONNU avec "idClient" : "${billing.customer.tiersPrestation?.idClient}".\nLe client ID de l’OSP est : clientId" : "${billingConfiguration.tiersPrestationCredentials?.clientId}",\n
        Le particulier a correctement activé son compte.
        \nMerci de résoudre le problème.
        \nBien cordialement`
    await $clipboard.copy(message)
    alertManager.success('Le message a été copié dans le presse-papier')
  }
}
