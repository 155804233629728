
import { Component, Vue, Getter, Action, Watch } from 'nuxt-property-decorator'
import { GuideLevelItem, IGuideLevel, SegmentEvent, CampaignType, IGuideStep } from '@abby/core-legacy'
import { WebinarType } from '@abby/shared'
import Link from '~/components-legacy/utils/Link.vue'
import CalendarEvent from '~/components-legacy/cards/CalendarEvent.vue'

@Component({
  components: { Link, CalendarEvent },
})
export default class GuideMenu extends Vue {
  @Getter('guide/countNotCompleted') countNotCompleted!: number
  @Getter('guide/countCompleted') countCompleted!: number
  @Getter('guide/guideConfig') guideConfig!: () => IGuideLevel[]
  @Getter('webinar/webinar')
  webinar!: WebinarType

  @Getter('webinar/queryLoading')
  webinarLoading!: boolean

  @Action('guide/setLevelToOpen') setLevelToOpen!: (value: GuideLevelItem | null) => void

  async handleMenu (value: boolean) {
    if (!value) { return }
    await this.$store.dispatch('guide/fetchGuideSteps')
  }

  get categories () {
    return [
      {
        title: 'Facturer mes clients',
        level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
        status: this.remaining(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY),
        ended: this.remaining(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY) === 'Terminé',
        progress: this.progressionPercentage(GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY),
      },
      {
        title: 'Faire sa comptabilité',
        level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
        status: this.remaining(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY),
        ended: this.remaining(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY) === 'Terminé',
        progress: this.progressionPercentage(GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY),
      },
      {
        title: 'S’organiser et être productif',
        level: GuideLevelItem.GET_ORGANIZE_EASILY,
        status: this.remaining(GuideLevelItem.GET_ORGANIZE_EASILY),
        ended: this.remaining(GuideLevelItem.GET_ORGANIZE_EASILY) === 'Terminé',
        progress: this.progressionPercentage(GuideLevelItem.GET_ORGANIZE_EASILY),
      },
    ]
  }

  get totalSteps () {
    let total = 0
    const guideConfig = this.guideConfig()
    for (let i = 0; i < guideConfig.length; i += 1) {
      const guideLevel = guideConfig[i]
      total += guideLevel.steps.length
    }
    return total
  }

  get totalStepsCompleted () {
    let total = 0
    const guideConfig = this.guideConfig()
    for (let i = 0; i < guideConfig.length; i += 1) {
      const guideLevel = guideConfig[i]
      total += guideLevel.steps.filter(s => s.completedAt).length
    }
    return total
  }

  remaining (guideLevelItem: GuideLevelItem) {
    const guideLevel = this.guideConfig().find(g => g.level === guideLevelItem)
    if (!guideLevel) { return }
    if (guideLevel.steps.filter((s: IGuideStep) => s.completedAt).length === 0) { return 'Commencer' }
    if (guideLevel.steps.filter((s: IGuideStep) => s.completedAt).length === guideLevel.steps.length) { return 'Terminé' }
    return `${guideLevel.steps.filter(s => s.completedAt).length} / ${guideLevel.steps.length}`
  }

  progressionPercentage (guideLevelItem: GuideLevelItem) {
    const guideLevel = this.guideConfig().find((g: IGuideLevel) => g.level === guideLevelItem)
    if (!guideLevel) { return }
    return (guideLevel.steps.filter((s: IGuideStep) => s.completedAt).length / guideLevel.steps.length) * 100
  }

  openGuide (guideLevelItem: GuideLevelItem) {
    this.setLevelToOpen(guideLevelItem)
    if (this.$router.currentRoute.path !== '/guide') {
      this.$router.push('/guide')
    }
  }

  openHelpcenter () {
    this.$help.toggleHelpCenter(true)
  }

  openHelp () {
    window?.open('https://aide.abby.fr/fr/', '_blank')
  }
}
