import { UpdateOrganizationNotes } from '@abby/shared'
import { OrganizationMapper } from '~/services/client/organization/mappers/Organization.mapper'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useUpdateOrganizationNotes = () => {
  const organizationRepository = useOrganizationRepository()
  const clientStore = useClientStore()
  const alertManager = useAlertManager()

  return async (id: string, notes: UpdateOrganizationNotes) => {
    const editedOrganization = await organizationRepository.updateOrganizationNotes(id, notes)
    const organization = OrganizationMapper.toDomain(editedOrganization)
    if (clientStore.selectedOrganization.value) {
      clientStore.selectedOrganization.value.notes = organization.notes
    }

    alertManager.success('Les notes de l\'organisation ont bien été mises à jour')
    return editedOrganization
  }
}
