import currencyJs from 'currency.js';
import { CurrencyCode } from '../enums';
import { currencyPattern, fixNumber, roundDecimal } from './prices.valueObject';

type MoneyProps = {
  amountInCents: number;
  currency?: CurrencyCode;
};

type MoneySnapshot = {
  amountInCents: number;
  currency?: CurrencyCode;
};

export class Money {
  #amountInCents: number;
  #currency: CurrencyCode;

  public get value(): number {
    return this.#amountInCents;
  }

  public get currency(): CurrencyCode {
    return this.#currency;
  }

  private constructor(props: MoneyProps) {
    this.#amountInCents = props.amountInCents;
    this.#currency = props.currency || CurrencyCode.EUR;
  }

  /**
   * Cette méthode permet de représenter une donnée de type Money
   * Elle prend en paramètre un montant exprimé en centime
   * et une currency qui vaut par défaut 'EUR'
   *
   * @public
   * @static
   * @param {MoneyProps} props { amountInCents: number; currency: Currency }
   * @returns {Money}
   */
  public static create(props: MoneyProps): Money {
    return new Money(props);
  }

  public snapshot(): MoneySnapshot {
    return {
      amountInCents: this.#amountInCents,
      currency: this.#currency,
    };
  }

  public add(value: number): Money {
    return Money.create({
      amountInCents: roundDecimal(this.#amountInCents + value),
      currency: this.#currency,
    });
  }

  public substract(value: number): Money {
    return Money.create({
      amountInCents: roundDecimal(this.#amountInCents - value),
      currency: this.#currency,
    });
  }

  public multiplyBy(factor: number): Money {
    return Money.create({
      amountInCents: roundDecimal(this.#amountInCents * factor),
      currency: this.#currency,
    });
  }

  public divideBy(divisor: number): Money {
    return Money.create({
      amountInCents: roundDecimal(this.#amountInCents * divisor),
      currency: this.#currency,
    });
  }

  public format(currency = CurrencyCode.EUR, options = { keepExraDecimals: false }): string {
    const { keepExraDecimals } = options;
    const pattern = currencyPattern[this.#currency || currency];
    const precision = keepExraDecimals ? this.getPrecisionFromCents() : 2;
    const currencyValue = currencyJs(fixNumber(this.#amountInCents / 100), { fromCents: false, ...pattern, precision: precision || pattern.precision });
    return currencyValue.format();
  }

  public round(precision = 2): Money {
    const _precision = precision - 2;
    return Money.create({
      amountInCents: roundDecimal(this.#amountInCents, _precision),
      currency: this.#currency,
    });
  }

  private getPrecisionFromCents(): number {
    // Calculer le montant en euros
    const value = fixNumber(this.#amountInCents / 100);
    // Formater le montant en tant que chaîne de caractères
    const amountAsString: string = value.toString();

    // Trouver l'index du point décimal dans la chaîne
    const indexOfDecimalDot: number = amountAsString.indexOf('.');

    if (indexOfDecimalDot === -1) {
      // S'il n'y a pas de point décimal, cela signifie qu'il n'y a pas de chiffres après la virgule
      return 0;
    }
    // Calculer le nombre de chiffres après la virgule
    return amountAsString.length - indexOfDecimalDot - 1;
  }
}
