
import { Action, Component, Getter, Prop, Ref, Vue, Watch } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import {
  AbbyPlans,
  ABGroupPricing,
  CampaignType,
  CreateSponsorship,
  getProductName,
  isProductRecurring,
  ReadCompanyStripeProduct,
  ReadStripeCheckoutAmount,
  RegisteredType,
  SegmentEvent,
  sleep,
  StripeProductFrequency,
  StripeProductType,
} from '@abby/core-legacy'
import { Action as AbbyAction } from '@abby/shared'
import ASForm from '~/components-legacy/inputs/ASForm.vue'
import AsTextField from '~/components-legacy/inputs/ASTextField.vue'
import Link from '~/components-legacy/utils/Link.vue'
import { IAuthUser } from '~/store/auth'
import { useSponsorshipStore, SponsorshipStore } from '~/composables/sponsorship/useSponsorshipPinia.store'
import { usePromotionManager } from '~/composables/marketing/usePromotionManager'
import { Promotion } from '~/services/marketing/interfaces/Promotion'

@Component({
  computed: {
    ABGroupPricing () {
      return ABGroupPricing
    },
  },
  components: {
    Link,
    AsTextField,
    ASForm,
  },
  setup () {
    const sponsorshipStore = useSponsorshipStore()
    const { getActivePromotion } = usePromotionManager()
    return {
      sponsorshipStore,
      getActivePromotion,
    }
  },
})
export default class NewPaymentCard extends Vue {
  private sponsorshipStore!: SponsorshipStore;

  @Prop()
  product?: StripeProductType

  @Prop()
  withCE?: boolean

  @Prop({ default: true })
  showCoupon?: boolean

  @Prop({ default: false })
  disableCoupon?: boolean

  @Prop()
  abGroup?: ABGroupPricing

  @Prop()
  source?: string

  @Prop()
  options?: StripeProductType[] | undefined

  @Prop({ required: false })
  frequency?: StripeProductFrequency

  @Prop({ required: false, default: true })
  useDefaultPaymentMethod!: boolean

  @Action('company/fetchPaymentMethods') fetchPaymentMethods!: () => Promise<void>;
  @Action('company/setPlans') setPlans!: (value: ReadCompanyStripeProduct[]) => Promise<void>;
  @Getter('company/paymentMethods') paymentMethods!: any[] | null;
  @Getter('company/plans') plans!: ReadCompanyStripeProduct[];
  @Getter('auth/hasGodFather') hasGodFather!: boolean
  @Getter('auth/user') user!: IAuthUser

  @Ref('form') form!: InstanceType<typeof ValidationProvider>;

  usePayment = 'default'
  isPaymentMethodSaved = true
  showPromoCode = false
  promoCodeLoading = false
  paymentMethodUsed = null
  promoCodeSaved = ''
  displayPromoCode = ''

  checkoutLoading: boolean = true

  showRecurringPaymentInformation = false
  promoCodeError: string = ''

  cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#26385e',
        fontWeight: 500,
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#8596ad',
        },
      },
      invalid: {
        color: '#E94E6E',
      },
    },
  }

  getActivePromotion!: Function

  initialLoading = false
  showThanks = false
  card: any;
  elements: any;
  loading = false
  paymentDone = false
  stripeCardComplete = false;
  stripeElementLoading = true;
  clientSecret = ''
  stripeRequestPayment = false
  checkout: ReadStripeCheckoutAmount = {
    proratedAmount: 0,
    proratedAmountWithoutTax: 0,
    subTotalAmountWithoutTax: 0,
    subTotalAmount: 0,
    totalAmountWithoutTax: 0,
    totalAmount: 0,
    discount: null,
    coupon: null,
  }

  togglePromoCode () {
    this.showPromoCode = !(this.showPromoCode || this.promoCodeError)
    if (!this.showPromoCode) {
      this.removePromoCode()
    }
  }

  get isValid () {
    return !this.initialLoading
  }

  get isRecurring () {
    if (!this.product) { return false }
    return isProductRecurring(this.product)
  }

  get hasActionsSlot () {
    return this.$scopedSlots.actions
  }

  get activePromotion (): Promotion | undefined {
    return this.getActivePromotion()
  }

  get promoCode () {
    return this.$store.getters['upsells/promoCode']
  }

  get stripe () {
    return this.$stripe.stripe
  }

  set promoCode (value: string) {
    this.$store.dispatch('upsells/setPromoCode', value)
  }

  get defaultPaymentMethod () {
    return this.paymentMethods?.[0]
  }

  get isAuthorizedToSetSponsorshipCode () {
    return (this.$dayjs().diff(this.user.createdAt, 'd') <= 7) && !this.hasGodFather
  }

  initPaymentRequest () {
    return this.stripe.paymentRequest({
      country: 'FR',
      currency: 'eur',
      total: {
        label: getProductName(this.product),
        amount: this.checkout.totalAmount || this.checkout.subTotalAmount,
      },
    })
  }

  async verifyPromoCodeIsValid (promoCode: string, params?: { frequency?: StripeProductFrequency }) {
    try {
      let coupon = await this.$api.stripe.getCoupon(promoCode, params)
      let routingCoupon = null
      if (coupon?.metadata?.routing) {
        routingCoupon = coupon?.metadata[`frequency_${params?.frequency}_${this.product}`] || coupon?.metadata[`frequency_${params?.frequency}`]
        coupon = await this.$api.stripe.getCoupon(routingCoupon, params)
      }
      const allProducts = await this.$api.stripeProduct.getAll()
      const product = allProducts?.find(p => p.id === this.product)

      if (coupon?.applies_to?.products.includes(product?.productId || '')) {
        this.promoCodeError = ''
        return routingCoupon || this.promoCode
      } else {
        this.promoCodeError = 'Le coupon n\'est pas valide pour ce produit'
        // this.$alertsManager.warning('Le coupon n\'est pas valide pour ce produit')
        return null
      }
    } catch (e: any) {
      this.promoCodeError = 'Le coupon renseigné n\'existe pas'
      return null
    }
  }

  async setCheckout (avoidSetPromoCode = false) {
    try {
      this.checkoutLoading = true
      if (!avoidSetPromoCode) {
        await this.setPromoCode()
      }
      this.checkout = await this.$api.stripe.getProductAmount(this.product, {
        promoCode: this.promoCodeSaved,
        abGroup: this.abGroup,
        frequency: this.isRecurring ? this.frequency || StripeProductFrequency.YEAR : undefined,
        options: this.options,
      })
      await this.handleInit()
    } catch (_) {
      //
    } finally {
      this.checkoutLoading = false
    }
  }

  get hasDouble2Offer () {
    return !this.$campaignsManager.isDisplayable(CampaignType.DOUBLE_2) && (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE || this.$planManager.isTrial())
  }

  async setPromoCode () {
    if (this.product && [StripeProductType.ABBY_CE].includes(this.product)) {
      this.$store.dispatch('upsells/setPromoCode', null)
      return
    }
    let affiliationUrl: any = {}
    try {
      affiliationUrl = JSON.parse(this.user.affiliationUrl || '{}') || {}
    } catch (_) {
      //
    }

    if (!this.promoCode && affiliationUrl?.partnerCode) {
      this.$store.dispatch('upsells/setPromoCode', affiliationUrl?.partnerCode)
    } else if (!this.promoCode && this.activePromotion && this.$dayjs().isBetween(this.activePromotion.startDate, this.activePromotion.endDate, 'day', '[]')) {
      this.$store.dispatch('upsells/setPromoCode', this.activePromotion.promoCode)
    }

    /* else if (!this.promoCode && this.hasGodFather) {
      this.$store.dispatch('upsells/setPromoCode', 'MERCIPARRAIN')
    } else if (!this.promoCode) {
      this.$store.dispatch('upsells/setPromoCode', 'BIENVENUE')
    } */
    await this.applyPromoCode()
  }

  @Watch('frequency')
  async frequencyHandler () {
    await this.setCheckout()
  }

  @Watch('product')
  async productHandler () {
    await this.setCheckout()
  }

  @Watch('options')
  async optionsHandler () {
    await this.setCheckout()
  }

  async mounted () {
    this.initialLoading = true
    await this.$stripe.loadStripe()
    if (this.isRecurring) {
      await this.fetchPaymentMethods()
      this.usePayment = this.defaultPaymentMethod ? 'default' : 'custom'
      this.stripeElementLoading = false
      this.clientSecret = await this.$api.stripe.createPaymentMethod({
        productId: this.product,
        frequency: this.frequency || StripeProductFrequency.YEAR,
      })
      await this.setCheckout()
      this.initialLoading = false
      return
    }

    // One time payment handler
    if (!this.useDefaultPaymentMethod) {
      this.clientSecret = await this.$api.stripe.createPaymentMethod()
    } else {
      try {
        await this.fetchPaymentMethods()
        this.usePayment = this.defaultPaymentMethod ? 'default' : 'custom'
      } catch (e: any) {
        this.$alertsManager.autoError(e as any)
      }

      try {
        const { clientSecret } = await this.$api.stripe.createPayment(this.product, { options: this.options })
        this.clientSecret = clientSecret
        await this.setCheckout()
      } catch (e: any) {
        this.$alertsManager.autoError(e as any)
      }
    }

    this.handleInit()
    this.initialLoading = false
  }

  async handleInit () {
    if (!this.stripe || !this.clientSecret) {
      this.$alertsManager.error('Un problème est survenue avec notre partenaire de paiement.')
      return
    }

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecret,
    })
    this.card = this.elements.create('payment', {
      wallets: { applePay: 'never', googlePay: 'never' },
      paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'paypal'],

    })

    try {
      this.card.mount(this.$refs.card)
    } catch {}

    this.card.addEventListener('ready', () => {
      this.stripeElementLoading = false
    })
    this.card.addEventListener('change', (event: any) => {
      this.stripeCardComplete = event.complete
      this.paymentMethodUsed = event.value.type
    })
    this.card.addEventListener('focus', () => {
      this.usePayment = 'custom'
    })

    if (!this.useDefaultPaymentMethod && !this.isRecurring) {
      return
    }

    // Google pay & Apple Pay
    try {
      const paymentRequest = await this.initPaymentRequest()
      const canMakePayment = await paymentRequest?.canMakePayment()
      if (canMakePayment?.applePay || canMakePayment?.googlePay) {
        const prButton = this.elements.create('paymentRequestButton', {
          paymentRequest,
          style: {
            paymentRequestButton: {
              height: '44px',
              borderRadius: '8px',
            },
          },
        })
        try {
          prButton.mount('#payment-request-button')
        } catch (e: any) {}

        prButton.addEventListener('ready', () => {
          this.stripeRequestPayment = true
        })

        paymentRequest?.on('paymentmethod', async (ev) => {
          if (this.isRecurring) {
            this.loading = true
            try {
              const result = await this.stripe.confirmCardSetup(this.clientSecret, {
                payment_method: ev.paymentMethod.id,
              })
              if (!result || result?.error || result?.setupIntent?.status !== 'succeeded') {
                this.$alertsManager.error('Une erreur est survenue')
                ev.complete('fail')
                return
              }
              await this.$api.stripe.createPayment(this.product, { promoCode: this.promoCode, paymentMethod: result.setupIntent.payment_method as string, options: this.options })
              ev.complete('success')
              this.paymentDone = true
              this.$emit('succeed', this.product)
              this.showThanks = true
              this.addPlan(this.product)
              this.$alertsManager.success('Vous avez bien procédé au paiement')
            } catch (e: any) {
              this.$alertsManager.error('Une erreur est survenue')
              ev.complete('fail')
            } finally {
              this.loading = false
            }
            return
          }

          const { paymentIntent, error: confirmError } = await this.stripe.confirmCardPayment(
            this.clientSecret,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false },
          )

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail')
            this.$alertsManager.error(confirmError?.message || 'Une erreur est survenue')
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success')
            // Check if the PaymentIntent requires any actions and if so let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: `paymentIntent.status === "requires_source_action"`.
            if (paymentIntent?.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await this.stripe.confirmCardPayment(this.clientSecret)
              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                this.$alertsManager.error(error?.message || 'Une erreur est survenue')
              } else {
                this.paymentDone = true
                this.$emit('succeed', this.product)
                this.showThanks = true
                this.addPlan(this.product)
                this.$alertsManager.success('Vous avez bien procédé au paiement')
              }
            } else {
              // The payment has succeeded.
              this.paymentDone = true
              this.$emit('succeed', this.product)
              this.showThanks = true
              this.addPlan(this.product)
              this.$alertsManager.success('Vous avez bien procédé au paiement')
            }
          }
        })
      }
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }

  async savePaymentMethod () {
    this.loading = true
    try {
      const result = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      })
      this.$emit('save-succeed', result)
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.loading = false
    }
  }

  async waitUntilClientSecret () {
    return await new Promise((resolve) => {
      setTimeout(async () => {
        if (this.clientSecret) {
          return resolve(true)
        }
        return resolve(await this.waitUntilClientSecret())
      }, 100)
    })
  }

  async makePaymentOneTime () {
    if (this.usePayment === 'default') {
      if (!this.defaultPaymentMethod?.id) {
        this.usePayment = 'custom'
        this.$alertsManager.error('Nous ne parvenons pas à utiliser le moyen de paiement par défaut, essayer avec une nouvelle carte')
        this.loading = false
        return
      }

      return await this.stripe.confirmCardPayment(this.clientSecret, {
        payment_method: this.defaultPaymentMethod.id,
      })
    } else {
      return await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: {
          save_payment_method: this.isPaymentMethodSaved && this.usePayment === 'custom',
        },
      })
    }
  }

  async makePaymentSubscription () {
    if (this.paymentMethodUsed === 'paypal') {
      const query = {
        frequency: this.frequency,
        abGroup: this.abGroup,
        product: this.product,
        options: this.options,
        ...(this.promoCodeSaved ? { promoCode: this.promoCodeSaved } : {}),
      }

      function serialize (obj: any) {
        const str = '?' + Object.keys(obj).reduce(function (a, k) {
          if (k && obj[k]) {
          // @ts-ignore
            a.push(k + '=' + encodeURIComponent(obj[k]))
          }
          return a
        }, []).join('&')
        return str
      }

      const _query = serialize(query)

      await this.stripe.confirmPayPalSetup(this.clientSecret, {
        return_url: (new URL(`/confirm-paypal-payment/${_query === '?' || _query === '' ? '' : _query || ''}`, window.location.href)).toString(),
        mandate_data: {
          customer_acceptance: {
            type: 'online',
            online: {
              infer_from_client: true,
            },
          },
        },
      })
      return
    }
    if (this.usePayment !== 'default') {
      const result = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
      })

      if (!result || result?.error || result?.setupIntent?.status !== 'succeeded') {
        if (result?.error?.code === 'card_declined') {
          throw new Error('card_declined_' + result?.error?.decline_code)
        } else if (result?.error?.code === 'expired_card') {
          throw new Error('expired_card')
        } else if (result?.error?.code === 'incorrect_cvc') {
          throw new Error('incorrect_cvc')
        } else if (result?.error?.code === 'processing_error') {
          throw new Error('processing_error')
        } else if (result?.error?.code === 'incorrect_number') {
          throw new Error('incorrect_number')
        }
        throw new Error(result?.error?.message || 'Une erreur est survenue')
      }
      return await this.$api.stripe.createPayment(this.product, {
        abGroup: this.abGroup,
        ...(this.promoCodeSaved ? { promoCode: this.promoCodeSaved } : {}),
        paymentMethod: result.setupIntent.payment_method as string,
        frequency: this.frequency,
        options: this.options,
      })
    }
    return await this.$api.stripe.createPayment(this.product,
      {
        ...(this.promoCodeSaved ? { promoCode: this.promoCodeSaved } : {}),
        paymentMethod: this.defaultPaymentMethod.id,
        frequency: this.frequency,
        options: this.options,
        abGroup: this.abGroup,
      })
  }

  async applyPromoCode () {
    if (this.disableCoupon) { this.removePromoCode(); return }
    const regex = /^([A-Z]{2})-(\d{6})-([A-Z\d]{2})$/
    try {
      if (!this.promoCode) { return }
      this.displayPromoCode = this.promoCode
      this.promoCodeLoading = true
      const regex = /^([A-Z]{2})-(\d{6})-([A-Z\d]{2})$/
      if (this.promoCode?.trim()?.length === 12 && regex.test(this.promoCode) && this.isAuthorizedToSetSponsorshipCode) {
        const codeExist = await this.sponsorshipStore.update({
          email: this.user.email ?? '',
          code: this.promoCode?.trim(),
        })
        if (codeExist) {
          this.promoCode = 'MERCIPARRAIN'
          this.displayPromoCode = 'MERCIPARRAIN'
        }
      }
      const promoCode = await this.verifyPromoCodeIsValid(this.promoCode, { frequency: this.frequency })
      if (!promoCode) {
        return
      }
      this.promoCodeSaved = promoCode
    } finally {
      this.promoCodeLoading = false
    }
  }

  removePromoCode () {
    try {
      this.promoCodeError = ''
      this.promoCodeLoading = true
      this.promoCodeSaved = ''
      this.$store.dispatch('upsells/setPromoCode', null)
      this.$emit('update:promo-code', null)
      this.$nextTick(async () => {
        this.$emit('apply-promo-code')
        await this.setCheckout(true)
      })
      this.showPromoCode = false
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    } finally {
      this.promoCodeLoading = false
    }
  }

  addPlan (productId?: StripeProductType) {
    const hasPlan = this.plans?.find(p => p.productId === productId)
    if (hasPlan) {
      this.setPlans(this.plans.map(p => ({
        ...p,
        ...(p.productId === productId
          ? {
            trialAt: null,
            deletedAt: null,
            subscriptionId: 'tmp_value',
            extendedTrialAt: null,
            frequency: this.frequency,
          }
          : {}),
      })))
      return
    }
    if (productId) {
      this.setPlans([
        {
          productId,
          companyId: '',
          id: '',
          trialAt: null,
          deletedAt: null,
          subscriptionId: 'tmp_value',
          extendedTrialAt: null,
          frequency: this.frequency,
        },
      ])
      if ([StripeProductType.ABBY_CREATION_START, StripeProductType.ABBY_CREATION_START_PREMIUM, StripeProductType.ABBY_CREATION_START_BUSINESS].includes(productId)) {
        this.$nuxt.setLayout('default')
      }
    }
  }

  async makePaymentIntent () {
    this.loading = true
    this.showRecurringPaymentInformation = true
    await sleep(1000)
    try {
      await this.waitUntilClientSecret()

      let result
      let resultSubscription
      if (this.isRecurring) {
        resultSubscription = await this.makePaymentSubscription()
      } else {
        result = await this.makePaymentOneTime()
      }

      if (result?.error?.type === 'validation_error') {
        return
      } else if (result?.error) {
        throw new Error(result?.error.message)
      }

      this.$help.sendChatEvent('SubscriptionStarted')
      this.$ap.track(AbbyAction.SUBSCRIPTION_PAYMENT, { source: this.source, product: this.product, frequency: this.frequency })
      this.$ap.sendToGTM({
        event: this.isRecurring ? SegmentEvent.SUBSCRIPTION_STARTED : SegmentEvent.PURCHASE_COMPLETED,
        data: {
          withCE: this.withCE,
          productId: this.product,
          // nécessité de convertir les valeurs renvoyés en centimes depuis stripe
          amount: this.isRecurring
            ? (resultSubscription?.totalAmount ? Math.round(resultSubscription.totalAmount / 100) : null)
            : (result?.paymentIntent?.amount ? Math.round(result.paymentIntent.amount / 100) : null),
          orderId: result?.paymentIntent.id || resultSubscription?.id,
        },
      })
      this.paymentDone = true
      this.$emit('succeed', this.product)
      this.showThanks = true
      this.addPlan(this.product)
      this.$alertsManager.success('Vous avez bien procédé au paiement')
    } catch (e: any) {
      if (e.message === 'card_declined_generic_decline') {
        this.$alertsManager.error('Votre carte a été refusée. Merci de réessayer ou changer de moyen de paiement. (Votre carte est-elle active ?)')
      } else if (e.message === 'card_declined_insufficient_funds') {
        this.$alertsManager.error('Votre carte a été refusée pour fonds insuffisants. Merci d\'approvisionner votre compte puis réessayer ou changer de moyen de paiement.')
      } else if (e.message === 'card_declined_lost_card') {
        this.$alertsManager.error('Le paiement a été refusé car la carte a été signalée perdue. Merci de changer de moyen de paiement.')
      } else if (e.message === 'card_declined_stolen_card') {
        this.$alertsManager.error('Le paiement a été refusé car la carte a été signalée volée. Merci de changer de moyen de paiement.')
      } else if (e.message === 'expired_card') {
        this.$alertsManager.error('Le paiement a été refusé car la carte est expirée. Merci de changer de moyen de paiement.')
      } else if (e.message === 'incorrect_cvc') {
        this.$alertsManager.error('Le numéro CVC est incorrect. Merci de le corriger puis réessayer.')
      } else if (e.message === 'incorrect_number') {
        this.$alertsManager.error('Le numéro de carte est incorrect. Merci de le corriger puis réessayer.')
      } else if (e.message === 'processing_error') {
        this.$alertsManager.error('Une erreur s\'est produite lors du traitement de la carte. Le paiement doit être tenté à nouveau. Si le paiement ne peut toujours pas être traité, veuillez réessayer plus tard ou changer de moyen de paiement')
      } else {
        this.$alertsManager.autoError(e as any)
      }
    } finally {
      this.loading = false
    }
  }
}
