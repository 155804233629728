interface BillingConfigurationProps {
    id: string;
    test: boolean;
    tiersPrestationCredentials?: {
        clientId: string;
    },
    tiersPrestationActivated?: boolean;
}

export class BillingConfiguration {
    readonly id: string;
    readonly test: boolean;
    readonly tiersPrestationCredentials?: {
        clientId: string;
        novaNumber?: string;
    }

    readonly tiersPrestationActivated?: boolean;

    private constructor (props: BillingConfigurationProps) {
      this.id = props.id
      this.test = props.test
      this.tiersPrestationCredentials = props.tiersPrestationCredentials
      this.tiersPrestationActivated = props.tiersPrestationActivated
    }

    static create (props: BillingConfigurationProps): BillingConfiguration {
      return new BillingConfiguration(props)
    }
}
