import { ProductType, ProductUnit } from '@abby/shared'

export const useIsProductStockable = () => {
  return (type: ProductType, unit: ProductUnit) => {
    const allowedStockUnit = [ProductUnit.UNIT, ProductUnit.ARTICLE, ProductUnit.GRAM, ProductUnit.KILOGRAM,
      ProductUnit.KILOMETER, ProductUnit.LITER, ProductUnit.BATCH, ProductUnit.METER, ProductUnit.SQUARE_METER,
      ProductUnit.CUBIC_METER, ProductUnit.LINEAR_METER, ProductUnit.TON, ProductUnit.LICENSE,
    ]
    const allowedStockType = [ProductType.SALE_OF_GOODS, ProductType.SALE_OF_MANUFACTURED_GOODS, ProductType.DISBURSEMENT]

    return allowedStockType.includes(type) && allowedStockUnit.includes(unit)
  }
}
