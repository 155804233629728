import {
  InvalidUrssafCustomerRegistrationReason,
  UrssafError,
  SapAddress,
} from '@abby/shared'
import pickBy from 'lodash/pickBy'
import { ContactTPForm, ContactTPFormProps } from '~/services/client/contact/forms/ContactTP.form'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import {
  useCreateContactFormFromContactTPForm,
} from '~/composables/client/contact/mapper/useCreateContactFromContactTPForm'

const AllUrssafErrors = {
  ...UrssafError,
  ...InvalidUrssafCustomerRegistrationReason,
}
type TypeOfAllUrssafErrors =
  | InvalidUrssafCustomerRegistrationReason
  | UrssafError;

export const useCreateContactTP = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()
  const clientStore = useClientStore()
  const busManager = useBusManager()
  const createContactFormFromContactTPFormMapper = useCreateContactFormFromContactTPForm()

  return async (contactTP: ContactTPFormProps): Promise<void> => {
    const newContact = await contactRepository.createContact(
      createContactFormFromContactTPFormMapper(contactTP),
    )
    try {
      await contactRepository.registerTierPrestation({
        ...contactTP,
        address: pickBy(contactTP.address, value => !!value) as SapAddress,
        contactId: newContact.id,
      })
      alertManager.success('Le contact a bien été créé')
      clientStore.toggleClientFormSidePanel(false)
      busManager.emit('contactCreated', newContact)
    } catch (e: any) {
      if (
        Array.isArray(e.response.data?.data) &&
        e.response.data.data.map((e: string) =>
          Object.values(AllUrssafErrors).includes(e as TypeOfAllUrssafErrors),
        )
      ) {
        clientStore.urssafTPErrors.value = e.response.data.data
        clientStore.contactTPToEdit.value = ContactTPForm.create({ ...contactTP, ...{ contactId: newContact.id } })
      } else {
        clientStore.toggleClientFormSidePanel(false)
      }
    }
  }
}
