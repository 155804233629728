export enum BillingLogEvent {
  // DRAFTED
  INVOICE_DRAFTED = 'invoice.drafted',
  ADVANCE_DRAFTED = 'advance.drafted',
  ASSET_DRAFTED = 'asset.drafted',
  PURCHASE_ORDER_DRAFTED = 'purchase_order.drafted',
  ESTIMATE_DRAFTED = 'estimate.drafted',

  // FINALIZED
  INVOICE_FINALIZED = 'invoice.finalized',
  ADVANCE_FINALIZED = 'advance.finalized',
  ASSET_FINALIZED = 'asset.finalized',
  PURCHASE_ORDER_FINALIZED = 'purchase_order.finalized',
  ESTIMATE_FINALIZED = 'estimate.finalized',

  // CREATED FINALIZED
  INVOICE_CREATED_FINALIZED = 'invoice.created_finalized',
  ADVANCE_CREATED_FINALIZED = 'advance.created_finalized',
  ASSET_CREATED_FINALIZED = 'asset.created_finalized',
  PURCHASE_ORDER_CREATED_FINALIZED = 'purchase_order.created_finalized',
  ESTIMATE_CREATED_FINALIZED = 'estimate.created_finalized',

  // SIGNED
  PURCHASE_ORDER_SIGNED = 'purchase_order.signed',
  ESTIMATE_SIGNED = 'estimate.signed',

  // SIGNED ONLINE
  PURCHASE_ORDER_SIGNED_ONLINE = 'purchase_order.signed_online',
  ESTIMATE_SIGNED_ONLINE = 'estimate.signed_online',

  // REFUSED
  PURCHASE_ORDER_REFUSED = 'purchase_order.refused',
  ESTIMATE_REFUSED = 'estimate.refused',

  // UNPAID
  INVOICE_UNPAID = 'invoice.unpaid',
  ADVANCE_UNPAID = 'advance.unpaid',
  ASSET_UNPAID = 'asset.unpaid',

  // PAID
  INVOICE_PAID = 'invoice.paid',
  ADVANCE_PAID = 'advance.paid',
  ASSET_PAID = 'asset.paid',

  // CANCELLED
  INVOICE_CANCELLED = 'invoice.cancelled',
  ADVANCE_CANCELLED = 'advance.cancelled',

  // REQUESTED
  INVOICE_EMAIL_REQUESTED = 'invoice_email.requested',
  ESTIMATE_EMAIL_REQUESTED = 'estimate_email.requested',
  PURCHASE_ORDER_EMAIL_REQUESTED = 'purchase_order_email.requested',
  ASSET_EMAIL_REQUESTED = 'asset_email.requested',
  ADVANCE_EMAIL_REQUESTED = 'advance_email.requested',

  // DELIVERED
  INVOICE_EMAIL_DELIVERED = 'invoice_email.delivered',
  ESTIMATE_EMAIL_DELIVERED = 'estimate_email.delivered',
  PURCHASE_ORDER_EMAIL_DELIVERED = 'purchase_order_email.delivered',
  ASSET_EMAIL_DELIVERED = 'asset_email.delivered',
  ADVANCE_EMAIL_DELIVERED = 'advance_email.delivered',

  // OPENED
  INVOICE_EMAIL_UNIQUE_OPENED = 'invoice_email.unique_opened',
  ESTIMATE_EMAIL_UNIQUE_OPENED = 'estimate_email.unique_opened',
  PURCHASE_ORDER_EMAIL_UNIQUE_OPENED = 'purchase_order_email.unique_opened',
  ASSET_EMAIL_UNIQUE_OPENED = 'asset_email.unique_opened',
  ADVANCE_EMAIL_UNIQUE_OPENED = 'advance_email.unique_opened',

  // CLICKED
  INVOICE_EMAIL_CLICKED = 'invoice_email.clicked',
  ESTIMATE_EMAIL_CLICKED = 'estimate_email.clicked',
  PURCHASE_ORDER_EMAIL_CLICKED = 'purchase_order_email.clicked',
  ASSET_EMAIL_CLICKED = 'asset_email.clicked',
  ADVANCE_EMAIL_CLICKED = 'advance_email.clicked',

  // REMINDER
  INVOICE_REMINDER_ACTIVATED = 'invoice_reminder.activated',
  INVOICE_REMINDER_DISABLED = 'invoice_reminder.disabled',

  // FREQUENCY
  INVOICE_FREQUENCY_ACTIVATED = 'invoice_frequency.activated',
  INVOICE_FREQUENCY_DISABLED = 'invoice_frequency.disabled',
}
