import { ReadStockConfiguration } from '@abby/shared'
import { StockManagementConfigurationItem } from '~/services/product/stocks/entities/StockManagementConfigurationItem.entity'

export class StockManagementConfigurationItemMapper {
  static fromHttp (stockManagementConfiguration: ReadStockConfiguration): StockManagementConfigurationItem {
    return StockManagementConfigurationItem.create({
      id: stockManagementConfiguration.id,
      companyId: stockManagementConfiguration.companyId,
      isActivated: stockManagementConfiguration.isActivated,
      stockEvent: stockManagementConfiguration.stockEvent,
      updatedAt: stockManagementConfiguration.updatedAt,
      createdAt: stockManagementConfiguration.createdAt,
    })
  }
}
