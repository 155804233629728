import { ReadOpportunityListItem, Time } from '@abby/shared'
import { OpportunityItem } from '~/services/productivity/opportunity/entities/OpportunityItem.entity'
import { AbbyAmount } from '~/services/abby/valueObjects/AbbyAmount.valueObject'

export class OpportunityItemMapper {
  static toDomain (item: ReadOpportunityListItem): OpportunityItem {
    return OpportunityItem.create({
      id: item.id,
      name: item.name,
      ...item.dueDate && { dueDate: Time.toDate(item.dueDate) },
      amount: AbbyAmount.create(item.amount),
      customerId: item.customer.id,
      customerName: item.customer.name,
      category: {
        ...item.category,
        showCustomer: true,
        showDueDate: true,
        showAmount: true,
      },
    })
  }
}
