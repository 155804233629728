import { BillingLocale } from '@abby/core-legacy'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'

export const useDownloadBilling = () => {
  const busManager = useBusManager()
  const billingRepository = useBillingRepository()

  return async (billing: BillingItem, options?: { locale: BillingLocale }) => {
    await billingRepository.downloadBilling(billing, options)
    billing.lastDownloadAt = new Date()
    busManager.emit('billingDownloaded', billing)
  }
}
