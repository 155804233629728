
import { Component, Vue } from 'nuxt-property-decorator'

@Component({
  middleware: ['redirection'],
})
export default class Auth extends Vue {
  head () {
    return this.$brandManager.head()
  }
}

