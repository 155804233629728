import { CA } from './CA';
import { CotisationAmounts } from './CotisationAmounts';
import { Periode } from './Periode';
import { CreatePayment } from './CreatePayment';
import { CalculationMethod } from '../../../Enum/CalculationMethod';

export type OutputDeclaration = {
  montants: CotisationAmounts;
  dateExigibilite: Date;
  certificat: string;
  periode: Periode;
  idDeclaration: string;
};

export type OutputEstimation = {
  montants: CotisationAmounts;
  dateExigibilite: Date;
  periode: Periode;
};

export type OutputTelepaiement = {
  certificat: string;
  dateExigibilite: string;
  periode: Periode;
  idTelepaiement: string;
};

export enum DeclarationState {
  ALREADY_DECLARED_ELSEWHERE = 1,
  DECLARED = 2,
  PAID = 3,
}

export enum DeclarationActionHistory {
  DECLARATION_CREATION = 1,
  DECLARATION_EDITION = 2,
  DECLARATION_PAYMENT = 3,
  DECLARATION_DONE_ELSEWHERE = 4,
}

export type CreateOrUpdateDeclarationData = OutputDeclaration & {
  ca: CA;
  siret: string;
  companyId: string;
  isCipav: boolean;
};

export interface CreateDeclarationData {
  companyId: string;
  siret: string;
  declarationState: DeclarationState;

  montants: CotisationAmounts;
  dateExigibilite: Date;
  certificat: string;
  periode: Periode;

  ca: CA;

  history?: Array<{
    action: DeclarationActionHistory;
    date: Date;
    data: any;
  }>;
}

export interface IUrssafDeclarationItem {
  _id?: string;
  companyId: string;
  siret: string;
  declarationState: DeclarationState;

  montants?: CotisationAmounts | null;
  dateExigibilite?: Date | null;
  declaredAt?: Date;
  paidAt?: Date;
  paymentMethodUsed?: CreatePayment | null;
  certificat?: string | null;
  isCipav?: boolean;
  periode: Periode;
  basedOn: CalculationMethod;

  ca?: CA | null;

  history?: Array<{
    action: DeclarationActionHistory;
    date: Date;
    data: any;
  }>;
}
