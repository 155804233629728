import type { ReadBridgeItem } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Bridge extends Resource {
  connectV2 (bankId?: number): Promise<{ redirectUrl: string }> {
    return this.axios.$get('v2/banks/items/connect', { params: { bankId } })
  }

  getAll (): Promise<Array<ReadBridgeItem>> {
    return this.axios.$get('/bridge/items')
  }

  deleteItem (itemId: number): Promise<boolean> {
    return this.axios.$delete(`/bridge/item/${itemId}`)
  }

  deleteAll (companyId: string): Promise<boolean> {
    return this.axios.$delete(`/bridge/${companyId}`)
  }
}
