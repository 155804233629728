import { Assurance } from './Assurance';

function getAssurances(assurances: string[]): any {
  return Assurance.filter(ass => assurances.includes(ass.code));
}

export const ParentActivityMeta: Array<any> = [
  {
    name: 'Achat-Revente',
    argument: 'Votre service de livraison a endommagé un colis destiné à votre client. Votre assurance pourra compenser votre perte pour vous permettre de rebondir.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Agent commercial / Apporteur d\'affaires',
    argument: 'En cas de litige avec un client, les frais de défense et les éventuels dommages et intérêts peuvent représenter des sommes considérables ! Votre assurance pourra compenser tout ces frais.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Artisanat (Création, Fabrication, Réparation)',
    argument: 'Vous êtes responsable d’une création, fabrication ou réparation. S’il y a une quelconque défaillance, alors votre client peut réclamer réparation. Allez de l\'avant, assurez vos arrières.',
    assurances: getAssurances(['simplis']),
    required: true,
  },
  {
    name: 'Batiment / Gros oeuvre / Second oeuvre',
    argument: 'Après avoir terminée un chantier, votre responsabilité est engagé pendant 10 ans auprès des propriétaires et des successeurs. Avec une assurance, travaillez sereinement.',
    assurances: getAssurances(['simplis']),
    required: true,
  },
  {
    name: 'Beauté / Bien-être / Esotérisme',
    argument: 'Vous êtes responsable d\'une réaction allergique, brûlure, coupure... Un accident ne se prévoit pas en revanche se protéger contre oui.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Conseil / Expertise / Services aux entreprises',
    argument: 'La liste des risques auquel vous êtes exposé est longue : retards, informations confidentielles, perte de données, casse... Avec une assurance, travaillez sereinement.',
    assurances: getAssurances(['assurup']),
    required: true,
  },
  {
    name: 'Cours et Formation',
    argument: 'Votre activité est soumise à des défauts de conseil, divulgation d’informations confidentielles... Votre assurance pourra compenser des demandes de remboursement potentielles.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Culture, Animation, Sports, et Spectacles',
    argument: 'Vous avez organisé un événement durant lequel un visiteur s\'est blessé sur une installation. Il peut réclamer des dommages et intérêt très important pour le préjudice causé. Avec une assurance, travaillez sereinement.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Hôtellerie-Restauration, Tourisme',
    argument: 'Un client tombe malade après avoir consommé ce que vous lui avez vendu. L’addition risque d’être salée. Sauf avec une assurance.',
    assurances: getAssurances(['assurup']),
    required: true,
  },
  {
    name: 'Location d\'équipements et de matériel',
    argument: 'Votre matériel coûte cher. Il se dégrade, perd en valeur ou vous le retrouvez dans un état déplorable. Votre assurance vous couvre entièrement.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Mécanique / Technique',
    argument: 'Une de vos réparations a endommagé certaines pièces ou est à l’origine d’un malheureux accident. C\'est votre responsabilité qui est engagée.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Médical / Santé',
    argument: 'Vous travaillez avec la santé de vos patients. Les professions de santé nécessitent une attestation d’assurance Responsabilité civile professionnelle pour être exercées en toute légalité.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Service à la personne et aux animaux',
    argument: 'Votre activité consiste à prendre soin de la vie qui vous entoure. C\'est votre assurance et votre responsabilité qui est engagée.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
  {
    name: 'Transport / Livraison / Logistique',
    argument: 'Vous êtes victime ou responsable d\'un accident, les conséquences peuvent être désastreuses physiquement, moralement ou bien financièrement. Allez de l\'avant, assurez vos arrières.',
    assurances: getAssurances(['assurup']),
    required: true,
  },
  {
    name: 'Web / Informatique / Multimédias',
    argument: 'Vous travaillez sur des maquettes et vous perdez la sauvegarde d\'une semaine de travail. Un accident ne se prévoit pas en revanche se protéger contre oui.',
    assurances: getAssurances(['assurup']),
    required: false,
  },
];

export function getParentActivityMeta(parentActivityName: string | undefined): any {
  return ParentActivityMeta.find(pam => pam.name === parentActivityName) || {
    name: 'Autre',
    argument: 'La liste des risques auquel vous êtes exposé est longue : accidents, retards, perte de données, casse... Avec une assurance, travaillez sereinement.',
    assurances: getAssurances(['assurup']),
    required: false,
  };
}
