import { ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { AbbyArcadeCode } from '~/services/abby/enums/AbbyArcadeCode.enum'

export const useArcadePiniaStore = () => {
  const useArcadePiniaStore = defineStore('arcadePiniaStore', () => {
    const isOpened = ref(false)
    const arcadeCode = ref<AbbyArcadeCode | null>(null)

    const toggleArcadePiniaModal = (value: boolean, _arcadeCode?: AbbyArcadeCode) => {
      isOpened.value = value
      arcadeCode.value = _arcadeCode || null
    }

    return {
      isOpened,
      arcadeCode,
      toggleArcadePiniaModal,
    }
  })

  const arcadePiniaStore = useArcadePiniaStore()
  const { toggleArcadePiniaModal } = arcadePiniaStore
  const {
    isOpened,
    arcadeCode,
  } = storeToRefs(arcadePiniaStore)

  return {
    isOpened,
    arcadeCode,
    toggleArcadePiniaModal,
  }
}
