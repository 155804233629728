import { Resource } from '../Resource';
import { DeleteAccountChurnSurveyQuestion } from '../../enums';
import { CreateUnsubscribeChurnSurvey } from '../../dto';

type DeleteAccountChurnSurvey = {
  question: DeleteAccountChurnSurveyQuestion;
  answer: string;
};

type CreateAuthenticatedExperimentation = {
  experimentName: string;
  variantName: string;
};

export class Analytics extends Resource {
  createUnsubscribeChurnSurvey(params: CreateUnsubscribeChurnSurvey) {
    return this.http.$post('analytics/churn-survey/unsubscribe', params);
  }

  startAuthenticatedExperimentation(params: CreateAuthenticatedExperimentation) {
    return this.http.$post('v2/analytic/startAuthenticatedExperimentation', params);
  }

  createDeleteAccountChurnSurvey(deleteAccountChurnSurvey: DeleteAccountChurnSurvey) {
    return this.http.$post('analytics/churn-survey/delete-account', {
      question: deleteAccountChurnSurvey.question,
      answer: deleteAccountChurnSurvey.answer,
    });
  }
}
