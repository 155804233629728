import { ClientType } from '~/services/client/_common/enums/ClientType.enum'
import { ContactFormMapper } from '~/services/client/contact/mappers/ContactForm.mapper'
import { ContactTPFormMapper } from '~/services/client/contact/mappers/ContactTPForm.mapper'
import { OrganizationFormMapper } from '~/services/client/organization/mappers/OrganizationForm.mapper'
import { OrganizationContactFormMapper } from '~/services/client/organization/mappers/OrganizationContactForm.mapper'
import { OrganizationMapper } from '~/services/client/organization/mappers/Organization.mapper'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'

export const useOpenClientFormEditionSidePanel = () => {
  const clientStore = useClientStore()
  const organizationRepository = useOrganizationRepository()
  const contactRepository = useContactRepository()

  return async (id: string, clientType: ClientType) => {
    switch (clientType) {
    case ClientType.ORGANIZATION: {
      const organization = await organizationRepository.getOrganizationById(id)
      clientStore.openEditClientFormSidePanel(
        OrganizationFormMapper.toDomain(organization),
        ClientType.ORGANIZATION,
      )
      break
    }
    case ClientType.CONTACT:
    case ClientType.CONTACT_ORGANIZATION:
    case ClientType.CONTACT_PSA: {
      const contact = await contactRepository.getContactById(id)

      if (contact.hasTiersPrestation) {
        const customerTP = await contactRepository.getContactSapData(contact.id)
        if (customerTP) {
          clientStore.openEditClientFormSidePanel(
            ContactTPFormMapper.toDomain(contact, customerTP),
            ClientType.CONTACT_PSA,
          )
        }
      } else if (contact.organization) {
        const organization = await organizationRepository.getOrganizationById(contact.organization.id)
        clientStore.selectedOrganizationContactOrganization.value = OrganizationMapper.toDomain(organization)
        clientStore.openEditClientFormSidePanel(
          OrganizationContactFormMapper.toDomain(contact, organization),
          ClientType.CONTACT_ORGANIZATION,
        )
      } else {
        clientStore.openEditClientFormSidePanel(
          ContactFormMapper.toDomain(contact),
          ClientType.CONTACT,
        )
      }
      break
    }
    default:
      break
    }
  }
}
