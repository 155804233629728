import debounce from 'lodash/debounce'
import { getCacheByAxiosConfig, requestInterceptor, isCacheableMethod, getUUIDByAxiosConfig, resetCache } from '~/plugins/etag'
import { getHeaderCaseInsensitive } from '~/plugins/etag/utils'
import { Cache } from '~/plugins/etag/Cache'

// Screen dimension state management
let cachedScreenHeight: number = window.innerHeight
let cachedScreenWidth: number = window.innerWidth

// Debounced dimension update function
const updateScreenDimensions = debounce(() => {
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth
}, 250)

let tokenPromise: Promise<string> | null = null

export const retrieveToken = () => {
  const tokenKey = Object.entries(localStorage).find(([key]) =>
    key.includes('firebase:authUser:'),
  )
  if (!tokenKey) {
    return {
      refreshToken: null,
      token: null,
      expirationTime: null,
    }
  }
  const { stsTokenManager } = JSON.parse(localStorage[tokenKey[0]])
  return {
    token: stsTokenManager.accessToken,
    refreshToken: stsTokenManager.refreshToken,
    expirationTime: stsTokenManager.expirationTime,
  }
}

export const checkIfUser = ($fire: any) => {
  if (!$fire.auth.currentUser) {
    const { refreshToken } = retrieveToken()
    return !!refreshToken
  }
  return true
}

export const refreshToken = async ($fire: any, hasFirebaseUser: boolean, store: any, cb: () => void) => {
  setTimeout(() => cb(), 10_000)

  if (hasFirebaseUser) {
    const token = await $fire.auth.currentUser.getIdToken(true)
    if (store.getters['auth/expirationTime'] !== null) {
      const expirationTime = Date.now() + 3600 * 1000
      store.dispatch('auth/setExpirationTime', expirationTime, { root: true })
    }
    return token
  }

  const apiKey = $fire.auth.config.apiKey
  const { refreshToken, expirationTime } = retrieveToken()

  if (store.getters['auth/expirationTime'] !== null) {
    store.dispatch('auth/setExpirationTime', expirationTime, { root: true })
  }

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: new URLSearchParams({
      key: apiKey,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
  }

  const data = await fetch('https://securetoken.googleapis.com/v1/token', options)
    .then(response => response.json())
  return data.id_token
}

export default function ({ $axios, $fire, $api, $sdk, store }: any) {
  // Set initial dimensions
  cachedScreenHeight = window.innerHeight
  cachedScreenWidth = window.innerWidth

  // Initialize resize listener
  window.addEventListener('resize', updateScreenDimensions)

  const logout = async () => {
    resetCache()
    await store.dispatch('auth/logout', null, { root: true })
  }

  $axios.interceptors.request.use(requestInterceptor)

  $axios.onResponse((response: any) => {
    if (isCacheableMethod(response.config)) {
      const responseETAG = getHeaderCaseInsensitive('etag', response.headers)
      if (responseETAG) {
        const uuid = getUUIDByAxiosConfig(response.config)
        if (!uuid) {
          return null
        }
        Cache.set(uuid, responseETAG, response.data)
      }
    }

    return response
  })

  $axios.onRequest(async (config: any) => {
    const expirationTime = store.getters['auth/expirationTime'] || 0
    const hasUser = checkIfUser($fire)
    const hasFirebaseUser = !!$fire.auth.currentUser

    // Set screen dimensions from cached values
    config.headers['screen-height'] = cachedScreenHeight
    config.headers['screen-width'] = cachedScreenWidth
    config.headers.support = 'web-app'

    if (hasUser && expirationTime < Date.now()) {
      try {
        if (!tokenPromise) {
          tokenPromise = refreshToken($fire, hasFirebaseUser, store, () => { tokenPromise = null })
        }
        const token = await tokenPromise
        $api.setToken(`Bearer ${token}`)
        $sdk.setToken(token)
        config.headers.authorization = `Bearer ${token}`
        return config
      } catch (e) {
        await logout()
        // eslint-disable-next-line no-console
        console.error(e)
      }
    }
    return config
  })

  $axios.onResponseError(async (error: any) => {
    if (error.config && error.response && error.response.status === 401) {
      await logout()
      throw error
    }

    // Handle cached responses
    if (error.response && error.response.status === 304) {
      const getCachedResult = getCacheByAxiosConfig(error.response.config)
      if (!getCachedResult) {
        throw error
      }
      const newResponse = error.response
      newResponse.status = 200
      newResponse.data = getCachedResult.value
      return newResponse
    }

    throw error
  })
}
