import { ActionTree, GetterTree } from 'vuex'
import {
  CampaignType,
} from '@abby/core-legacy'
import type {
  ReadCampaigns,
  UpdateCampaigns,
} from '@abby/core-legacy'
import { RootState } from '~/store/index'
import { CampaignTrigger, CampaignTriggerConfig } from '~/plugins/campaignsManager'

type ModalData = {
  campaign?: CampaignType
}

export interface CampaignsState {
  campaigns: ReadCampaigns | null
  campaignsQueue: Array<CampaignTriggerConfig>
  loaded: boolean,
  counter: {
    [CampaignTrigger.CLICKS]: number
    [CampaignTrigger.VIEWS]: number
    [CampaignTrigger.INACTIVITY_DELAY]: number
    [CampaignTrigger.SCROLL]: number
  }
  modals: {
    resetBillingNumber: boolean,
    onboardingUrssafConnection: boolean,
    onboardingImmediateAdvance: boolean,
    blackFriday: boolean,
    welcomeVideo: boolean,
    trialJanuary2024: boolean,
    blackFridayReport2024: boolean,
    multipleYearsSubscription: boolean,
  }
  modalsData: {
    [k in keyof CampaignsState['modals']]?: ModalData
  } | null
}

export type CampaignsModals = Extract<keyof CampaignsState['modals'], string>

export const state = (): CampaignsState => ({
  campaigns: null,
  campaignsQueue: [],
  loaded: false,
  counter: {
    [CampaignTrigger.CLICKS]: 0,
    [CampaignTrigger.VIEWS]: 0,
    [CampaignTrigger.INACTIVITY_DELAY]: 0,
    [CampaignTrigger.SCROLL]: 0,
  },
  modals: {
    resetBillingNumber: false,
    onboardingImmediateAdvance: false,
    onboardingUrssafConnection: false,
    blackFriday: false,
    welcomeVideo: false,
    trialJanuary2024: false,
    blackFridayReport2024: false,
    multipleYearsSubscription: false,
  },
  modalsData: null,
})

export const getters: GetterTree<CampaignsState, RootState> = {
  campaigns (state: CampaignsState) {
    return state.campaigns
  },
  campaignsQueue (state: CampaignsState) {
    return state.campaignsQueue
  },
  counter (state: CampaignsState) {
    return state.counter
  },
  modalsData: state => state.modalsData,
  modals: state => state.modals,
  loaded: state => state.loaded,
}

export const mutations = {
  SET_CAMPAIGNS (state: CampaignsState, value: ReadCampaigns | null) {
    state.campaigns = value
  },
  OPEN_MODAL (state: CampaignsState, key: CampaignsModals) {
    state.modals[key] = true
  },
  OPEN_MODAL_WITH_DATA (state: CampaignsState, { modal, data }: { modal: CampaignsModals, data: ModalData }) {
    state.modals[modal] = true
    if (state.modalsData) {
      state.modalsData[modal] = data
    } else {
      state.modalsData = {
        [modal]: data,
      }
    }
  },
  CLOSE_MODAL (state: CampaignsState, key: CampaignsModals) {
    state.modals[key] = false
    if (state.modalsData) {
      state.modalsData[key] = undefined
    }
  },
  CLEAN_MODAL_WITH_DATA (state: CampaignsState, key: CampaignsModals) {
    if (state.modalsData) {
      state.modalsData[key] = undefined
    }
  },
  INCREMENT_COUNTER (state: CampaignsState, { value, counter }: { value: number, counter: keyof CampaignsState['counter'] }) {
    state.counter[counter] += value
  },
  RESET_COUNTER (state: CampaignsState, counter: keyof CampaignsState['counter']) {
    state.counter[counter] = 0
  },
  PUSH_CAMPAIGN_IN_QUEUE (state: CampaignsState, value: CampaignTriggerConfig) {
    // Ne pas enregistrer plusieurs campagne avec le même trigger
    // if (state.campaignsQueue.find(c => c.trigger === value.trigger)) { return }
    // Ne pas enregistrer une campagne avec doNotConsiderAnymoreAt
    // if (state?.campaigns?.campaigns?.[value.type]?.doNotConsiderAnymoreAt) { return }
    state.campaignsQueue.push(value)
  },
  REMOVE_CAMPAIGN_IN_QUEUE (state: CampaignsState, value: CampaignTriggerConfig) {
    const index = state.campaignsQueue.indexOf(value)
    state.campaignsQueue.splice(index, 1)
  },
  RESET (_currentState: CampaignsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_LOADED (state: CampaignsState, value: boolean) {
    state.loaded = value
  },
}

export const actions: ActionTree<any, any> = {
  async fetchCampaigns ({ commit }) {
    try {
      let campaigns = null
      // Pour les admins
      // if (companyId) {
      //   campaigns = await this.$api.campaigns.getCampaignsByCompanyId(companyId)
      // Pour les utilisateurs
      // } else {
      campaigns = await this.$api.campaigns.getCompanyCampaigns()
      // }
      commit('SET_CAMPAIGNS', campaigns)
      commit('SET_LOADED', true)
    } catch (e) {
      //
    } finally {
      //
    }
  },

  async updateCampaigns ({ commit }, params: UpdateCampaigns) {
    try {
      let campaigns = null
      // Pour les admins
      if (params?.companyId) {
        campaigns = await this.$api.campaigns.updateCampaignsByCompanyId(params)
        // Pour les utilisateurs
      } else {
        campaigns = await this.$api.campaigns.updateCompanyCampaigns(params)
      }
      commit('SET_CAMPAIGNS', campaigns)
    } catch (e) {
      //
    } finally {
      //
    }
  },

  openModal ({ commit }, key: CampaignsModals) {
    commit('OPEN_MODAL', key)
  },

  closeModal ({ commit }, key: CampaignsModals) {
    commit('CLOSE_MODAL', key)
  },
}
