import { ContactItem } from '~/services/client/contact/entities/ContactItem.entity'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'

export const useRemoveContact = () => {
  const contactRepository = useContactRepository()
  const busManager = useBusManager()
  const alertManager = useAlertManager()
  const confirmManager = useConfirmManager()

  return async (contact: ContactItem) => {
    const confirm = await confirmManager.ask({
      title: 'Supprimer un contact',
      content: 'Cette action est irréversible. Voulez vous vraiment supprimer ce contact ?',
      confirmText: 'Supprimer',
      icon: 'remove',
      confirmButton: { color: 'error', depressed: true },
    })
    if (!confirm) {
      return false
    }
    await contactRepository.removeContact(contact.id)
    busManager.emit('contactRemoved', contact)
    alertManager.success('Le contact a été supprimé avec succès.')
  }
}
