import { ReadStockMovement } from '@abby/shared'
import { StockMovementItem } from '~/services/product/stocks/entities/StockMovementItem.entity'

export class StockMovementItemMapper {
  static fromHttp (stockMovements: ReadStockMovement): StockMovementItem {
    return StockMovementItem.create({
      id: stockMovements.id,
      date: stockMovements.createdAt,
      companyId: stockMovements.companyId,
      productDesignation: stockMovements.productDesignation,
      productId: stockMovements.productId,
      quantity: stockMovements.quantity,
      billingId: stockMovements.billingId,
      billingNumber: stockMovements.billingNumber,
      stockEvent: stockMovements.stockEvent,
      product: stockMovements.product,
      productReference: stockMovements.productReference,
    })
  }
}
