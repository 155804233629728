import { ClientState } from '@abby/shared'
import { ContactAction } from '~/services/client/contact/enums/ContactAction.enum'

export type ContactItemProps = {
  id: string
  fullname: string
  emails?: string[]
  defaultContact?: boolean
  createdAt: Date
  archivedAt?: Date
  hasBillings: boolean
  tiersPrestation?: {
    state: ClientState;
  }
  organization?: {
    id: string;
    name: string;
  };
  test: boolean;
}

export class ContactItem {
  readonly id: string
  readonly fullname: string
  readonly emails?: string[]
  readonly defaultContact?: boolean
  readonly createdAt: Date
  readonly archivedAt?: Date
  readonly hasBillings: boolean
  readonly test: boolean

  readonly tiersPrestation?: {
      state: ClientState;
  }

  readonly organization?: {
    id: string;
    name: string;
  }

  actions: ContactAction[] = [];

  private constructor (props: ContactItemProps) {
    this.id = props.id
    this.fullname = props.fullname
    this.emails = props.emails
    this.defaultContact = props.defaultContact
    this.createdAt = props.createdAt
    this.archivedAt = props.archivedAt
    this.organization = props.organization
    this.hasBillings = props.hasBillings
    this.tiersPrestation = props.tiersPrestation
    this.test = props.test

    this.computeActions()
  }

  public static create (props: ContactItemProps): ContactItem {
    return new ContactItem(props)
  }

  computeActions () {
    this.actions.push(
      ContactAction.EDIT,
      ContactAction.ARCHIVE,
      ContactAction.UNARCHIVE,
      ContactAction.DELETE,
      ContactAction.VIEW_DETAILS,
      ContactAction.VIEW_ORGANIZATION_DETAILS,
      ContactAction.CREATE_INVOICE,
      ContactAction.CREATE_ESTIMATE,
      ContactAction.CREATE_ASSET,
      ContactAction.CREATE_PURCHASE_ORDER,
      ContactAction.ADMIN_COPY_CONTACT_ID,
      ContactAction.GO_TO_CLIENT_PORTAL,
      ContactAction.URSSAF_ACTIVATION_EMAIL_NOT_RECEIVED,
      ContactAction.UPDATED_EMAIL_URSSAF_ACCOUNT,
      ContactAction.DOWNLOAD_TAX_RETURN,
      ContactAction.DEFINE_AS_PRIMARY_CONTACT,
      ContactAction.EDIT_NOTES,
    )
  }
}
