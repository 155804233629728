import { ProductType, ProductUnit } from '@abby/shared'
import { ProductAction } from '~/services/product/_common/enums/ProductAction.enum'

export type ProductItemProps = {
  id: string;
  readonly typeEnum: ProductType;
  readonly unitEnum: ProductUnit;
  type: string;
  unit: string;
  designation: string;
  description: string | null;
  personalServiceActivity: string | null;
  reference: string | null;
  vatText: string | null;
  vatAmount?: number;
  isDeliveryOfGood: boolean;
  unitPrice: string;
  priceWithoutTaxes: string;
  priceWithTaxes: string;
  taxesIncluded: boolean;
  stock: number;
}

export class ProductItem {
  readonly id: string;
  readonly typeEnum: ProductType;
  readonly unitEnum: ProductUnit;
  readonly type: string;
  readonly unit: string;
  readonly designation: string;
  readonly description: string | null;
  readonly personalServiceActivity: string | null;
  readonly reference: string | null;
  readonly vatText: string | null;
  readonly vatAmount?: number;
  readonly isDeliveryOfGood: boolean;
  readonly unitPrice: string;
  readonly priceWithoutTaxes: string;
  readonly priceWithTaxes: string;
  readonly taxesIncluded: boolean;
  readonly stock: number;

  actions: ProductAction[] = [];

  private constructor (props: ProductItemProps) {
    this.id = props.id
    this.typeEnum = props.typeEnum
    this.unitEnum = props.unitEnum
    this.type = props.type
    this.unit = props.unit
    this.designation = props.designation
    this.description = props.description
    this.personalServiceActivity = props.personalServiceActivity
    this.reference = props.reference
    this.vatText = props.vatText
    this.vatAmount = props.vatAmount
    this.isDeliveryOfGood = props.isDeliveryOfGood
    this.unitPrice = props.unitPrice
    this.priceWithoutTaxes = props.priceWithoutTaxes
    this.priceWithTaxes = props.priceWithTaxes
    this.taxesIncluded = props.taxesIncluded
    this.stock = props.stock

    this.computeActions()
  }

  public static create (props: ProductItemProps): ProductItem {
    return new ProductItem(props)
  }

  computeActions () {
    this.actions.push(
      ProductAction.EDIT,
      ProductAction.DELETE,
      ProductAction.VIEW_DETAILS,
      ProductAction.ADMIN_COPY_PRODUCT_ID,
    )
  }
}
