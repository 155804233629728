var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panel',{staticClass:"tw-transition-none"},[_c('v-expansion-panel-header',{staticClass:"tw-bg-background-base tw-flex tw-justify-between tw-items-center tw-rounded tw-p-4",class:{ 'tw-cursor-normal': _setup.disabled },staticStyle:{"min-height":"56px"},attrs:{"disabled":_setup.disabled,"hide-actions":_setup.disabled}},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('div',{staticClass:"tw-as-body-1"},[_vm._v("\n        "+_vm._s(_setup.props.title)+"\n      ")]),_vm._v(" "),(_setup.isLoading)?_c('v-progress-circular',{staticClass:"tw-mx-2",attrs:{"width":3,"size":20,"indeterminate":"","color":"#24385e"}}):_c('div',{staticClass:"billing-documents__counter"},[_vm._v("\n        "+_vm._s(_setup.docCount)+"\n      ")])],1)]),_vm._v(" "),_c('v-expansion-panel-content',[_c('div',[_c('table',{staticClass:"tw-bg-white tw-p-4 tw-w-full"},_vm._l((_setup.documents),function(item){return _c(_setup.BillingLineLight,{key:item.id,attrs:{"billing":item},on:{"open":function($event){return _setup.emit('billing-action', {
              action: _setup.BillingAction.VIEW_DETAILS,
              billing: item,
            })},"open-opportunity":function($event){return _setup.emit('billing-action', {
              action: _setup.BillingAction.VIEW_OPPORTUNITY,
              billing: item,
            })},"more-actions":_setup.handleOpenMenu}})}),1),_vm._v(" "),(_setup.hasNextPage)?_c('div',{staticClass:"tw-w-full tw-flex tw-justify-center"},[_c('v-btn',{staticClass:"tw-w-full",attrs:{"large":"","text":"","color":"primary","loading":_setup.isFetchingNextPage},on:{"click":_setup.fetchNextPage}},[_c('v-icon',{attrs:{"left":"","color":"primary"}},[_vm._v("\n            expand_more\n          ")]),_vm._v("Charger plus\n          d'articles\n        ")],1)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }