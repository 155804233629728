import { ParcelAddress } from '../../Enum/ParcelAddress';

enum ClientState {
  SYNCED = 'synced',
  PENDING = 'pending',
  END_USER_ACTION_REQUIRED = 'end_user_action_required',
  ERROR = 'error',
}

export interface ITiersPrestationCustomer {
  civilite?: string;
  dateNaissance?: Date | string;
  nomNaissance?: string;
  lieuNaissance?: {
    codePaysNaissance?: string;
    departementNaissance?: string;
    communeNaissance?: {
      codeCommune?: string;
      libelleCommune?: string;
    };
    country?: string;
    cityCode?: string;
    cityName?: string;
    zipCode?: string;
  };
  adressePostale: {
    codePays?: string;
    departement?: string;
    numeroVoie?: string;
    lettreVoie?: ParcelAddress;
    codeTypeVoie?: string;
    lieuDit?: string;
    libelleVoie?: string;
    complement?: string;
    commune?: {
      codeCommune?: string;
      libelleCommune?: string;
    };
    country?: string;
    cityCode?: string;
    cityName?: string;
    zipCode?: string;
  };
  coordonneeBancaire?: {
    bic?: string;
    iban?: string;
    titulaire?: string;
  };
  idClient?: string;
  registeredAt?: Date;
  status?: ClientState;
}
