import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  GuideLevelItem,
  GuideStepItem,
} from '@abby/core-legacy'
import type {
  CreatePersona,
  IGuideLevel,
  IGuideStep, Overwrite,
  ReadPersona,
} from '@abby/core-legacy'
import { GuideType } from '~/services/guide/enums/GuideType.enum'

import { configBuilderGroupA } from '~/logic/contexts/guide/domain/infrastructure/store/guideConfigGroupA'
import { configBuilderGroupB } from '~/logic/contexts/guide/domain/infrastructure/store/guideConfigGroupB'

const configMap: { [key in GuideType]: any} = {
  [GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_A]: configBuilderGroupA,
  [GuideType.ONBOARDING_MICRO_WITHOUT_VAT_GROUP_B]: configBuilderGroupB,
}

import { RootState } from '~/store/index'

type GuideLevel = Overwrite<IGuideLevel, {
  steps: Overwrite<IGuideStep, { isDisplayable?: boolean }>[]
}>

export interface GuideState {
  guideConfig: IGuideLevel[],
  guideType?: GuideType,
  canDisableTestMode?: boolean,
  disableFromBilling?: boolean,
  persona: ReadPersona | null,
  guideSteps: IGuideStep[]
  levelToOpen: GuideLevelItem | null,
  stepToOpen: GuideStepItem | null,
  modal : {
    deactivateBillingTestModeModal: boolean,
    urssafDeclarationModal: boolean,
    bankSynchroModal: boolean,
    onlinePaymentModal: boolean,
    immediateAdvanceModal: boolean
  }
}

export const state = (): GuideState => ({
  guideConfig: [],
  guideType: undefined,
  persona: {
    abbyDiscoveredFrom: '',
  },
  guideSteps: [],
  disableFromBilling: false,
  levelToOpen: null,
  stepToOpen: null,
  modal: {
    deactivateBillingTestModeModal: false,
    urssafDeclarationModal: false,
    bankSynchroModal: false,
    onlinePaymentModal: false,
    immediateAdvanceModal: false,
  },
})

export const getters: GetterTree<GuideState, RootState> = {
  modal: state => state.modal,
  countNotCompleted: (_, getters) => {
    return getters.guideConfig()?.reduce((acc: any, level: any) => {
      return [...acc, ...level.steps.filter((s: any) => !s.completedAt && s.isDisplayable)]
    }, []).length
  },
  countCompleted: (_, getters) => {
    return getters.guideConfig()?.reduce((acc: any, level: any) => {
      return [...acc, ...level.steps.filter((s: any) => s.completedAt)]
    }, []).length
  },
  guideConfig: (state, getters, _, rootGetters) => () => {
    if (!state.guideType) { return [] }
    let xpm = {}
    try {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      xpm = window.$nuxt?.$xpm || {}
    } catch (_) {
      //
    }
    const options = {
      canDisableTestMode: state.canDisableTestMode,
    }
    const configBuilder = configMap[state.guideType]
    const config = configBuilder(getters, rootGetters, xpm, options)
    return (config as any)
      .map((levelConfig: GuideLevel) => ({
        ...levelConfig,
        steps: levelConfig.steps.filter((step) => {
          return step?.isDisplayable === undefined ? true : step.isDisplayable
        }).map(s => ({
          ...s,
          isDisplayable: true,
          completedAt: state.guideSteps?.find(_s => _s.step === s.step)?.completedAt,
        })),
      }))
  },
  persona: state => state.persona,
  levelToOpen: state => state.levelToOpen,
  stepToOpen: state => state.stepToOpen,
  disableFromBilling: state => state.disableFromBilling,
}

export const mutations: MutationTree<GuideState> = {
  SET_GUIDE_CONFIG (state, guideConfig: IGuideLevel[]) {
    state.guideConfig = guideConfig
  },
  SET_GUIDE_STEPS (state, guideSteps: IGuideStep[]) {
    state.guideSteps = guideSteps
  },
  SET_PERSONA (state, persona: ReadPersona) {
    state.persona = persona
  },
  SET_LEVEL_TO_OPEN (state, value: GuideLevelItem | null) {
    state.levelToOpen = value
  },
  SET_STEP_TO_OPEN (state, value: GuideStepItem | null) {
    state.stepToOpen = value
  },
  SET_MODAL_KEY (state, { key, value }: { key: keyof GuideState['modal'], value: boolean }) {
    state.modal[key] = value
  },
  RESET (_currentState: GuideState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
  SET_GUIDE_TYPE (state: GuideState, guideType: GuideType) {
    state.guideType = guideType
  },
  SET_CAN_DISABLE_TEST_MODE (state: GuideState, canDisableTestMode: boolean) {
    state.canDisableTestMode = canDisableTestMode
  },
  SET_DISABLE_FROM_BILLING (state: GuideState, disablefromBilling: boolean) {
    state.disableFromBilling = disablefromBilling
  },
}

export const actions: ActionTree<GuideState, RootState> = {
  async fetchGuideSteps ({ commit, getters }) {
    try {
      commit('SET_GUIDE_CONFIG', getters.guideConfig())
      const results = await this.$api.guideStep.getAll()
      commit('SET_GUIDE_STEPS', results)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async fetchPersona ({ commit }) {
    try {
      const persona = await this.$api.persona.get()
      if (!persona) { return }
      delete persona.__v
      commit('SET_PERSONA', persona)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async updatePersona ({ commit }, payload: CreatePersona) {
    try {
      const persona = await this.$api.persona.update(payload)
      delete persona.__v
      commit('SET_PERSONA', persona)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async completeStep ({ commit, state }, payload: { step: GuideStepItem, skip?: boolean}) {
    try {
      if (this.$help.hasCompleted(payload.step)) { return }
      const result = await this.$api.guideStep.completeStep(payload.step, { skip: payload.skip })
      commit('SET_GUIDE_STEPS', [
        ...state.guideSteps,
        result,
      ])
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  setLevelToOpen ({ commit }, value: GuideLevelItem | null) {
    commit('SET_LEVEL_TO_OPEN', value)
  },
  setStepToOpen ({ commit }, value: GuideStepItem | null) {
    commit('SET_STEP_TO_OPEN', value)
    localStorage.setItem('last_guide_step_opened', value || '')
  },
  goToNextStep ({ commit, getters }, { level, step } : { level: GuideLevelItem, step: GuideStepItem }) {
    const levels = getters.guideConfig()
    const levelGroup = levels.find((s: any) => s.level === level)
    const stepIndex = levelGroup.steps.findIndex((s: any) => s.step === step)
    const nextStep = levelGroup.steps[stepIndex + 1]?.step
    commit('SET_STEP_TO_OPEN', nextStep)
  },
  closeDeactivateBillingTestModeModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'deactivateBillingTestModeModal', value: false })
    commit('SET_DISABLE_FROM_BILLING', false)
  },
  closeUrssafDeclarationModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'urssafDeclarationModal', value: false })
  },
  closeBankSynchroModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'bankSynchroModal', value: false })
  },
  closeOnlinePaymentModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'onlinePaymentModal', value: false })
  },
  closeImmediateAdvanceModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'immediateAdvanceModal', value: false })
  },
  openDeactivateBillingTestModeModal ({ commit }, fromBilling: boolean = false) {
    commit('SET_MODAL_KEY', { key: 'deactivateBillingTestModeModal', value: true })
    commit('SET_DISABLE_FROM_BILLING', fromBilling)
  },
  openUrssafDeclarationModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'urssafDeclarationModal', value: true })
  },
  openBankSynchroModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'bankSynchroModal', value: true })
  },
  openOnlinePaymentModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'onlinePaymentModal', value: true })
  },
  openImmediateAdvanceModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'immediateAdvanceModal', value: true })
  },
  setGuideType ({ commit }, guideType: GuideType) {
    commit('SET_GUIDE_TYPE', guideType)
  },
  setCanDisableTestMode ({ commit }, canDisableTestMode: boolean) {
    commit('SET_CAN_DISABLE_TEST_MODE', canDisableTestMode)
  },
}
