import { AdvanceMode } from '../enums/estimate';

enum AdvanceModeLegacy {
  POURCENTAGE = 1,
  AMOUNT = 2,
}

export const mapAdvanceModeToLegacyAdvanceMode = {
  [AdvanceMode.PERCENTAGE]: AdvanceModeLegacy.POURCENTAGE,
  [AdvanceMode.AMOUNT]: AdvanceModeLegacy.AMOUNT,
};

export const mapAdvanceModeLegacyToAdvanceMode = {
  [AdvanceModeLegacy.POURCENTAGE]: AdvanceMode.PERCENTAGE,
  [AdvanceModeLegacy.AMOUNT]: AdvanceMode.AMOUNT,
};
