import { ReadContact, ReadCustomerInformations } from '@abby/shared'
import { ContactTPForm } from '~/services/client/contact/forms/ContactTP.form'

export class ContactTPFormMapper {
  public static toDomain (
    contact: ReadContact,
    customer: ReadCustomerInformations,
  ): ContactTPForm {
    return ContactTPForm.create({
      id: customer.id,
      civility: customer.civility,
      birthname: customer.birthname,
      lastname: customer.lastname,
      firstname: customer.firstname,
      birthCountry: customer.birthCountry,
      birthZipCode: customer.birthZipCode,
      birthCity: customer.birthCity,
      birthCityCode: customer.birthCityCode,
      birthInseeDepartmentCode: customer.birthInseeDepartmentCode,
      birthDate: customer.birthDate,
      mobilePhone: customer.mobilePhone,
      email: customer.email,
      address: customer.address,
      bankInformation: customer.bankInformation,
      idClient: customer.idClient,
      contactId: contact.id,
    })
  }
}
