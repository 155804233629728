import { ReadOrganization } from '@abby/shared'
import { OrganizationForm } from '~/services/client/organization/forms/Organization.form'

export class OrganizationFormMapper {
  public static toDomain (doc: ReadOrganization): OrganizationForm {
    return OrganizationForm.create({
      id: doc.id,
      name: doc.name,
      commercialName: doc.commercialName,
      emails: doc.emails,
      siret: doc.siret,
      vatNumber: doc.vatNumber,
      billingAddress: doc.billingAddress,
      deliveryAddress: doc.deliveryAddress,
      bankInformations: doc.bankInformations,
      preferences: {
        language: doc.language,
        currency: doc.currency,
        paymentMethods: doc.paymentMethods,
      },
    })
  }
}
