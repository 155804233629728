import { ActionTree, GetterTree, MutationTree } from 'vuex'
import get from 'lodash/get'
import type { IExperience } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ExperienceState {
  experiences: IExperience[],
  customExperience: IExperience | null,
}

export const state = (): ExperienceState => ({
  experiences: [],
  customExperience: null,
})

export const getters: GetterTree<ExperienceState, RootState> = {
  experiences: (state: ExperienceState) => state.experiences,
  customExperience: (state: ExperienceState) => state.customExperience,
  localCustomExperience: (state: ExperienceState) => (rootElement: string) => {
    return get(state.customExperience?.data, rootElement)
  },
}

export const mutations: MutationTree<ExperienceState> = {
  SET_EXPERIENCES (state, value: IExperience[]): void {
    state.experiences = value
  },
  SET_CUSTOM_EXPERIENCE (state, value: IExperience): void {
    state.customExperience = value
  },
  RESET (_currentState: ExperienceState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<ExperienceState, RootState> = {
  async fetchAll ({ commit }) {
    try {
      const result = await this.$api.experiences.getAll()
      commit('SET_EXPERIENCES', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
  async fetchUserExperience ({ commit }, activityId: number | null) {
    try {
      const result = await this.$api.experiences.getUserExperience(activityId)
      commit('SET_CUSTOM_EXPERIENCE', result)
    } catch (e) {
      this.$alertsManager.autoError(e as any)
    }
  },
}
