import { Organization } from '~/services/client/organization/entities/Organization.entity'
import {
  useOrganizationRepository,
} from '~/logic/contexts/client/infrastructure/repository/organization.repository'
import { useClientStore } from '~/logic/contexts/client/infrastructure/store/clientPinia.store'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useFetchOrganizationStatistics = () => {
  const organizationRepository = useOrganizationRepository()
  const clientStore = useClientStore()
  const alertManager = useAlertManager()

  return async (organization: Organization) => {
    try {
      const organizationStatistic = await organizationRepository.getOrganizationStatisticsById(organization.id)
      clientStore.setOrganizationStatistics(organizationStatistic)
    } catch (error) {
      alertManager.autoError(error)
    }
  }
}
