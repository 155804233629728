import { render, staticRenderFns } from "./TimeRecordsGroupItem.vue?vue&type=template&id=e88e83a4"
import script from "./TimeRecordsGroupItem.vue?vue&type=script&setup=true&lang=ts"
export * from "./TimeRecordsGroupItem.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TimeRecordsGroupItem.vue?vue&type=style&index=0&id=e88e83a4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../common/temp/webapp-legacy/node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__pretti_ij34aobnxlkdmmy5vni36wgymq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports