import { ClientState } from '@abby/shared'

type CustomerCompany = {
  name: string;
  commercialName?: string;
}

type TierPrestation = {
  idClient?: string;
  registeredAt?: Date;
  status: ClientState;
}

export type BillingCustomerProps = {
  id: string;
  firstname?: string;
  lastname?: string;
  company?: CustomerCompany;
  legacyId: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  token: string;
  tiersPrestation?: TierPrestation;
  tiersPrestationIsActivated?: boolean;
  organizationId?: string;
  contactId?: string;
  // eslint-disable-next-line no-use-before-define
  parent?: BillingCustomer;
  isDefault?: boolean;
  test?: boolean;
}

export class BillingCustomer {
  readonly id: string;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly company?: CustomerCompany;
  readonly email?: string;
  readonly phone?: string;
  readonly address?: string;
  readonly city?: string;
  readonly country?: string;
  readonly legacyId: string;
  readonly zipCode?: string;
  readonly token: string;
  readonly tiersPrestation?: TierPrestation;
  readonly tiersPrestationIsActivated?: boolean;
  readonly organizationId?: string;
  readonly contactId?: string;
  readonly parent?: BillingCustomer;
  readonly displayName: string;
  readonly isDefault?: boolean;
  readonly test?: boolean;
  readonly type?: 'organization' | 'contact';

  private constructor (props: BillingCustomerProps) {
    this.id = props.id
    this.firstname = props.firstname
    this.lastname = props.lastname
    this.company = props.company
    this.email = props.email
    this.phone = props.phone
    this.address = props.address
    this.city = props.city
    this.country = props.country
    this.zipCode = props.zipCode
    this.token = props.token
    this.tiersPrestation = props.tiersPrestation
    this.tiersPrestationIsActivated = props.tiersPrestationIsActivated
    this.legacyId = props.legacyId
    this.organizationId = props.organizationId
    this.contactId = props.contactId
    this.parent = props.parent
    this.displayName = `${this.firstname || ''} ${this.lastname || ''}`.trim() || this.company?.name || this.company?.commercialName || ''
    this.isDefault = props.isDefault
    this.test = !!props.test
    this.type = props.organizationId && !props.contactId ? 'organization' : 'contact'
  }

  public static create (props: BillingCustomerProps): BillingCustomer {
    return new BillingCustomer(props)
  }
}
