
import { Component, Getter, Vue } from 'nuxt-property-decorator'
import { ICompany, IUser } from '@abby/core-legacy'
import { AppState } from '~/store/app'

@Component({
  components: {
    UserAvatar: () => import('~/components/user/_common/ui/UserAvatar.vue'),
  },
})
export default class UserNavigationMenu extends Vue {
  @Getter('auth/user')
  currentUser!: IUser

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Getter('app/loading') loading!: AppState['loading']

  @Getter('company/company')
  company!: ICompany

  get token () {
    // @ts-ignore
    return this.$api.getToken().replace('Bearer ', '')
  }

  get isDev () {
    return this.$config.nodeEnv === 'development'
  }

  get isStaging () {
    return this.$config.nodeEnv === 'staging'
  }

  get fullName () {
    return `${this.currentUser?.firstname} ${this.currentUser?.lastname}`.trim()
  }

  get email () {
    return this.currentUser?.email
  }

  get userLocalDashboardUrl () {
    return `http://dashboard.localhost:3000/clients/list?id=${this.currentUser?.id}`
  }

  get companyId () {
    return this.company?.id
  }

  get userId () {
    return this.currentUser?.id
  }

  async copyData (value: string) {
    await this.$clipboard.copy(value)
    this.$alertsManager.info('Copié avec succès')
  }

  redirectToUrl (url: string) {
    window.open(url, '_blank')
  }

  logoutAccountFromDashboard () {
    const id = this.currentUser?.id
    this.logout()
    let baseURL
    if (this.$config.nodeEnv === 'development') {
      baseURL = 'http://dashboard.localhost:3000'
    } else if (this.$config.nodeEnv === 'staging') {
      baseURL = 'https://dashboard-next.abby.fr'
    } else {
      baseURL = 'https://dashboard.abby.fr'
    }
    window.open(`${baseURL}/clients/${id}`, '_self')
  }

  async logout () {
    try {
      await this.$store.dispatch('auth/logout')
    } catch (e: any) {
      this.$alertsManager.autoError(e as any)
    }
  }
}
