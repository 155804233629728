import { Editor } from '@tiptap/vue-2'
// We have to import theses because for some reason, theses extension methods are not recognized by the CI here
// @ts-ignore
import { Ai } from '@tiptap-pro/extension-ai'
// @ts-ignore
import { Color } from '@tiptap/extension-color'

export const useEditorAiProposition = () => {
  return ({ editor, oldEditorContent }: { editor: Editor, oldEditorContent: string }) => {
    return [
      {
        text: 'Accepter',
        icon: 'check',
        action: () => {
          editor
            .chain()
            .focus()
            .selectAll()
            .unsetColor()
            .removeEmptyTextStyle()
            .aiAccept()
            .blur()
            .run()
        },
      },
      {
        text: 'Refuser',
        icon: 'close',
        action: () => {
          editor
            .chain()
            .focus()
            .setContent(oldEditorContent)
            .removeEmptyTextStyle()
            .aiReject()
            .run()
        },
      },
      {
        text: 'Régénérer',
        icon: 'refresh',
        action: () => {
          editor.chain().focus().aiRegenerate().run()
        },
      },
    ]
  }
}
