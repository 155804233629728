import { Plugin } from '@nuxt/types'
import {
  AbbyPlans,
  GuideStepItem,
  GuideLevelItem,
  IBillingConfiguration,
  IPersona,
  PersonaFeature,
  PlanFeature,
  StripeProductType,
} from '@abby/core-legacy'
import type { ICompany, IGuideLevel } from '@abby/core-legacy'
import { IAuthUser } from '~/store/auth'
import { HelpArticle } from '~/services/help/enums/HelpArticle.enum'

export interface IHelpdeskArticlePreview {
  title: string,
  content: string,
  url: string,
  id: string | null,
}

export interface HelpInstance {
  openArticle(id?: HelpArticle | string | null, params?: { inApp?: boolean }): void;
  openHelpdesk(query?: string): void;
  searchArticles(query: string): Promise<IHelpdeskArticlePreview[]>;
  toggleCalendly(value?: boolean): void;
  toggleHelpCenter(value?: boolean): void;
  toggleChat(value: boolean, params?: { open?: boolean, openConversationsList?: boolean, openNewConversation?: boolean, newConversationMessage?: string }): void;
  updateChatData(params?: { hide?: boolean }, data?: object, companyData?: object): void
  onChatHide(callback: () => void): void
  onChatShow(callback: () => void): void
  init(params?: { hide?: boolean }): void
  sendChatEvent(event: string, metadata?: any): void
  hasCompleted(step: GuideStepItem): boolean
  completeGuideStep(step: GuideStepItem, skip?: boolean): Promise<void>
  goToNextStep(step: GuideStepItem): Promise<void>
  waitUntilIntercomIsSet(callback?: () => any): Promise<void>
  waitUntilAppIsLoaded(callback?: () => any): Promise<void>
  disconnectIntercom(): void
}

const HelpPlugin: Plugin = ({ store, $planManager }, inject) => {
  const getUser = (): IAuthUser => store.getters['auth/user']
  const getUserHashIntercom = (): string => store.getters['auth/userHashIntercom']
  const getIsInCreation = (): IAuthUser => store.getters['company/isInCreation']
  const getCompany = (): ICompany => store.getters['company/company']
  const getIsConnectedFromDashboard = (): boolean => store.getters['auth/connectedFromDashboard']
  const getPersona = (): IPersona => store.getters['guide/persona']
  const getBillingConfiguration = (): IBillingConfiguration => store.getters['billingConfiguration/billingConfiguration']

  const updateChatData = async (params?: { hide?: boolean }, data?: object, companyData?: object) => {
    const user = getUser()
    const company = getCompany()
    const isInCreation = getIsInCreation()
    const persona = getPersona()
    const billingConfiguration = getBillingConfiguration()
    let plan = ''
    const trial = $planManager?.isTrial?.() ? '(Trial)' : ''
    const whichPlanCompanyHas = $planManager?.whichPlanCompanyHas?.()
    if (whichPlanCompanyHas === AbbyPlans.ABBY_START) {
      plan = `Start ${trial}`.trim()
    } else if (whichPlanCompanyHas === AbbyPlans.ABBY_PRO) {
      plan = `Pro ${trial}`.trim()
    } else if (whichPlanCompanyHas === AbbyPlans.ABBY_BUSINESS) {
      plan = `Business ${trial}`.trim()
    } else if ($planManager?.has?.(StripeProductType.ABBY_PLUS) && isInCreation) {
      plan = 'Plus'
    } else if ($planManager?.has?.(StripeProductType.ABBY_CONTACT) && isInCreation) {
      plan = 'Contact'
    } else if ($planManager?.displayAbbyCreationPlans()) {
      plan = 'Choix création plan'
    }
    let chatAccess = ''
    if ($planManager?.hasAccessTo(PlanFeature.SUPPORT_CHAT) || $planManager?.displayAbbyCreationPlans()) {
      chatAccess = 'Oui'
    }
    if ($planManager?.hasAccessTo(PlanFeature.SUPPORT_CHAT_PRIORITY)) {
      chatAccess = 'Oui (prioritaire)'
    }

    const hide = $planManager?.displayAbbyCreationPlans() ? false : params?.hide

    let userHash = null
    if (user) {
      await store.dispatch('auth/fetchUserHashIntercom')
      userHash = getUserHashIntercom()
    }
    // @ts-ignore
    window.intercomSettings = {
      app_id: 'hu6d8oic',
      hide_default_launcher: hide,
      user_id: user?.id,
      user_hash: userHash,
    }

    // @ts-ignore
    window?.Intercom?.('update', {
      app_id: 'hu6d8oic',
      name: user?.fullName,
      email: user?.email,
      phone: user?.phone,
      user_id: user?.id,
      user_hash: userHash,
      hide_default_launcher: hide,
      ...(user?.profilePicture?.url
        ? {
          avatar: {
            type: 'avatar',
            image_url: user?.profilePicture?.url,
          },
        }
        : {}),
      created_at: user?.createdAt,
      plan,
      is_SAP: !!persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || billingConfiguration?.tiersPrestationActivated,
      has_immediate_advance_validated: billingConfiguration?.tiersPrestationActivated && billingConfiguration?.tiersPrestationValidated,
      chatAccess,
      industry: company?.activity?.name || company?.otherActivity || '',
      company_name: company?.commercialName || company?.name,
      monthly_spend: 0,
      creation: company?.currentStep,
      account_type: isInCreation ? 'Création' : 'Gestion',
      ...data,
      company: {
        id: company?.id,
        name: company?.commercialName || company?.name,
        plan,
        chatAccess,
        monthly_spend: 0,
        user_count: 1,
        size: 1,
        website: '',
        industry: company?.activity?.name || company?.otherActivity || '',
        ...companyData,
      },
    })
  }

  const toggleHelpCenter = (value?: boolean) => {
    if (value === true) {
      store.dispatch('helpCenter/openHelpCenter')
    } else if (value === false) {
      store.dispatch('helpCenter/closeHelpCenter')
    }
  }

  const waitUntilIntercomIsSet = (callback?: () => any) => {
    if (!process.browser) { return }
    // @ts-ignore
    if (typeof window?.Intercom !== 'undefined') {
      callback?.()
    } else if (getIsConnectedFromDashboard()) {
      // Ne rien faire et quitter la boucle
    } else {
      setTimeout(() => { waitUntilIntercomIsSet(callback) }, 1000)
    }
  }

  const waitUntilAppIsLoaded = (callback?: () => any) => {
    if (!store.getters['app/appLoading']) {
      callback?.()
    } else {
      setTimeout(() => { waitUntilAppIsLoaded(callback) }, 1000)
    }
  }

  const toggleChat = async (value: boolean, params?: { open?: boolean, openConversationsList?: boolean, openNewConversation?: boolean, newConversationMessage?: string }) => {
    if (value) {
      toggleHelpCenter(false)
      // @ts-ignore
      await updateChatData({ hide: false })
      if (params?.open) {
        // @ts-ignore
        window?.Intercom?.('show')
        // @ts-ignore
      } else if (params?.openConversationsList) {
        // @ts-ignore
        window?.Intercom?.('showMessages')
      } else if (params?.openNewConversation) {
        // @ts-ignore
        window?.Intercom?.('showNewMessage', params?.newConversationMessage || undefined)
      }
    } else if (!value) {
      // @ts-ignore
      window?.Intercom?.('hide')
      // @ts-ignore
      window?.Intercom?.('update', { hide_default_launcher: true })
    }
  }

  inject('help', {
    init (params?: { hide?: boolean }) {
      waitUntilIntercomIsSet(async () => {
        await updateChatData({ hide: params?.hide })
        // @ts-ignore
        window?.Intercom?.('onUnreadCountChange', (unreadCount: number) => {
          if (unreadCount) {
            // @ts-ignore
            window.Intercom?.('update', {
              vertical_padding: 70,
            })
          }
          store.dispatch('helpCenter/updateUnreadMessages', unreadCount)
        })
      })
    },
    waitUntilIntercomIsSet,
    waitUntilAppIsLoaded,
    openArticle (id?: HelpArticle | string | null, params?: { inApp?: boolean }) {
      if (!id) {
        window?.open('https://aide.abby.fr/fr/', '_blank')
      } else if ($planManager?.hasAccessTo(PlanFeature.SUPPORT_CHAT) && params?.inApp === true) {
        // @ts-ignore
        window?.Intercom?.('showArticle', id)
      } else {
        window?.open(`https://aide.abby.fr/fr/articles/${id}`, '_blank')
      }
    },
    openHelpdesk (query?: string) {
      window?.open(`https://aide.abby.fr/fr?q=${query || ''}`, '_blank')
    },
    async searchArticles (query?: string): Promise<IHelpdeskArticlePreview[]> {
      return await new Promise((resolve) => {
        const articles: IHelpdeskArticlePreview[] = []
        const xhr = new window.XMLHttpRequest()
        xhr.responseType = 'document'
        xhr.addEventListener('load', function () {
          const searchResults = this?.response?.querySelectorAll('.collection-link') // article__preview
          searchResults.forEach(function (item: HTMLElement) {
            const title = item?.getElementsByClassName('t__h3')?.[0]?.innerHTML?.trim()?.replaceAll('class="font-bold"', 'class="text-search-highlighter"')
            const content = item?.getElementsByClassName('paper__preview')?.[0]?.innerHTML?.trim().replaceAll('class="font-bold"', 'class="text-search-highlighter"')
            const url = `https://aide.abby.fr${item?.getAttribute('href')?.trim()}` || ''
            const id = url.substring(url.indexOf('articles/') + 9, url.indexOf('-')) || null
            articles.push({ title, content, url, id })
          })
          resolve(articles || [])
        })
        xhr.open('GET', `/aide/?q=${query}`)
        xhr.send(null)
      })
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleCalendly (value?: boolean) {

    },
    toggleHelpCenter (value?: boolean) {
      toggleHelpCenter(value)
    },
    toggleChat (value: boolean, params?: { open?: boolean, openConversationsList?: boolean, openNewConversation?: boolean, newConversationMessage?: string }) {
      toggleChat(value, params)
    },
    async updateChatData (params?: { hide?: boolean }, data?: object, companyData?: object) {
      await updateChatData(params, data, companyData)
    },
    onChatShow (callback: () => void) {
      // @ts-ignore
      window?.Intercom?.('onShow', callback)
    },
    onChatHide (callback: () => void) {
      // @ts-ignore
      window?.Intercom?.('onHide', callback)
    },
    sendChatEvent (event: string, metadata?: any) {
      // @ts-ignore
      window?.Intercom?.('trackEvent', event, metadata || undefined)
    },
    disconnectIntercom () {
      // @ts-ignore
      window?.Intercom?.('shutdown')
    },
    hasCompleted (step: GuideStepItem) {
      const guideConfig = store.getters['guide/guideConfig']() as IGuideLevel[]
      return guideConfig.find(gl => gl.steps.find(gs => gs.completedAt && gs.step === step))
    },
    completeGuideStep (step: GuideStepItem, skip?: boolean) {
      return store.dispatch('guide/completeStep', { step, skip })
    },
    goToNextStep (level: GuideLevelItem, step: GuideStepItem) {
      return store.dispatch('guide/goToNextStep', { level, step })
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $help: HelpInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $help: HelpInstance
  }
  interface Context {
    $help: HelpInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $help: HelpInstance
  }
}

export default HelpPlugin
