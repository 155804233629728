import { ReadCustomerInformations } from '@abby/shared'
import {
  ClientCopyTiersPrestationMessage,
} from '~/services/client/_common/valueObjects/ClientCopyTiersPrestationEmailMessage.valueObject'

export class ClientCopyTiersPrestationMessageHttpMapper {
  static toDomain (result: { title: string, customer: ReadCustomerInformations, subject: string, content: string, hint?: string }): ClientCopyTiersPrestationMessage {
    return ClientCopyTiersPrestationMessage.create(result)
  }
}
