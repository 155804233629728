import { Plugin } from '@nuxt/types'
// eslint-disable-next-line import/named
import { StripeConstructorOptions, Stripe } from '@stripe/stripe-js'

const stripePlugin: Plugin = ({ $config }, inject) => {
  const defaults: StripeConstructorOptions = {
    locale: 'fr',
  }

  try {
    const productionKey = 'pk_live_51JADtrI21zkxZU1xtgzbRvHF1VpXQWLch3T6OOUCrv49FPzvviV0TxrQaEVOBnbqWfonvRl60f4QvWW395ztyTIN00BJaCm9GH'
    const testKey = 'pk_test_51JADtrI21zkxZU1xo5AhDfqw36QY7DizRkRML00vbUjOawIRQ9HC08mHu3m604vV1DBlBGjbdTQNbH0usN6ti4Bi00Ysv4eYN3'

    const stripeKey = $config.nodeEnv === 'production' ? productionKey : testKey
    let stripe: Stripe | null = null
    inject('stripe', {
      get stripe () {
        return stripe
      },
      async loadStripe () {
        if (stripe) { return stripe }
        const { loadStripe } = await import('@stripe/stripe-js/pure')
        stripe = await loadStripe(stripeKey, {
          ...defaults,
        })
        return stripe
      },
    })
  } catch (e) {
    if (e instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(`[nuxt-stripe-module] ${e.message}`)
    }

  }
}

export type AbbyStripe = { stripe: Stripe, loadStripe: () => Promise<void> }

declare module 'vue/types/vue' {
  interface Vue {
    $stripe: AbbyStripe;
  }
}

// Nuxt 2.9+
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $stripe: AbbyStripe;
  }

  interface Context {
    $stripe: AbbyStripe;
  }
}

export default stripePlugin
