import { IExperience } from '../Interface/schemas/IExperience';
import { CompanyExperience } from './CompanyExperience';

export const ExperiencesList: Array<IExperience> = [

  /**
   * CLASSIC
   */
  {
    experience: CompanyExperience.CLASSIC,
    isActivated: true,
    data: {
      defaultReferent: 'enzo@app-abby.com',
      register: {
        cardText: 'Je veux choisir mon activité',
        cardImg: '/creation-classic.png',
      },
      plans: {
        abbyPlans: {
          haveCallExpert: true,
        },
      },
      upsells: {
        haveCallExpert: true,
        sirenPhrase: 'Passez avant tous les autres. Nous prioriserons votre dossier et vos démarches seront accélérées',
        priorityPhrase: 'Dossier de création prioritaire',
      },
      creationForm: {
        companyInfo: {
          showStep: true,
        },
        activity: {
          showCommercialNameField: true,
          showPeriodicityField: true,
          showSedentaryActivityField: true,
          showMainActivityFields: true,
          showSelectedActivityCard: false,
          showSwitchDisplayAllFields: false,
          displayAllFieldsValue: true,
        },
        socialDeclaration: {
          showHealthInsuranceSchemeField: true,
        },
      },
    },
  },

  /**
   * DELIVERER
   */
  {
    experience: CompanyExperience.DELIVERER,
    isActivated: true,
    activitiesRange: [765, 766, 830, 831, 832, 833, 834, 835, 836, 837, 838, 839, 840, 841],
    data: {
      defaultReferent: 'dylan@app-abby.com',
      register: {
        component: 'RegisterDeliverer',
        cardText: 'Je veux devenir livreur',
        cardImg: '/creation-deliverer.png',
      },
      plans: {
        abbyPlans: {
          haveCallExpert: false,
          getSirenWithoutAbbyPlus: 'Supérieur à 14 jours',
          getSirenWithAbbyPlus: '24h / 48h',
        },
      },
      upsells: {
        haveCallExpert: false,
        sirenPhrase: 'Passez avant tous les autres : obtenez votre SIREN en 24h / 48h. Sans Abby Plus, cela prendrait 14 jours',
        priorityPhrase: 'SIREN en 24h / 48h',
      },
      files: {
        justificatif_xp_diplome: {
          name: 'Attestation de conduite',
          fileName: 'demande_autorisation_exercice_transport',
          instructions: [
            'Veuillez déposer un justificatif attestant de votre inscription au Registre des Transporteurs et des Loueurs. <a target="_blank" href="https://agence-juridique.com/articles/quels-statuts-choisir-pour-devenir-livreur-coursier-independant">Plus d\'infos ici</a>',
          ],
        },
      },
      creationForm: {
        companyInfo: {
          showStep: false,
          addressTypeValue: 1,
        },
        activity: {
          showCommercialNameField: false,
          showPeriodicityField: true,
          showSedentaryActivityField: true,
          showMainActivityFields: true,
          showSelectedActivityCard: true,
          showSwitchDisplayAllFields: true,
          displayAllFieldsValue: false,
          selectedActivityIsRegulatedContent: '<span class="tw-text-red-base">Attention ! Vous pouvez devenir <span class="tw-font-bold">coursier motorisé</span> mais vous ne serez pas couverts en cas de problème si vous n\'êtes pas inscrit au <span class="tw-font-bold">Registre des Transporteurs et des Loueurs.</span> '
            + '<a target="_blank" href="https://agence-juridique.com/articles/quels-statuts-choisir-pour-devenir-livreur-coursier-independant">Plus d\'infos ici</a>.</span>',
          periodicityValue: 1,
          sedentaryActivityValue: true,
          mainActivityDescriptionValue: 7,
          creationActivityDescriptionValue: 'Services',
          hasEmployeesDefault: {
            value: false,
          },
        },
        socialDeclaration: {
          showHealthInsuranceSchemeField: false,
          healthInsuranceSchemeValue: 4,
          otherHealthInsuranceSchemeValue: 'Non concerné',
        },
      },
    },
  },

];
