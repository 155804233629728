import { ISuggestedAccountingAccount } from '../models/ITransaction';

export const MappingSuggestedAccountingAccount: Array<ISuggestedAccountingAccount> = [
  {
    id: 303,
    name: 'Retraits, Chq. et Vir.',
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901902,
  },
  {
    id: 326,
    name: 'Virements internes',
    abbyProfessionalAccountingAccountNumber: 580000,
    abbyPersonalAccountingAccountNumber: 901904,
  },
  {
    id: 88,
    name: 'Chèques',
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901901,
  },
  {
    id: 85,
    name: 'Retraits',
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901902,
  },
  {
    id: 78,
    name: 'Virements',
    abbyProfessionalAccountingAccountNumber: 580000,
    abbyPersonalAccountingAccountNumber: 901903,
  },
  {
    id: 170,
    name: 'Loisirs & Sorties',
    abbyProfessionalAccountingAccountNumber: 625300,
    abbyPersonalAccountingAccountNumber: 901806,
  },
  {
    id: 320,
    name: 'Sortie au restaurant',
    abbyProfessionalAccountingAccountNumber: 625700,
    abbyPersonalAccountingAccountNumber: 901807,
  },
  {
    id: 310,
    name: 'Sports d\'hiver',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901809,
  },
  {
    id: 269,
    name: 'Divertissements',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901802,
  },
  {
    id: 263,
    name: 'Hôtels',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901805,
  },
  {
    id: 249,
    name: 'Voyages / Vacances',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901810,
  },
  {
    id: 244,
    name: 'Sorties culturelles',
    abbyProfessionalAccountingAccountNumber: 625600,
    abbyPersonalAccountingAccountNumber: 901811,
  },
  {
    id: 242,
    name: 'Sport',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901808,
  },
  {
    id: 227,
    name: 'Bars / Clubs',
    abbyProfessionalAccountingAccountNumber: 625300,
    abbyPersonalAccountingAccountNumber: 901801,
  },
  {
    id: 226,
    name: 'Hobbies',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901804,
  },
  {
    id: 224,
    name: 'Frais Animaux',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901803,
  },
  {
    id: 223,
    name: 'Loisirs & Sorties - Autres',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901806,
  },
  {
    id: 165,
    name: 'Auto & Transports',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901202,
  },
  {
    id: 309,
    name: 'Péage',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901208,
  },
  {
    id: 288,
    name: 'Entretien véhicule',
    abbyProfessionalAccountingAccountNumber: 625200,
    abbyPersonalAccountingAccountNumber: 901206,
  },
  {
    id: 264,
    name: 'Location de véhicule',
    abbyProfessionalAccountingAccountNumber: 625200,
    abbyPersonalAccountingAccountNumber: 901207,
  },
  {
    id: 251,
    name: 'Stationnement',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901209,
  },
  {
    id: 247,
    name: 'Assurance véhicule',
    abbyProfessionalAccountingAccountNumber: 625200,
    abbyPersonalAccountingAccountNumber: 901201,
  },
  {
    id: 198,
    name: 'Billets d\'avion',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901203,
  },
  {
    id: 197,
    name: 'Billets de train',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901204,
  },
  {
    id: 196,
    name: 'Transports en commun',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901210,
  },
  {
    id: 87,
    name: 'Carburant',
    abbyProfessionalAccountingAccountNumber: 625200,
    abbyPersonalAccountingAccountNumber: 901205,
  },
  {
    id: 84,
    name: 'Frais de déplacements - Autres',
    abbyProfessionalAccountingAccountNumber: 625100,
    abbyPersonalAccountingAccountNumber: 901202,
  },
  {
    id: 162,
    name: 'Achats & Shopping',
    abbyProfessionalAccountingAccountNumber: 605001,
    abbyPersonalAccountingAccountNumber: 901001,
  },
  {
    id: 441888,
    name: 'Licenses',
    abbyProfessionalAccountingAccountNumber: 613001,
    abbyPersonalAccountingAccountNumber: 901006,
  },
  {
    id: 319,
    name: 'Films & DVDs',
    abbyProfessionalAccountingAccountNumber: 618000,
    abbyPersonalAccountingAccountNumber: 901004,
  },
  {
    id: 318,
    name: 'Musique',
    abbyProfessionalAccountingAccountNumber: 618000,
    abbyPersonalAccountingAccountNumber: 901008,
  },
  {
    id: 272,
    name: 'Vêtements/Chaussures',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901009,
  },
  {
    id: 262,
    name: 'Articles de sport',
    abbyProfessionalAccountingAccountNumber: 600000,
    abbyPersonalAccountingAccountNumber: 901002,
  },
  {
    id: 243,
    name: 'Livres',
    abbyProfessionalAccountingAccountNumber: 618000,
    abbyPersonalAccountingAccountNumber: 901007,
  },
  {
    id: 186,
    name: 'Achats & Shopping - Autres',
    abbyProfessionalAccountingAccountNumber: 605001,
    abbyPersonalAccountingAccountNumber: 901001,
  },
  {
    id: 184,
    name: 'Matériel',
    abbyProfessionalAccountingAccountNumber: 605000,
    abbyPersonalAccountingAccountNumber: 901001,
  },
  {
    id: 183,
    name: 'Cadeaux',
    abbyProfessionalAccountingAccountNumber: 605000,
    abbyPersonalAccountingAccountNumber: 901003,
  },
  {
    id: 168,
    name: 'Alimentation & Restau.',
    abbyProfessionalAccountingAccountNumber: 625300,
    abbyPersonalAccountingAccountNumber: 901104,
  },
  {
    id: 313,
    name: 'Café',
    abbyProfessionalAccountingAccountNumber: 625300,
    abbyPersonalAccountingAccountNumber: 901102,
  },
  {
    id: 273,
    name: 'Supermarché / Epicerie',
    abbyProfessionalAccountingAccountNumber: 625300,
    abbyPersonalAccountingAccountNumber: 901105,
  },
  {
    id: 260,
    name: 'Restauration rapide',
    abbyProfessionalAccountingAccountNumber: 625700,
    abbyPersonalAccountingAccountNumber: 901807,
  },
  {
    id: 188,
    name: 'Alimentation - Autres',
    abbyProfessionalAccountingAccountNumber: 625700,
    abbyPersonalAccountingAccountNumber: 901806,
  },
  {
    id: 83,
    name: 'Restaurants',
    abbyProfessionalAccountingAccountNumber: 625700,
    abbyPersonalAccountingAccountNumber: 901104,
  },
  {
    id: 161,
    name: 'Logement',
    abbyProfessionalAccountingAccountNumber: 613200,
    abbyPersonalAccountingAccountNumber: 901710,
  },
  {
    id: 328,
    name: 'Charges diverses',
    abbyProfessionalAccountingAccountNumber: 615000,
    abbyPersonalAccountingAccountNumber: 901702,
  },
  {
    id: 323,
    name: 'Extérieur et jardin',
    abbyProfessionalAccountingAccountNumber: 615000,
    abbyPersonalAccountingAccountNumber: 901707,
  },
  {
    id: 293,
    name: 'Eau',
    abbyProfessionalAccountingAccountNumber: 606100,
    abbyPersonalAccountingAccountNumber: 901704,
  },
  {
    id: 246,
    name: 'Assurance habitation',
    abbyProfessionalAccountingAccountNumber: 616000,
    abbyPersonalAccountingAccountNumber: 901701,
  },
  {
    id: 222,
    name: 'Entretien',
    abbyProfessionalAccountingAccountNumber: 615000,
    abbyPersonalAccountingAccountNumber: 901706,
  },
  {
    id: 221,
    name: 'Décoration',
    abbyProfessionalAccountingAccountNumber: 625600,
    abbyPersonalAccountingAccountNumber: 901703,
  },
  {
    id: 220,
    name: 'Bureaux - Autres',
    abbyProfessionalAccountingAccountNumber: 625001,
    abbyPersonalAccountingAccountNumber: 901709,
  },
  {
    id: 218,
    name: 'Gaz',
    abbyProfessionalAccountingAccountNumber: 606100,
    abbyPersonalAccountingAccountNumber: 901708,
  },
  {
    id: 217,
    name: 'Electricité',
    abbyProfessionalAccountingAccountNumber: 606100,
    abbyPersonalAccountingAccountNumber: 901705,
  },
  {
    id: 216,
    name: 'Loyer / Locaux',
    abbyProfessionalAccountingAccountNumber: 613200,
    abbyPersonalAccountingAccountNumber: 901710,
  },
  {
    id: 159,
    name: 'Impôts & Taxes',
    abbyProfessionalAccountingAccountNumber: 635100,
    abbyPersonalAccountingAccountNumber: 901602,
  },
  {
    id: 441988,
    name: 'TVA',
    abbyProfessionalAccountingAccountNumber: 445510,
    abbyPersonalAccountingAccountNumber: 901606,
  },
  {
    id: 302,
    name: 'Taxes',
    abbyProfessionalAccountingAccountNumber: 635100,
    abbyPersonalAccountingAccountNumber: 901605,
  },
  {
    id: 209,
    name: 'CFE',
    abbyProfessionalAccountingAccountNumber: 635120,
    abbyPersonalAccountingAccountNumber: 901605,
  },
  // TODO we should change that when we have society
  {
    id: 208,
    name: 'IS',
    abbyProfessionalAccountingAccountNumber: 635100,
    abbyPersonalAccountingAccountNumber: 901602,
  },
  {
    id: 207,
    name: 'Amendes',
    abbyProfessionalAccountingAccountNumber: 108200,
    abbyPersonalAccountingAccountNumber: 901601,
  },
  {
    id: 206,
    name: 'Impôts & Taxes - Autres',
    abbyProfessionalAccountingAccountNumber: 635100,
    abbyPersonalAccountingAccountNumber: 901602,
  },
  {
    id: 171,
    name: 'Abonnements',
    abbyProfessionalAccountingAccountNumber: 613001,
    abbyPersonalAccountingAccountNumber: 900901,
  },
  {
    id: 171,
    name: 'Abonnements',
    abbyProfessionalAccountingAccountNumber: 613001,
    abbyPersonalAccountingAccountNumber: 900901,
  },
  {
    id: 280,
    name: 'Abonnements - Autres',
    abbyProfessionalAccountingAccountNumber: 613001,
    abbyPersonalAccountingAccountNumber: 900901,
  },
  {
    id: 277,
    abbyProfessionalAccountingAccountNumber: 626000,
    abbyPersonalAccountingAccountNumber: 900905,
    name: 'Téléphonie mobile',
  },
  {
    id: 258,
    abbyProfessionalAccountingAccountNumber: 626000,
    abbyPersonalAccountingAccountNumber: 900904,
    name: 'Téléphonie fixe',
  },
  {
    id: 219,
    abbyProfessionalAccountingAccountNumber: 613001,
    abbyPersonalAccountingAccountNumber: 900902,
    name: 'Câble / Satellite',
  },
  {
    id: 180,
    abbyProfessionalAccountingAccountNumber: 626000,
    abbyPersonalAccountingAccountNumber: 900903,
    name: 'Internet',
  },
  {
    id: 315,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901503,
    name: 'Esthétique & Soins',
  },
  {
    id: 321,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901503,
    name: 'Esthétique',
  },
  {
    id: 317,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901504,
    name: 'Esthétique & Soins - Autres',
  },
  {
    id: 316,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901505,
    name: 'Spa & Massage',
  },
  {
    id: 248,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901502,
    name: 'Cosmétique',
  },
  {
    id: 235,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901501,
    name: 'Coiffeur',
  },
  {
    id: 167,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902108,
    name: 'Scolarité & Enfants',
  },
  {
    id: 267,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902101,
    name: 'Baby-sitters & Crèches',
  },
  {
    id: 266,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902104,
    name: 'Jouets',
  },
  {
    id: 259,
    abbyProfessionalAccountingAccountNumber: 164001,
    abbyPersonalAccountingAccountNumber: 902107,
    name: 'Prêt étudiant',
  },
  {
    id: 241,
    abbyProfessionalAccountingAccountNumber: 613200,
    abbyPersonalAccountingAccountNumber: 902105,
    name: 'Logement étudiant',
  },
  {
    id: 240,
    abbyProfessionalAccountingAccountNumber: 740000,
    abbyPersonalAccountingAccountNumber: 902213,
    name: 'Pensions',
  },
  {
    id: 239,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902102,
    name: 'Ecole',
  },
  {
    id: 238,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902103,
    name: 'Fournitures scolaires',
  },
  {
    id: 237,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902108,
    name: 'Scolarité & Enfants - Autres',
  },
  {
    id: 163,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902006,
    name: 'Santé',
  },
  {
    id: 325,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902001,
    name: 'Dentiste',
  },
  {
    id: 322,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902004,
    name: 'Opticien / Ophtalmo.',
  },
  {
    id: 268,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902006,
    name: 'Santé - Autres',
  },
  {
    id: 261,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902002,
    name: 'Médecin',
  },
  {
    id: 245,
    abbyProfessionalAccountingAccountNumber: 645200,
    abbyPersonalAccountingAccountNumber: 902003,
    name: 'Mutuelle',
  },
  {
    id: 236,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 902005,
    name: 'Pharmacie',
  },
  {
    id: 2,
    abbyProfessionalAccountingAccountNumber: 706100,
    abbyPersonalAccountingAccountNumber: 902202,
    name: 'Entrées d\'argent',
  },
  {
    id: 441894,
    abbyProfessionalAccountingAccountNumber: 164000,
    abbyPersonalAccountingAccountNumber: 902109,
    name: 'Prêt',
  },
  {
    id: 441893,
    abbyProfessionalAccountingAccountNumber: 740000,
    abbyPersonalAccountingAccountNumber: 902213,
    name: 'Subventions',
  },
  {
    id: 327,
    abbyProfessionalAccountingAccountNumber: 740000,
    abbyPersonalAccountingAccountNumber: 902201,
    name: 'Allocations et pensions',
  },
  {
    id: 314,
    abbyProfessionalAccountingAccountNumber: 613200,
    abbyPersonalAccountingAccountNumber: 902208,
    name: 'Loyers reçus',
  },
  {
    id: 289,
    abbyProfessionalAccountingAccountNumber: 761000,
    abbyPersonalAccountingAccountNumber: 902204,
    name: 'Economies',
  },
  {
    id: 283,
    // abbyProfessionalAccountingAccountNumber: 471000,
    abbyPersonalAccountingAccountNumber: 902209,
    name: 'Remboursements pro',
  },
  {
    id: 282,
    abbyProfessionalAccountingAccountNumber: 580000,
    abbyPersonalAccountingAccountNumber: 902215,
    name: 'Virements internes',
  },
  {
    id: 279,
    abbyProfessionalAccountingAccountNumber: 646200,
    abbyPersonalAccountingAccountNumber: 902210,
    name: 'Retraite',
  },
  {
    id: 271,
    abbyProfessionalAccountingAccountNumber: 108000,
    abbyPersonalAccountingAccountNumber: 902203,
    name: 'Dépôt d\'argent',
  },
  {
    id: 233,
    abbyProfessionalAccountingAccountNumber: 750000,
    abbyPersonalAccountingAccountNumber: 902206,
    name: 'Extra',
  },
  {
    id: 232,
    abbyProfessionalAccountingAccountNumber: 706100,
    abbyPersonalAccountingAccountNumber: 902214,
    name: 'Ventes de services',
  },
  {
    id: 231,
    abbyProfessionalAccountingAccountNumber: 707000,
    abbyPersonalAccountingAccountNumber: 902214,
    name: 'Ventes de biens',
  },
  {
    id: 230,
    abbyProfessionalAccountingAccountNumber: 108100,
    abbyPersonalAccountingAccountNumber: 902211,
    name: 'Salaires',
  },
  {
    id: 80,
    abbyProfessionalAccountingAccountNumber: 761000,
    abbyPersonalAccountingAccountNumber: 902207,
    name: 'Intérêts financiers',
  },
  {
    id: 3,
    abbyProfessionalAccountingAccountNumber: 750000,
    abbyPersonalAccountingAccountNumber: 902202,
    name: 'Autres rentrées',
  },
  {
    id: 166,
    abbyProfessionalAccountingAccountNumber: 600000,
    name: 'Dépenses pro',
  },
  {
    id: 441900,
    abbyProfessionalAccountingAccountNumber: 623000,
    name: 'Marketing - Autres',
  },
  {
    id: 441899,
    abbyProfessionalAccountingAccountNumber: 622600,
    name: 'Frais juridique',
  },
  {
    id: 441898,
    abbyProfessionalAccountingAccountNumber: 635100,
    name: 'Taxe d\'apprentissage',
  },
  {
    id: 441897,
    abbyProfessionalAccountingAccountNumber: 645700,
    name: 'Prévoyance',
  },
  {
    id: 441896,
    abbyProfessionalAccountingAccountNumber: 611000,
    name: 'Sous-traitance informatique',
  },
  {
    id: 441895,
    abbyProfessionalAccountingAccountNumber: 611000,
    name: 'Conseils',
  },
  {
    id: 441892,
    abbyProfessionalAccountingAccountNumber: 648000,
    name: 'Frais de recrutement',
  },
  {
    id: 441891,
    abbyProfessionalAccountingAccountNumber: 623000,
    name: 'Rémunérations dirigeants',
  },
  {
    id: 441890,
    abbyProfessionalAccountingAccountNumber: 108100,
    name: 'Salaires',
  },
  {
    id: 441889,
    abbyProfessionalAccountingAccountNumber: 622600,
    name: 'Comptabilité',
  },
  {
    id: 441886,
    abbyProfessionalAccountingAccountNumber: 646100,
    name: 'Cotisations Sociales',
  },
  {
    id: 274,
    abbyProfessionalAccountingAccountNumber: 605001,
    name: 'Fournitures de bureau',
  },
  {
    id: 270,
    abbyProfessionalAccountingAccountNumber: 623000,
    name: 'Services en ligne',
  },
  {
    id: 265,
    abbyProfessionalAccountingAccountNumber: 108101,
    name: 'Notes de frais',
  },
  {
    id: 205,
    abbyProfessionalAccountingAccountNumber: 600000,
    name: 'Frais d\'impressions',
  },
  {
    id: 204,
    abbyProfessionalAccountingAccountNumber: 626000,
    name: 'Frais d\'expéditions',
  },
  {
    id: 203,
    abbyProfessionalAccountingAccountNumber: 615000,
    name: 'Maintenance bureaux',
  },
  {
    id: 202,
    abbyProfessionalAccountingAccountNumber: 623000,
    name: 'Publicité',
  },
  {
    id: 90,
    abbyProfessionalAccountingAccountNumber: 600000,
    name: 'Frais généraux - Autres',
  },
  {
    id: 324,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901404,
    name: 'Pressing',
  },
  {
    id: 308,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901405,
    name: 'Tabac',
  },
  {
    id: 294,
    abbyProfessionalAccountingAccountNumber: 108101,
    abbyPersonalAccountingAccountNumber: 901403,
    name: 'Dons',
  },
  {
    id: 278,
    abbyProfessionalAccountingAccountNumber: 616000,
    abbyPersonalAccountingAccountNumber: 901401,
    name: 'Assurance',
  },
  {
    id: 276,
    abbyProfessionalAccountingAccountNumber: 108001,
    abbyPersonalAccountingAccountNumber: 901402,
    name: 'Autres dépenses',
  },
  // {
  //   id: 1,
  //   abbyProfessionalAccountingAccountNumber: 471000,
  //   name: 'A catégoriser',
  // },
  {
    id: 756587,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901306,
    name: 'Incidents de paiement',
  },
  {
    id: 306,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901308,
    name: 'Services Bancaires',
  },
  {
    id: 195,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901308,
    name: 'Banque - Autres',
  },
  {
    id: 194,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901305,
    name: 'Hypothèque',
  },
  {
    id: 192,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901303,
    name: 'Epargne',
  },
  {
    id: 191,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901302,
    name: 'Débit mensuel carte',
  },
  {
    id: 89,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901307,
    name: 'Remboursement emprunt',
  },
  {
    id: 79,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901304,
    name: 'Frais bancaires',
  },
  {
    id: 164,
    abbyProfessionalAccountingAccountNumber: 627000,
    abbyPersonalAccountingAccountNumber: 901304,
    name: 'Frais bancaires',
  },
];
