
import { Action, Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import {
  BillingState,
  ErrorCodeUrssafTp,
  IBilling,
  PaymentRequestStatusUrssafTp,
  PlanFeature,
  ReadOpportunity,
} from '@abby/core-legacy'
import CustomerLabel from '~/components-legacy/customerDetails/CustomerLabel.vue'
import BillingChips from '~/components-legacy/BillingChips.vue'
import BillingInformation from '~/components-legacy/BillingInformation.vue'
import Link from '~/components-legacy/utils/Link.vue'

const MAX_LENGTH = 18

@Component({
  components: {
    Link,
    BillingInformation,
    BillingChips,
    CustomerLabel,
  },
})
export default class BillingLineDesignation extends Vue {
  @Action('opportunity/openOpportunityDetails') openOpportunityDetails!: (payload: { opportunity: ReadOpportunity }) => Promise<void>;

  @Prop()
  item!: IBilling

  @Prop({ default: false })
  avoidExpand!: boolean

  @Prop({ default: false })
  avoidUnpaidAction!: boolean

  @PropSync('expandedValue')
  expanded!: boolean

  @Prop({ default: true })
  enableDisplaySolveIssuesButton!: boolean

  serverItem: IBilling | null = null

  isMenuOpened = false

  get displaySolveIssuesButton () {
    if (!this.enableDisplaySolveIssuesButton) {
      return false
    }
    return (this.hasCustomerTPNonActivated || this.hasCustomerTPLienParticulier || this.hasCustomerTPBlocked || this.hasInvoiceDuplicate || this.canRetrySendBillingTp) && !this.avoidExpand
  }

  get hasAccessToOpportunity () {
    return this.$planManager.hasAccessTo(PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD)
  }

  get hasCustomerTPNonActivated () {
    return this.itemToDisplay.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_PARTICULIER_INCONNU
  }

  get hasCustomerTPLienParticulier () {
    return this.itemToDisplay.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_LIEN_PARTICULIER_PRESTATAIRE
  }

  get hasCustomerTPBlocked () {
    return this.itemToDisplay.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_COMPTE_BLOQUE
  }

  get itemToDisplay () {
    return this.serverItem || this.item
  }

  get numberTruncated () {
    if (!this.itemToDisplay.number) { return '' }
    if (this.itemToDisplay.number.length <= MAX_LENGTH) {
      return this.itemToDisplay.number
    } else {
      return this.itemToDisplay.number.substring(0, MAX_LENGTH - 3) + '...'
    }
  }

  openOpportunity () {
    if (!this.itemToDisplay.opportunity) { return }
    this.openOpportunityDetails({
      opportunity: this.itemToDisplay.opportunity,
    })
  }

  @Prop()
  search!: string

  @Prop()
  singleLineMode!: boolean

  get canRetrySendBillingTp () {
    if (!this.itemToDisplay.tiersPrestationIsActivatedForThisBilling) { return }
    if (this.itemToDisplay.billingState === BillingState.FINALIZED && !this.itemToDisplay.paymentRequest?.statut) { return true }
    const error = ErrorCodeUrssafTp.find(err => this.itemToDisplay.paymentRequest && [err.code].includes(this.itemToDisplay.paymentRequest.statut) && ![PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON, PaymentRequestStatusUrssafTp.ERR_NBRE_PREST_MAX].includes(this.itemToDisplay.paymentRequest.statut))
    if (error && this.itemToDisplay.billingState !== BillingState.PAID) { return error }
  }

  get hasInvoiceDuplicate () {
    if (!this.itemToDisplay.tiersPrestationIsActivatedForThisBilling) { return }
    const error = this.itemToDisplay?.paymentRequest?.statut === PaymentRequestStatusUrssafTp.ERR_FACTURE_DOUBLON
    if (error && this.itemToDisplay.billingState !== BillingState.PAID) { return error }
  }

  get displayCustomerLabel () {
    return (this.itemToDisplay.opportunity && this.hasAccessToOpportunity) || !this.singleLineMode
  }

  mounted () {
    this.$busManager.on('billingUpdated', (b: IBilling) => {
      if (b?._id !== this.item._id) { return }
      this.serverItem = b
    })
  }
}
