import { IIssueTemplate } from '../Interface/models/IIssueTemplate';
import { IssueLabelType } from './IssueLabelType';

export const IssueTemplate: Array<IIssueTemplate> = [
  {
    type: IssueLabelType.BUG,
    description: 'Date de l\'erreur : **DATE** \n\n'
      + 'Informations sur l\'erreur : **CODE ERREUR, MESSAGE D\'ERREUR, ETC** \n\n'
      + 'Actions pour reproduire l\'erreur : **PROCESSUS REPRODUCTION DU PROBLEME RENCONTRÉ**',
  },
  {
    type: IssueLabelType.DATA,
    description: '',
  },
  {
    type: IssueLabelType.NEW_FEATURE,
    description: '',
  },
  {
    type: IssueLabelType.SUPPORT,
    description: '',
  },
  {
    type: IssueLabelType.IMPROVEMENT,
    description: 'Problématique : **PROBLEMATIQUE** \n\n'
      + 'Idée d\'amélioration : **IDEES POUR RESOUDRE LA PROBLEMATIQUE**',
  },
];
