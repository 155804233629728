import { Code, Type } from '@abby/core-legacy'
import { AxiosRequestConfig } from 'axios'
import type {
  BasePaginateMongo,
  CreateCustomer,
  IBillingConfiguration,
  IClient,
  ICustomer,
  ICustomerCompany,
  QueryCustomer,
  UpdateBillingConfiguration,
  UpdateCustomer,
  UpdateClient,
  CreateProduct,
  IProduct,
  QueryProduct,
  UpdateProduct,
  CreateBilling,
  QueryBilling,
  IBilling,
  SendByEmail,
  ReadPayments,
  CreateClientPayment,
  ReadClientPayment,
  ReadCustomerStatistics,
  ReadBillingStatistics,
  CustomerDocGenerationParams,
  ReadEma,
  CancelBilling,
  QueryDownloadBilling,
  QueryDownloadProduct, QueryDownloadThirdParty, QueryDownloadAllBilling,
  QueryNova, QueryStatisticsBilling, QueryBillingDownloadedActivity,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Billing extends Resource {
  // customer

  createCustomer (data: CreateCustomer): Promise<ICustomer> {
    return this.axios.$post('/customer', data)
  }

  createCustomers (data: CreateCustomer[]): Promise<void> {
    return this.axios.$post('/customers', data)
  }

  updateCustomer (id: string, data: UpdateCustomer): Promise<ICustomer> {
    return this.axios.$put(`/customer/${id}`, data)
  }

  deleteCustomer (id: string): Promise<{ n?: number, ok?: number, deletedCount?: number }> {
    return this.axios.$delete(`/customer/${id}`)
  }

  updateClient (id: string, data: UpdateClient): Promise<ICustomer> {
    return this.axios.$put(`/customer/client/${id}`, data)
  }

  getAllClient (): Promise<Array<IClient>> {
    return this.axios.$get('/customers/clients')
  }

  getCustomer (id: string): Promise<ICustomer> {
    return this.axios.$get(`/customer/${id}`)
  }

  getCustomerStatistics (id: string): Promise<ReadCustomerStatistics> {
    return this.axios.$get(`/customer/${id}/statistics`)
  }

  getAllCustomerCompanies (): Promise<Array<ICustomerCompany>> {
    return this.axios.$get('/customers/customerCompanies')
  }

  getAllCustomers (query: QueryCustomer): Promise<BasePaginateMongo<ICustomer>> {
    return this.axios.$get('/customers', { params: query })
  }

  downloadCustomer (params: QueryDownloadThirdParty): Promise<any> {
    return this.axios.get('/customers/download', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.customer,
      type: Type.resourceError,
      message: 'customer.export_no_data',
    }))
  }

  downloadContacts (params: QueryDownloadThirdParty): Promise<any> {
    return this.axios.get('/customers/download-contacts', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.customer,
      type: Type.resourceError,
      message: 'customer.export_no_data',
    }))
  }

  downloadOrganizations (params: QueryDownloadThirdParty): Promise<any> {
    return this.axios.get('/customers/download-organizations', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.customer,
      type: Type.resourceError,
      message: 'customer.export_no_data',
    }))
  }

  // config

  getConfiguration (): Promise<IBillingConfiguration | boolean> {
    return this.axios.$get('/billingConfiguration')
  }

  updateConfiguration (data: UpdateBillingConfiguration): Promise<IBillingConfiguration> {
    return this.axios.$put('/billingConfiguration', data)
  }

  // product

  createProduct (data: CreateProduct): Promise<IProduct> {
    return this.axios.$post('/product', data)
  }

  createProducts (data: CreateProduct[]): Promise<void> {
    return this.axios.$post('/products', data)
  }

  updateProduct (id: string, data: UpdateProduct): Promise<IProduct> {
    return this.axios.$put(`/product/${id}`, data)
  }

  getAllProduct (query: QueryProduct): Promise<BasePaginateMongo<IProduct>> {
    return this.axios.$get('/products', { params: query })
  }

  deleteProduct (id: string): Promise<{ n?: number, ok?: number, deletedCount?: number }> {
    return this.axios.$delete(`/product/${id}`)
  }

  downloadProduct (params: QueryDownloadProduct): Promise<any> {
    return this.axios.get('/products/download', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.product,
      type: Type.resourceError,
      message: 'product.export_no_data',
    }))
  }

  // invoice

  createBillingDocument (data: CreateBilling): Promise<IBilling> {
    return this.axios.$post('/billing', data)
  }

  updateBillingDocument (id: string, data: CreateBilling): Promise<IBilling> {
    return this.axios.$put(`/billing/${id}/update`, data)
  }

  updateReminder (id: string, reminder: IBillingConfiguration['reminder']): Promise<IBilling> {
    return this.axios.$put(`/billing/${id}/updateReminder`, reminder)
  }

  updateTitle (id: string, title: string): Promise<IBilling> {
    return this.axios.$put(`/billing/${id}/updateTitle`, { title })
  }

  activateOnlineSignature (id: string, validityDate: Date): Promise<IBilling> {
    return this.axios.$put(`/billing/${id}/activateOnlineSignature`, { validityDate })
  }

  activateStripePayment (id: string): Promise<IBilling> {
    return this.axios.$put(`/billing/${id}/activateStripePayment`)
  }

  getPaginated (query: QueryBilling, config?: AxiosRequestConfig): Promise<BasePaginateMongo<IBilling>> {
    return this.axios.$get('/billings', { params: query, ...config })
  }

  deleteBillingDocument (id: string): Promise<{ n?: number, ok?: number, deletedCount?: number }> {
    return this.axios.$delete(`/billing/${id}`)
  }

  archiveDocument (id: string): Promise<IBilling> {
    return this.axios.$patch(`/v2/billing/${id}/archive`)
  }

  markAsFinalized (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsFinalized`)
  }

  markAsUnPaid (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsUnPaid`)
  }

  markAsRefused (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsRefused`, { refusedAt: new Date() })
  }

  markAsNotRefused (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsNotRefused`)
  }

  markAsSigned (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsSigned`, { signedAt: new Date() })
  }

  markAsNotSigned (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/markAsNotSigned`)
  }

  sendByEmail (id: string, body: SendByEmail): Promise<void> {
    return this.axios.$post(`/billing/${id}/sendByEmail`, body)
  }

  addDownloadedActivity (id: string, fileData: QueryBillingDownloadedActivity): Promise<void> {
    return this.axios.$put(`/billing/${id}/addDownloadedActivity`, fileData)
  }

  sendTestReminderEmail (billing: IBilling): Promise<void> {
    return this.axios.$post('/billing/sendTestReminderEmail', billing)
  }

  sendTestRecurringEmail (billing: IBilling): Promise<void> {
    return this.axios.$post('/billing/frequency/sendTestRecurringEmail', billing)
  }

  getStatistics (params: QueryStatisticsBilling, config?: AxiosRequestConfig): Promise<ReadBillingStatistics> {
    return this.axios.$get('/billings/statistics', {
      params,
      ...config,
    })
  }

  getPayment (id: string, token: string): Promise<IBilling> {
    return this.axios.$get(`/billing/${id}/payment`, { params: { token } })
  }

  getPayments (id: string, token: string): Promise<ReadPayments> {
    return this.axios.$get('billing/payments', { params: { id, token } })
  }

  getCustomerBillings (id: string): Promise<IBilling[]> {
    return this.axios.$get(`/billing/customer/${id}/billings`)
  }

  getClientPayment (payload: CreateClientPayment): Promise<ReadClientPayment> {
    return this.axios.$post('/billing/clientPayment', payload)
  }

  get (id: string): Promise<IBilling> {
    return this.axios.$get(`/billing/${id}`)
  }

  generateFileToDownload (id: string, params?: QueryDownloadBilling): Promise<IBilling> {
    return this.axios.$get(`/billing/${id}/generateFile`, { ...params?.locale ? { params: { locale: params.locale?.toLowerCase() } } : {} })
  }

  downloadAllBilling (params: QueryDownloadAllBilling): Promise<any> {
    return this.axios.get('/billing/downloadAll', { params, responseType: 'arraybuffer' }).then(res => res).catch(() => ({
      code: Code.billing,
      type: Type.resourceError,
      message: 'billing.export_no_data',
    }))
  }

  regenerateDocument (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/regenerate/billing/${id}`)
  }

  fixCustomerAddress (id: string): Promise<boolean> {
    return this.axios.$get(`/v2/billing/fixCustomerAddress/${id}`)
  }

  unlinkFromCreateFrom (id: string): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/unlinkFromCreateFrom`)
  }

  retrySendBillingUrssaf (id: string, paymentRequest?: IBilling['paymentRequest']): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/retryUrssafTp`, paymentRequest)
  }

  updateNovaNumber (novaNumber: string): Promise<void> {
    return this.axios.$put('/urssaftp/novaNumber', { novaNumber })
  }

  generateAF (payload: CustomerDocGenerationParams): Promise<any> {
    const _payload = payload
    const dateType = payload.query?.dateType
    const basedOnDateDebutEmploi = payload.query?.basedOnDateDebutEmploi
    const year = payload.query?.year
    delete _payload.query
    return this.axios.$get('/customer/generateAF', {
      params: {
        ..._payload, dateType, year, basedOnDateDebutEmploi,
      },
      responseType: 'arraybuffer',
    }).then(res => res).catch(() => ({
      code: Code.taxReturnDocument,
      type: Type.resourceError,
      message: 'tax_return_document.none_document',
    }))
  }

  checkUrssafTpStatut (id: string): Promise<any> {
    return this.axios.$get(`/billing/${id}/urssafTpStatut`)
  }

  cancelBilling (id: string, payload: CancelBilling): Promise<IBilling> {
    return this.axios.$post(`/billing/${id}/cancelBilling`, payload)
  }

  fetchNovaEma (payload: QueryNova): Promise<ReadEma> {
    return this.axios.$post('/billing/nova/ema', payload)
  }

}
