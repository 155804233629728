import { IStrapiQuery } from '../../types';

import * as qs from 'qs';

export function formatQuery(query: IStrapiQuery): string {
  return qs.stringify(query, {
    encodeValuesOnly: true,
  });
}

export function normalize(data: any) {
  const isObject = (_data: any) => Object.prototype.toString.call(_data) === '[object Object]';
  const isArray = (_data: any) => Object.prototype.toString.call(_data) === '[object Array]';

  const flatten = (_data: any) => {
    if (!_data.attributes) return _data;

    return {
      id: _data.id,
      ..._data.attributes,
    };
  };

  if (isArray(data)) {
    return data.map((item: any) => normalize(item));
  }

  let __data = data;

  if (isObject(__data)) {
    if (isArray(__data.data)) {
      __data = [...__data.data];
    }
    else if (isObject(__data.data)) {
      __data = flatten({ ...__data.data });
    }
    else if (__data.data === null) {
      __data = null;
    }
    else {
      __data = flatten(__data);
    }

    for (const key in __data) {
      __data[key] = normalize(__data[key]);
    }

    return __data;
  }

  return __data;
}
