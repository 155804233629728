
import { useWindowSize } from '@vueuse/core'
import { Component, Vue, PropSync, Getter, Prop } from 'nuxt-property-decorator'

@Component({
  setup () {
    const { width } = useWindowSize()
    return {
      windowWidth: width,
    }
  },
})
export default class IntercomButton extends Vue {
  @PropSync('chatIsOpen')
  chatIsOpenValue!: boolean

  @Prop({ default: 'modal' })
  componentToOpen!: 'chat' | 'modal'

  @Getter('helpCenter/helpCenterIsOpen')
  helpCenterIsOpen!: boolean

  windowWidth!: number

  openHelpcenter () {
    if (!this.chatIsOpenValue) {
      if (this.componentToOpen === 'modal') {
        this.$help.toggleHelpCenter(!this.helpCenterIsOpen)
      } else {
        this.$help.toggleChat(true, { open: true })
      }
    } else {
      this.$help.toggleChat(false, { open: false })
    }
  }

  get showIcon () {
    return (!this.helpCenterIsOpen && !this.chatIsOpenValue) || this.windowWidth > 450
  }
}
