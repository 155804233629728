import { Resource } from '../Resource';
import {
  CreateCategorizationRule,
  ReadCategorizationRule,
  ReadCategorizationRules,
  UpdateCategorizationRule,
  QueryCategorizationRule,
} from '../../dto';

export class CategorizationRule extends Resource {
  async create(payload: CreateCategorizationRule): Promise<ReadCategorizationRule> {
    return this.http.$post('/v2/banks/categorization-rules', payload);
  }

  async update(id: string, payload: UpdateCategorizationRule): Promise<ReadCategorizationRule> {
    return this.http.$put(`/v2/banks/categorization-rules/${id}`, payload);
  }

  async delete(id: string): Promise<void> {
    return this.http.$delete(`/v2/banks/categorization-rules/${id}`);
  }

  async paginate(query: QueryCategorizationRule): Promise<ReadCategorizationRules> {
    return this.http.$get('/v2/banks/categorization-rules', { params: query });
  }

  async retrieve(id: string): Promise<ReadCategorizationRule> {
    return this.http.$get(`/v2/banks/categorization-rules/${id}`);
  }
}
