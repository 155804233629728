import { defineStore, storeToRefs } from 'pinia'
import { ref, Ref } from 'vue'
import { PlanFeature } from '@abby/core-legacy'

export interface ConfirmParams {
  title?: string
  subtitle?: string
  content?: string
  confirmText?: string
  confirmButton?: object
  confirmButtonIcon?: string
  icon?: 'remove' | 'save'
  cancelText?: string
  cancelButton?: object
  confirmCallback?: () => void
  cancelCallback?: () => void
  noCancelButton?: boolean
  maxWidth?: number
  feature?: PlanFeature
}

export const useConfirmManagerStore = () => {
  const useConfirmManagerStore = defineStore('confirmManager', () => {
    const confirm: Ref<ConfirmParams | null> = ref(null)

    const setConfirm = (value: ConfirmParams | null) => {
      confirm.value = value
    }

    const reset = () => {
      confirm.value = null
    }

    const clearConfirm = () => {
      setConfirm(null)
    }

    return {
      confirm,
      setConfirm,
      reset,
      clearConfirm,
    }
  })

  const confirmManagerStore = useConfirmManagerStore()
  const { setConfirm, reset, clearConfirm } = confirmManagerStore
  const { confirm } = storeToRefs(confirmManagerStore)

  return {
    confirm,
    setConfirm,
    reset,
    clearConfirm,
  }
}
