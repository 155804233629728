import { useContext } from '@nuxtjs/composition-api'
import { BillingItem } from '~/services/billing/_common/entities/BillingItem.entity'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useCopyBillingCustomerPortalLink = () => {
  const { $clipboard } = useContext()
  const alertManager = useAlertManager()

  return async (billing: BillingItem) => {
    const url = `${process.env.baseAppUrl}/clientPortal?id=${billing.customer.legacyId}&billing=${billing.id}&token=${billing.customer.token}`
    await $clipboard.copy(url)
    alertManager.success('Le lien a été copié dans le presse-papier')
  }
}
