import { Resource } from '../Resource';
import {
  BillingRangeType,
  BillingState,
  BillingType,
  ReadBillingCollection,
  ReadBillingConfiguration,
  ReadBillingStatistics,
  ReadCustomer,
  QueryBilling, ReadBillingDetails, ReconciliateInvoice, ReadBillingPayment, ReadSimilarTransaction,
} from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type BillingFilterQuery = {
  type?: BillingType[];
  state?: BillingState[];
  range?: [string, string];
  rangeType?: BillingRangeType;
  archived?: boolean;
  test: boolean;
  late?: boolean;
  search?: string;
};

export class Billing extends Resource {
  paginate(query: QueryParams<QueryBilling>): Promise<ReadBillingCollection> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/v2/billings', { params, cancelToken });
  }

  getConfiguration(): Promise<ReadBillingConfiguration> {
    return this.http.$get('/v2/billing/configuration');
  }

  getStatistics(query: QueryParams<BillingFilterQuery>): Promise<ReadBillingStatistics> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/v2/billings/statistics', { params, cancelToken });
  }

  archive(id: string): Promise<void> {
    return this.http.$patch(`/v2/billing/${id}/archive`);
  }

  unarchive(id: string): Promise<void> {
    return this.http.$patch(`/v2/billing/${id}/unarchive`);
  }

  getCustomerFromContact(contactId: string): Promise<ReadCustomer> {
    return this.http.$get(`/v2/customer/contact/${contactId}`);
  }

  getCustomerFromOrganization(organizationId: string): Promise<ReadCustomer> {
    return this.http.$get(`/v2/customer/organization/${organizationId}`);
  }

  fetchInvoiceDetails(id: string): Promise<ReadBillingDetails> {
    return this.http.$get(`/v2/accounting-billing/invoice/${id}/details`);
  }

  fetchAssetDetails(id: string): Promise<ReadBillingDetails> {
    return this.http.$get(`/v2/accounting-billing/asset/${id}/details`);
  }

  fetchAdvanceDetails(id: string): Promise<ReadBillingDetails> {
    return this.http.$get(`/v2/accounting-billing/advance/${id}/details`);
  }

  fetchInvoicePayments(id: string): Promise<ReadBillingPayment[]> {
    return this.http.$get(`/v2/accounting-billing/${id}/payments`);
  }

  fetchTransactionsToReconciliateWithInvoice(id: string): Promise<ReadSimilarTransaction[]> {
    return this.http.$get(`/v2/transactions/${id}/similar-transactions-invoice`);
  }

  fetchTransactionsToReconciliateWithAsset(id: string): Promise<ReadSimilarTransaction[]> {
    return this.http.$get(`/v2/transactions/${id}/similar-transactions-asset`);
  }

  fetchTransactionsToReconciliateWithAdvance(id: string): Promise<ReadSimilarTransaction[]> {
    return this.http.$get(`/v2/transactions/${id}/similar-transactions-advance`);
  }

  reconciliateInvoice(id: string, payload: ReconciliateInvoice): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/invoice/${id}/reconciliate`, payload);
  }

  reconciliateAsset(id: string, payload: ReconciliateInvoice): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/asset/${id}/reconciliate`, payload);
  }

  reconciliateAdvance(id: string, payload: ReconciliateInvoice): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/advance/${id}/reconciliate`, payload);
  }

  markInvoiceAsUnpaid(id: string): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/invoice/${id}/mark-as-unpaid`);
  }

  markAssetAsUnpaid(id: string): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/asset/${id}/mark-as-unpaid`);
  }

  markAdvanceAsUnpaid(id: string): Promise<void> {
    return this.http.$post(`/v2/accounting-billing/advance/${id}/mark-as-unpaid`);
  }

  // ADMIN FIX
  regenerateNumber(id: string): Promise<void> {
    return this.http.$post(`/billing/regenerate/billing/${id}/number`);
  }
}
