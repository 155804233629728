import type { ReadCountSignature } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Signature extends Resource {
  count (): Promise<ReadCountSignature> {
    return this.axios.$get('/signature/company/available')
  }

  downloadProof (id: string): Promise<{file: Buffer, contentType: string}> {
    return this.axios.get(`/signature/proof/${id}`, { responseType: 'arraybuffer' }).then(({ data, ...res }) => {
      return {
        file: data,
        contentType: res.headers['content-type'],
      }
    })
  }
}
