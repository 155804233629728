import { Resource } from '../Resource';
import {
  ReadOpportunityCategory,
  ReadPaginatedOpportunities,
} from '../../dto';
import { OpportunityOrderBy, OrderDirection } from '../../enums';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type OpportunityFilterQuery = {
  search?: string;
  orderBy?: OpportunityOrderBy;
  orderDirection?: OrderDirection;
  customerId?: string;
};

export class Opportunity extends Resource {
  paginate(query: QueryParams<{
    page: number;
    limit: number;
  } & OpportunityFilterQuery>): Promise<ReadPaginatedOpportunities> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/v2/opportunities', { params, cancelToken });
  }

  getCategories(): Promise<ReadOpportunityCategory[]> {
    return this.http.$get('/v2/opportunity/categories');
  }
}
